<template>
  <div class="rounded-lg w-full h-full overflow-hidden flex flex-col">
    <ChatHeader :title="title" />

    <div
      ref="messagesContainer"
      class="flex-grow overflow-y-auto p-4 space-y-4"
    >
      <div v-if="messages.length === 0" class="text-center text-gray-500 mt-10">
        <p class="text-lg">Sohbete başlamak için bir mesaj gönderin.</p>
      </div>

      <ChatMessageComponent
        v-for="(message, index) in messages"
        :key="index"
        :content="message.content"
        :isUser="message.role === 'user'"
        :timestamp="new Date(message.timestamp)"
      />

      <div v-if="isLoading" class="typing-indicator-container">
        <div class="typing-indicator">
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
    </div>

    <div class="p-3 border-t border-gray-200">
      <ChatInput @send="handleSendMessage" :isLoading="isLoading" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, defineProps, defineEmits, watch, nextTick } from "vue";
import ChatHeader from "./ChatHeader.vue";
import ChatMessageComponent from "./ChatMessage.vue";
import ChatInput from "./ChatInput.vue";
import type { ChatMessage } from "@/types/chat";

const props = defineProps({
  title: {
    type: String,
    default: "Sağlık Asistanı",
  },
  messages: {
    type: Array as () => ChatMessage[],
    default: () => [],
  },
  isLoading: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(["send"]);
const messagesContainer = ref<HTMLDivElement | null>(null);

const handleSendMessage = (message: string, document?: File) => {
  emit("send", message, document);
};

const scrollToBottom = () => {
  nextTick(() => {
    if (messagesContainer.value) {
      messagesContainer.value.scrollTop = messagesContainer.value.scrollHeight;
    }
  });
};

watch(
  () => props.messages,
  () => {
    scrollToBottom();
  },
  { deep: true }
);

// Scroll to bottom on mount
scrollToBottom();
</script>

<style scoped>
.typing-indicator-container {
  padding: 1rem;
  display: flex;
}

.typing-indicator {
  display: flex;
  align-items: center;
  background-color: #f3f4f6;
  padding: 0.5rem 1rem;
  border-radius: 1rem;
  border-bottom-left-radius: 0;
}

.typing-indicator span {
  height: 8px;
  width: 8px;
  margin: 0 2px;
  background-color: #9ca3af;
  border-radius: 50%;
  display: inline-block;
  animation: typing 1.4s infinite ease-in-out both;
}

.typing-indicator span:nth-child(1) {
  animation-delay: 0s;
}

.typing-indicator span:nth-child(2) {
  animation-delay: 0.2s;
}

.typing-indicator span:nth-child(3) {
  animation-delay: 0.4s;
}

@keyframes typing {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }
}
</style>
