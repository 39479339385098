import type { AxiosInstance } from "axios";
import { BlogModel, Blog } from "./types";

export * from "./types";

export class BlogService {
  private readonly instance: AxiosInstance;

  constructor(instance: AxiosInstance) {
    this.instance = instance;
  }

  async list(isAll: boolean): Promise<BlogModel[]> {
    try {
      const response = await this.instance.get("/blog/list", {
        withCredentials: true,
        params: {
          isAll,
        },
      });
      return response.data.data;
    } catch (error) {
      throw error;
    }
  }

  async get(slug: string): Promise<BlogModel> {
    try {
      const response = await this.instance.get(`/blog/${slug}`, {
        withCredentials: true,
      });
      return response.data.data;
    } catch (error) {
      throw error;
    }
  }

  async create(blog: Blog): Promise<BlogModel> {
    try {
      const response = await this.instance.post("/admin/blog/create", blog, {
        withCredentials: true,
      });
      return response.data.data;
    } catch (error) {
      throw error;
    }
  }
}
