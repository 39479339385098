import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "absolute top-2 right-2 bg-primaryTint text-light font-medium rounded-full py-1 text-sm opacity-95 px-4"
}
const _hoisted_2 = ["src"]
const _hoisted_3 = { class: "p-5 text-sm font-bold" }
const _hoisted_4 = { class: "font-extrabold text-secondary dark:text-secondary" }
const _hoisted_5 = { class: "my-[10px] block text-lg font-extrabold leading-[23px] text-black line-clamp-2 dark:text-white" }
const _hoisted_6 = { class: "line-clamp-4 text-gray-500 font-medium" }

import { Blog } from "@/utils/api";



export default /*@__PURE__*/_defineComponent({
  __name: 'BlogCard',
  props: {
    blog: {}
  },
  setup(__props: any) {

const props = __props;

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createBlock(_component_router_link, {
    to: `/admin/blog/${props.blog.slug}`,
    class: "relative rounded-3xl border cursor-pointer border-transparent bg-white transition duration-500 hover:border-secondary hover:bg-secondary/20 dark:bg-gray-dark"
  }, {
    default: _withCtx(() => [
      (props.blog.isActive)
        ? (_openBlock(), _createElementBlock("span", _hoisted_1, "Yayında"))
        : _createCommentVNode("", true),
      _createElementVNode("img", {
        src: props.blog.image,
        alt: "blog",
        class: "h-52 w-full rounded-t-3xl object-cover"
      }, null, 8, _hoisted_2),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("h6", _hoisted_4, _toDisplayString(props.blog.tag), 1),
        _createElementVNode("h5", _hoisted_5, _toDisplayString(props.blog.title), 1),
        _createElementVNode("p", _hoisted_6, _toDisplayString(props.blog.description), 1)
      ])
    ]),
    _: 1
  }, 8, ["to"]))
}
}

})