<template>
  <div class="w-full relative">
    <label v-if="label" class="text-xs font-semibold px-1">
      {{ label }}
    </label>

    <div class="flex items-center space-x-2 mt-2">
      <span class="text-sm font-medium">{{ min }}</span>

      <div class="relative w-full">
        <div
          class="absolute -top-10 left-1/2 transform -translate-x-1/2 bg-primary text-light text-sm font-bold px-2 py-1 rounded flex items-center"
          :style="{ left: `${thumbPosition}%` }"
        >
          <input
            type="number"
            class="w-12 text-center bg-transparent border-none outline-none text-light font-bold"
            v-model="inputValue"
            :disabled="disabled"
            @blur="validateInput"
            @keydown.enter.prevent="validateInput"
          />
          {{ unit }}
        </div>

        <input
          type="range"
          id="range-slider"
          v-model.number="rangeValue"
          :min="min"
          :max="max"
          :step="step"
          :disabled="disabled"
          @input="updateThumbPosition"
          :class="[
            'w-full h-3 rounded-lg appearance-none cursor-pointer',
            disabled ? 'cursor-not-allowed opacity-50' : '',
          ]"
        />

        <div
          class="absolute top-1 left-0 h-3 rounded-lg bg-primary z-0"
          :style="{ width: `${thumbPosition}%` }"
        ></div>
      </div>

      <span class="text-sm font-medium">{{ max }}</span>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { defineProps, defineEmits, ref, watch, onMounted } from "vue";

const props = defineProps({
  modelValue: {
    type: Number,
    required: true,
  },
  min: {
    type: Number,
    default: 0,
  },
  max: {
    type: Number,
    default: 100,
  },
  step: {
    type: Number,
    default: 1,
  },
  label: {
    type: String,
    default: "",
  },
  icon: {
    type: String,
    default: "",
  },
  unit: {
    type: String,
    default: "",
  },
  disabled: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(["update:modelValue"]);

const rangeValue = ref(props.modelValue);
const inputValue = ref(props.modelValue);
const thumbPosition = ref(0);

const updateThumbPosition = () => {
  const percentage =
    ((rangeValue.value - props.min) / (props.max - props.min)) * 100;
  thumbPosition.value = percentage;
};

const validateInput = () => {
  let validatedValue = Number(inputValue.value);
  if (validatedValue < props.min) {
    validatedValue = props.min;
  } else if (validatedValue > props.max) {
    validatedValue = props.max;
  }
  rangeValue.value = validatedValue;
  inputValue.value = validatedValue;
  updateThumbPosition();
};

watch(
  () => props.modelValue,
  (newValue) => {
    rangeValue.value = newValue;
    inputValue.value = newValue;
    updateThumbPosition();
  }
);

watch(rangeValue, (newValue) => {
  emit("update:modelValue", newValue);
});

onMounted(() => {
  updateThumbPosition();
});
</script>
