import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["disabled"]
const _hoisted_2 = { class: "flex justify-center items-center space-x-2" }

import { computed } from "vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'BaseButton',
  props: {
  nativeType: {
    type: String,
    default: "button",
  },
  loading: {
    type: Boolean,
    default: false,
  },
  type: {
    type: String as () => "primary" | "secondary" | "alternative" | "danger",
    default: "primary",
  },
},
  emits: ["click"],
  setup(__props, { emit: __emit }) {

const props = __props;

const emit = __emit;

const handleClick = () => {
  if (!props.loading) {
    emit("click");
  }
};

const buttonClasses = computed(() => {
  if (props.loading) {
    return "cursor-not-allowed bg-light text-primary";
  }

  switch (props.type) {
    case "secondary":
      return "bg-light text-primary border border-lightShade hover:bg-lightTint hover:border-secondaryTint hover:text-secondaryShade disabled:bg-light disabled:text-lightShade disabled:border-light";
    case "alternative":
      return "bg-secondary text-primary border border-secondary disabled:bg-light disabled:text-secondaryTint disabled:border-light";
    case "danger":
      return "bg-red-700 text-white border border-red-600 disabled:bg-red-200 disabled:text-red-500 disabled:border-red-200";
    default:
      return "bg-primary text-white disabled:bg-light disabled:text-primary disabled:border-secondary";
  }
});

return (_ctx: any,_cache: any) => {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock("button", {
    class: _normalizeClass([
      'block w-full mx-auto transition-[background-color] duration-300 ease-in-out transform rounded-lg px-3 py-2 font-semibold  disabled:cursor-not-allowed hover:bg-opacity-80',
      buttonClasses.value,
    ]),
    disabled: __props.loading,
    onClick: handleClick
  }, [
    _createElementVNode("div", _hoisted_2, [
      (__props.loading)
        ? (_openBlock(), _createBlock(_component_font_awesome_icon, {
            key: 0,
            icon: "stethoscope",
            class: "fast-spin"
          }))
        : _renderSlot(_ctx.$slots, "default", { key: 1 })
    ])
  ], 10, _hoisted_1))
}
}

})