import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, mergeProps as _mergeProps, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"




export default /*@__PURE__*/_defineComponent({
  __name: 'BaseCard',
  props: {
    fullWidth: { type: Boolean }
  },
  setup(__props: any) {

const props = __props;

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _mergeProps({
    class: [
      _ctx.$attrs.class,
      'bg-white border shadow-sm rounded-lg p-6',
      { 'w-full': props.fullWidth },
    ]
  }, _ctx.$attrs), [
    _renderSlot(_ctx.$slots, "default")
  ], 16))
}
}

})