<template>
  <div
    :class="[
      'px-4 py-4 text-left mt-2 mb-4 rounded-md border-l-4 shadow text-sm',
      backgroundClass,
      borderClass,
    ]"
  >
    <font-awesome-icon
      icon="circle-exclamation"
      :class="iconColorClass"
      class="mr-1"
    ></font-awesome-icon>
    {{ text }}
  </div>
</template>

<script setup lang="ts">
import { defineProps, computed } from "vue";

interface Disclaimer {
  text: string;
  type: "error" | "warning" | "success" | "primary";
}

const props = defineProps<Disclaimer>();

const backgroundClass = computed(() => {
  switch (props.type) {
    case "error":
      return "bg-red-50";
    case "warning":
      return "bg-amber-50";
    case "success":
      return "bg-green-50";
    default:
      return "";
  }
});

const borderClass = computed(() => {
  switch (props.type) {
    case "error":
      return "border-l-red-600";
    case "warning":
      return "border-l-yellow-600";
    case "success":
      return "border-l-green-600";
    default:
      return "";
  }
});

const iconColorClass = computed(() => {
  switch (props.type) {
    case "error":
      return "text-red-600";
    case "warning":
      return "text-yellow-600";
    case "success":
      return "text-green-600";
    default:
      return "";
  }
});
</script>
