import type { AxiosInstance } from "axios";
import { LoginCredentials, VerifyCredentials, User } from "./types";

export * from "./types";

export class AuthService {
  private readonly instance: AxiosInstance;

  constructor(instance: AxiosInstance) {
    this.instance = instance;
  }

  async login(
    credentials: LoginCredentials
  ): Promise<{ accessToken: string; refreshToken: string; user: User }> {
    try {
      const response = await this.instance.post("/auth/login", credentials, {
        withCredentials: true,
      });
      return response.data.data;
    } catch (error) {
      throw error;
    }
  }

  async register(credentials: LoginCredentials): Promise<{ user: User }> {
    try {
      const response = await this.instance.post("/auth/register", credentials, {
        withCredentials: true,
      });
      return response.data.data;
    } catch (error) {
      throw error;
    }
  }

  async verify(
    credentials: VerifyCredentials
  ): Promise<{ token: string; user: User }> {
    try {
      const response = await this.instance.post("/auth/verify", credentials, {
        withCredentials: true,
      });
      return response.data.data;
    } catch (error) {
      throw error;
    }
  }

  async resend(phoneNumber: string) {
    try {
      const response = await this.instance.post(
        "/auth/resend",
        { phoneNumber },
        {
          withCredentials: true,
        }
      );
      return response.data.status;
    } catch (error) {
      throw error;
    }
  }

  async refreshToken(): Promise<{ accessToken: string; refreshToken: string }> {
    try {
      const response = await this.instance.post("/auth/refresh-token", {
        withCredentials: true,
      });
      return response.data.data;
    } catch (error) {
      throw error;
    }
  }

  async forgotPassword(phoneNumber: string) {
    try {
      await this.instance.post("/auth/forgot-password", {
        phoneNumber,
      });
    } catch (error) {
      throw error;
    }
  }

  async verifyReset(
    phoneNumber: string,
    code: string
  ): Promise<{ resetToken: string }> {
    try {
      const response = await this.instance.post("/auth/verify-reset", {
        phoneNumber,
        code,
      });
      return response.data.data;
    } catch (error) {
      throw error;
    }
  }

  async resetPassword(resetToken: string, newPassword: string) {
    try {
      await this.instance.post("/auth/reset-password", {
        resetToken,
        newPassword,
      });
    } catch (error) {
      throw error;
    }
  }
}
