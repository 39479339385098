import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "bg-white rounded-md p-3 text-center shadow" }
const _hoisted_2 = { class: "font-semibold text-4xl" }
const _hoisted_3 = { class: "text-gray-500 -mt-1" }



export default /*@__PURE__*/_defineComponent({
  __name: 'StatsCard',
  props: {
    name: {},
    value: {},
    icon: {}
  },
  setup(__props: any) {

const props = __props;

return (_ctx: any,_cache: any) => {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, _toDisplayString(props.value), 1),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_font_awesome_icon, {
        icon: props.icon,
        class: "mr-1"
      }, null, 8, ["icon"]),
      _createTextVNode(_toDisplayString(props.name), 1)
    ])
  ]))
}
}

})