import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createVNode as _createVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, vModelText as _vModelText, normalizeClass as _normalizeClass, withDirectives as _withDirectives, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "text-xs font-semibold px-1 flex items-center overflow-auto"
}
const _hoisted_2 = { class: "flex gap-1 ml-2" }
const _hoisted_3 = { class: "flex items-center gap-3" }
const _hoisted_4 = ["placeholder", "disabled"]

import { ref, watch } from "vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'BaseArrayInput',
  props: {
  modelValue: { type: Array, required: true },
  placeholder: { type: String },
  label: { type: String },
  icon: { type: String },
  disabled: { type: Boolean },
  max: { type: Number },
},
  emits: ["update:modelValue"],
  setup(__props, { emit: __emit }) {

const props = __props;

const arr = ref<string[]>([...props.modelValue] as string[]);

const emit = __emit;

const inputValue = ref("");

function remove(j: string) {
  arr.value = arr.value.filter((i) => i !== j);
}

function add(e?: Event) {
  if (e) {
    e.preventDefault();
  }
  if (
    (!props.max || arr.value.length < props.max) &&
    inputValue.value.trim().length
  ) {
    arr.value.push(inputValue.value);
    inputValue.value = "";
  }
}

function handleKeydown(e: KeyboardEvent) {
  if (e.key === ",") {
    e.preventDefault();
    add();
  }
}

watch(
  () => props.modelValue,
  (newVal, oldVal) => {
    if (!oldVal.length && newVal.length) {
      arr.value = [...newVal] as string[];
    }
  },
  { deep: true }
);

watch(
  () => arr.value,
  (newValue) => {
    emit("update:modelValue", [...newValue]);
  },
  { deep: true }
);

return (_ctx: any,_cache: any) => {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_BaseButton = _resolveComponent("BaseButton")!

  return (_openBlock(), _createElementBlock("form", {
    class: "w-full relative",
    onSubmit: add
  }, [
    (__props.label)
      ? (_openBlock(), _createElementBlock("label", _hoisted_1, [
          (__props.icon)
            ? (_openBlock(), _createBlock(_component_font_awesome_icon, {
                key: 0,
                icon: __props.icon,
                class: "absolute top-[38px] left-3 text-gray-400"
              }, null, 8, ["icon"]))
            : _createCommentVNode("", true),
          _createTextVNode(" " + _toDisplayString(__props.label) + " ", 1),
          _createElementVNode("div", _hoisted_2, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(arr.value, (i) => {
              return (_openBlock(), _createElementBlock("div", {
                key: i,
                class: "bg-secondaryTint text-secondaryShade border-2 border-secondaryShade pl-2 py-1 rounded-full min-w-12 text-xs h-6 font-semibold flex items-center justify-center bg-opacity-30"
              }, [
                _createTextVNode(_toDisplayString(i) + " ", 1),
                _createVNode(_component_font_awesome_icon, {
                  icon: "x",
                  size: "2xs",
                  class: "p-1 rounded-full cursor-pointer hover:bg-opacity-30 bg-secondaryShade bg-opacity-0 ml-1",
                  onClick: ($event: any) => (remove(i))
                }, null, 8, ["onClick"])
              ]))
            }), 128))
          ])
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_3, [
      _withDirectives(_createElementVNode("input", {
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((inputValue).value = $event)),
        placeholder: __props.placeholder,
        disabled: __props.disabled,
        onBlur: add,
        onKeydown: handleKeydown,
        class: _normalizeClass([
          'w-full pr-3 py-2 rounded-lg border-2 border-gray-200 outline-none focus:border-secondary',
          __props.icon ? 'pl-7' : 'pl-3',
        ])
      }, null, 42, _hoisted_4), [
        [_vModelText, inputValue.value]
      ]),
      _createVNode(_component_BaseButton, {
        type: "secondary",
        class: "!w-32",
        "native-type": "submit",
        disabled: props.max && arr.value.length >= props.max,
        title: 
          props.max && arr.value.length >= props.max
            ? `Daha fazla ekleyemezsiniz.`
            : ''
        
      }, {
        default: _withCtx(() => _cache[1] || (_cache[1] = [
          _createTextVNode("EKLE")
        ])),
        _: 1
      }, 8, ["disabled", "title"])
    ])
  ], 32))
}
}

})