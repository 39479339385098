<template>
  <router-view v-slot="{ Component }">
    <transition name="page-flip" mode="out-in">
      <component :is="Component" />
    </transition>
  </router-view>
</template>

<script setup lang="ts">
import { watch, computed } from "vue";
import { useStore } from "vuex";
import { addAxiosInterceptors } from "./utils/api/interceptors";
import { axiosInstance } from "./utils/api/instance";

const store = useStore();
const token = computed(() => store.getters["token"]);

watch(
  () => token,
  () => {
    addAxiosInterceptors(axiosInstance);
  },
  { deep: true }
);
</script>

<style lang="scss">
#app {
  font-family: "League Spartan", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  @apply bg-light min-h-full;
}

.page-flip-enter-active {
  animation: flip-page-in 0.5s ease-out;
}

.page-flip-leave-active {
  animation: flip-page-out 0.5s ease-in;
}

@keyframes flip-page-in {
  0% {
    transform: rotateY(-90deg);
    opacity: 0;
  }
  50% {
    transform: rotateY(0deg);
    opacity: 1;
  }
  100% {
    transform: rotateY(0deg);
  }
}

@keyframes flip-page-out {
  0% {
    transform: rotateY(0deg);
    opacity: 1;
  }
  50% {
    transform: rotateY(90deg);
    opacity: 0.5;
  }
  100% {
    transform: rotateY(90deg);
    opacity: 0;
  }
}

.page-flip-enter-from {
  transform: rotateY(-90deg);
  opacity: 0;
}

.page-flip-leave-to {
  transform: rotateY(90deg);
  opacity: 0;
}

body,
html {
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
  height: 100%;
}

.router-view {
  width: 100vw;
  height: 100vh;
  perspective: 1000px;
}
</style>
