<template>
  <div
    :class="[
      $attrs.class,
      'bg-white border shadow-sm rounded-lg p-6',
      { 'w-full': props.fullWidth },
    ]"
    v-bind="$attrs"
  >
    <slot />
  </div>
</template>

<script setup lang="ts">
import { defineProps } from "vue";

const props = defineProps<{
  fullWidth: boolean;
}>();
</script>
