<template>
  <teleport to="body">
    <div
      v-if="isVisible"
      class="fixed inset-0 flex items-center justify-center bg-primaryTint bg-opacity-80 z-[100000] pt-12"
      @click.self="close"
    >
      <div
        class="bg-white rounded-lg shadow-lg max-h-[800px] md:max-w-[80%] max-w-[95%] p-6 relative mt-10"
      >
        <button
          @click="close"
          class="absolute top-2 right-2 text-typograhy-header hover:text-typograhy-text"
        >
          &times;
        </button>

        <slot />
      </div>
    </div>
  </teleport>
</template>

<script lang="ts" setup>
import { defineProps, defineEmits, ref, watch } from "vue";

const props = defineProps<{
  modelValue: boolean;
}>();

const emit = defineEmits<{
  (e: "update:modelValue", value: boolean): void;
}>();

const isVisible = ref(props.modelValue);

watch(
  () => props.modelValue,
  (newValue) => {
    isVisible.value = newValue;
  }
);

const close = () => {
  isVisible.value = false;
  emit("update:modelValue", false);
};
</script>
