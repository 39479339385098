import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "fixed inset-0 z-[1000]"
}
const _hoisted_2 = { class: "py-2 px-4 h-full overflow-y-auto" }
const _hoisted_3 = { class: "flex items-center gap-4" }
const _hoisted_4 = { class: "p-4 h-full" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createBlock(_Transition, {
    name: "drawer",
    mode: "out-in",
    duration: { enter: 300, leave: 300 }
  }, {
    default: _withCtx(() => [
      (_ctx.isOpen)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createElementVNode("div", {
              onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.closeDrawer && _ctx.closeDrawer(...args))),
              class: "fixed inset-0 bg-black bg-opacity-70"
            }),
            _createElementVNode("div", {
              class: _normalizeClass([
          'drawer-panel fixed right-0 bottom-0 shadow-lg w-full',
          _ctx.isFullScreen
            ? 'sm:!w-[calc(100%-288px)] !bg-light'
            : 'sm:w-1/3 bg-white',
        ])
            }, [
              _createElementVNode("div", _hoisted_2, [
                _createElementVNode("div", _hoisted_3, [
                  _createVNode(_component_font_awesome_icon, {
                    icon: "x",
                    size: "xs",
                    class: "cursor-pointer text-gray-500",
                    onClick: _ctx.closeDrawer
                  }, null, 8, ["onClick"]),
                  _renderSlot(_ctx.$slots, "title", {}, undefined, true)
                ]),
                _createElementVNode("div", _hoisted_4, [
                  _renderSlot(_ctx.$slots, "default", {}, undefined, true)
                ])
              ])
            ], 2)
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 3
  }))
}