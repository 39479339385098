<template>
  <div class="markdown-renderer text-left">
    <div v-html="htmlContent"></div>
  </div>
</template>

<script lang="ts" setup>
import { ref, onMounted, defineProps } from "vue";
import markdownIt from "markdown-it";
import { watch } from "vue";

const props = defineProps<{
  url?: string;
  content?: string;
}>();

const htmlContent = ref<string>("");

async function setHtmlContent() {
  if (props.url) {
    try {
      const response = await fetch(props.url as string);
      if (!response.ok) {
        throw new Error(`Failed to fetch: ${response.statusText}`);
      }
      const markdown = await response.text();
      const md = new markdownIt();
      htmlContent.value = md.render(markdown);
    } catch (error) {
      console.error("Error loading Markdown:", error);
      htmlContent.value = "<p>Rapor yüklenirken bir hata oluştu.</p>";
    }
  } else if (props.content) {
    const md = new markdownIt();
    htmlContent.value = md.render(props.content);
  }
}

watch(
  () => props.content,
  (val) => {
    if (val !== undefined) {
      const md = new markdownIt();
      htmlContent.value = md.render(val);
    }
  }
);

onMounted(async () => {
  await setHtmlContent();
});
</script>

<style>
.markdown-renderer {
  @apply font-sans leading-6  p-4;
}

.markdown-renderer h1 {
  @apply text-xl font-bold border-b-2 border-gray-300 pb-1 mt-4;
}

.markdown-renderer h2 {
  @apply text-lg font-bold mt-4 mb-2;
}

.markdown-renderer h3 {
  @apply text-base font-bold mt-4;
}

.markdown-renderer p {
  @apply my-2;
}

.markdown-renderer a {
  @apply text-secondaryShade cursor-pointer;
}

.markdown-renderer a:hover {
  @apply no-underline;
}

.markdown-renderer ol {
  @apply list-decimal ml-6 pl-2;
}

.markdown-renderer ol li {
  @apply my-2;
}

.markdown-renderer img {
  @apply max-h-96;
}

.markdown-renderer ul {
  @apply list-none ml-6 pl-2;
}

.markdown-renderer ul li {
  @apply relative my-2 pl-4;
}

.markdown-renderer ul li::before {
  content: "-";
  @apply absolute left-0 text-blue-600 font-bold text-base;
}

.markdown-renderer code {
  @apply px-1 rounded font-mono text-sm;
}

.markdown-renderer pre {
  @apply bg-gray-800 text-secondary p-3 border border-gray-300 rounded overflow-x-auto;
}

.markdown-renderer blockquote {
  @apply border-l-4 border-gray-300 my-2 pl-4 text-gray-600 italic;
}
</style>
