import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex flex-col gap-4 px-3 text-left" }
const _hoisted_2 = { class: "" }
const _hoisted_3 = { class: "text-left" }

import { ConsultationDetail } from "@/utils/api";
import { ref, watch } from "vue";
import { onMounted } from "vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'ConsultationForm',
  props: {
    modelValue: {}
  },
  emits: ["update:modelValue"],
  setup(__props: any, { emit: __emit }) {

const emit = __emit;

const props = __props;

const formData = ref<ConsultationDetail>({
  description: "",
  type: "",
  location: [],
  symptoms: [],
  when: "",
});

const timeOptions = ref([
  {
    label: "Dakikalar-saatler",
    value: "Dakikalar-saatler",
  },
  {
    label: "Günler-haftalar",
    value: "Günler-haftalar",
  },
  {
    label: "Aylar-yıllar",
    value: "Aylar-yıllar",
  },
  {
    label: "Belli aralıklarla tekrarlayan şikayetler",
    value: "Belli aralıklarla tekrarlayan şikayetler",
  },
  {
    label: "Doğuştan beri",
    value: "Doğuştan beri",
  },
]);

const descriptionOptions = ref([
  {
    label: "Birden çok lezyon",
    value: "Birden çok lezyon",
  },
  {
    label: "Tek cilt lezyonu",
    value: "Tek cilt lezyonu",
  },
  {
    label: "Tırnak lezyonu",
    value: "Tırnak lezyonu",
  },
  {
    label: "Saç lezyonu",
    value: "Saç lezyonu",
  },
  {
    label: "Kaşıntı",
    value: "Kaşıntı",
  },
  {
    label: "Diğer",
    value: "Diğer",
  },
]);

const typeOptions = ref([
  {
    label: "Su toplaması",
    value: "Su toplaması",
    image: "/img/derma/su.jpeg",
  },
  {
    label: "Kabarıklık",
    value: "Kabarıklık",
    image: "/img/derma/kabariklik.jpeg",
  },
  {
    label: "Kızarıklık",
    value: "Kızarıklık",
    image: "/img/derma/kizariklik.jpeg",
  },
  {
    label: "Yara",
    value: "Yara",
    image: "/img/derma/yara.jpeg",
  },
  {
    label: "Diğer",
    value: "Diğer",
  },
]);

const locationOptions = ref([
  {
    label: "Saçlı deri",
    value: "Saçlı deri",
  },
  {
    label: "Yüz",
    value: "Yüz",
  },
  {
    label: "Boyun",
    value: "Boyun",
  },
  {
    label: "Kollar",
    value: "Kollar",
  },
  {
    label: "Eller",
    value: "Eller",
  },
  {
    label: "Gövde",
    value: "Gövde",
  },
  {
    label: "Genital bölge",
    value: "Genital bölge",
  },
  {
    label: "Bacaklar",
    value: "Bacaklar",
  },
  {
    label: "Ayaklar",
    value: "Ayaklar",
  },
]);

const symptomOptions = ref([
  {
    label: "Kaşıntı",
    value: "Kaşıntı",
  },
  {
    label: "Ağrı",
    value: "Ağrı",
  },
  {
    label: "Ateş",
    value: "Ateş",
  },
]);

onMounted(() => {
  formData.value = { ...props.modelValue };
});
watch(
  formData,
  (newValue) => {
    emit("update:modelValue", newValue);
  },
  { deep: true }
);

return (_ctx: any,_cache: any) => {
  const _component_BaseCheckboxGroup = _resolveComponent("BaseCheckboxGroup")!

  return (_openBlock(), _createElementBlock("form", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_BaseCheckboxGroup, {
        options: descriptionOptions.value,
        modelValue: formData.value.description,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((formData.value.description) = $event)),
        label: "Ana Şikayet"
      }, null, 8, ["options", "modelValue"])
    ]),
    _createElementVNode("div", null, [
      _createVNode(_component_BaseCheckboxGroup, {
        options: typeOptions.value,
        modelValue: formData.value.type,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((formData.value.type) = $event)),
        label: "Lezyon Tipi",
        hasImage: true
      }, null, 8, ["options", "modelValue"])
    ]),
    _createElementVNode("div", null, [
      _createVNode(_component_BaseCheckboxGroup, {
        options: symptomOptions.value,
        modelValue: formData.value.symptoms,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((formData.value.symptoms) = $event)),
        label: "Eşlik Eden Semptomlar",
        multiple: true
      }, null, 8, ["options", "modelValue"])
    ]),
    _createElementVNode("div", null, [
      _createVNode(_component_BaseCheckboxGroup, {
        modelValue: formData.value.location,
        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((formData.value.location) = $event)),
        label: "Lokasyon",
        options: locationOptions.value,
        multiple: true
      }, null, 8, ["modelValue", "options"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_BaseCheckboxGroup, {
        options: timeOptions.value,
        modelValue: formData.value.when,
        "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((formData.value.when) = $event)),
        label: "Şikayetin Başlangıcı"
      }, null, 8, ["options", "modelValue"])
    ])
  ]))
}
}

})