import { defineComponent as _defineComponent } from 'vue'
import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex flex-col gap-4 mt-2" }
const _hoisted_2 = { class: "w-full" }
const _hoisted_3 = { class: "w-1/2" }
const _hoisted_4 = { class: "w-full" }

import { ref, watch } from "vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'Billing',
  props: {
    modelValue: {}
  },
  emits: ["update:modelValue"],
  setup(__props: any, { emit: __emit }) {



const emit = __emit;

const formData = ref<{ identityNumber: string; email: string }>({
  identityNumber: "",
  email: "",
});

watch(
  () => formData.value,
  (newValue) => {
    emit("update:modelValue", newValue);
  },
  { deep: true }
);

return (_ctx: any,_cache: any) => {
  const _component_BaseLabel = _resolveComponent("BaseLabel")!
  const _component_BaseInput = _resolveComponent("BaseInput")!
  const _component_BaseCard = _resolveComponent("BaseCard")!

  return (_openBlock(), _createBlock(_component_BaseCard, { class: "my-4" }, {
    default: _withCtx(() => [
      _createVNode(_component_BaseLabel, { size: "xl" }, {
        default: _withCtx(() => _cache[1] || (_cache[1] = [
          _createTextVNode("Fatura Bilgileri")
        ])),
        _: 1
      }),
      _createElementVNode("form", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createVNode(_component_BaseInput, {
                modelValue: formData.value.email,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((formData.value.email) = $event)),
                label: "Email *",
                placeholder: ""
              }, null, 8, ["modelValue"])
            ])
          ])
        ]),
        _cache[2] || (_cache[2] = _createElementVNode("span", { class: "text-xs" }, "* Lütfen bütün bilgileri eksiksiz doldurunuz.", -1))
      ])
    ]),
    _: 1
  }))
}
}

})