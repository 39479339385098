import { defineComponent as _defineComponent } from 'vue'
import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, unref as _unref, createBlock as _createBlock } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = {
  key: 1,
  class: "text-xs"
}
const _hoisted_3 = { class: "my-4" }
const _hoisted_4 = { class: "w-full" }
const _hoisted_5 = { class: "w-full" }
const _hoisted_6 = { class: "w-full flex items-center gap-2" }
const _hoisted_7 = { class: "w-1/2" }
const _hoisted_8 = { class: "w-1/2" }
const _hoisted_9 = { class: "w-full" }

import { ApiService, Address, AddressModel } from "@/utils/api";
import cities from "@/assets/cities.json";

import { ref, computed, onMounted, watch } from "vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'AddressForm',
  props: {
    modelValue: {}
  },
  emits: ["update:modelValue"],
  setup(__props: any, { emit: __emit }) {



const isFormVisible = ref(false);

const emit = __emit;

const selectedAddress = ref<string>();
const loading = ref(false);

const addresses = ref<AddressModel[]>([]);

const address = ref<Address>({
  city: "",
  district: "",
  name: "",
  detail: "",
  zipCode: "",
});

const districts = computed(() => {
  const province = cities.filter(
    (city: { text: string; districts: { text: string }[] }) =>
      city.text === address.value.city
  )[0];

  return province
    ? province.districts.map((d: { text: string }) => {
        return { key: d.text, label: d.text };
      })
    : [];
});

const provinces = cities.map((city: { text: string }) => {
  return { key: city.text, label: city.text };
});

const addressOptions = computed(() =>
  addresses.value.map((a) => {
    return { label: a.name, value: a._id };
  })
);

async function listAddresses() {
  ApiService.profile.address
    .list()
    .then((res) => {
      addresses.value = res;
    })
    .catch((err) => {
      console.error(err);
      addresses.value = [];
    });
}

async function createAddress(e: Event) {
  loading.value = true;
  e.preventDefault();
  ApiService.profile.address
    .create(address.value as Address)
    .then((res) => {
      console.log(res);
      addresses.value.push(res);
      address.value = { city: "", district: "", name: "", detail: "" };
    })
    .catch((err) => {
      console.error(err);
    })
    .finally(() => {
      loading.value = false;
    });
}

watch(
  () => selectedAddress.value,
  (newValue) => {
    let address = addresses.value.find((a) => a._id === newValue);

    emit("update:modelValue", address);
  },
  { deep: true }
);

onMounted(async () => {
  await listAddresses();
});

return (_ctx: any,_cache: any) => {
  const _component_BaseLabel = _resolveComponent("BaseLabel")!
  const _component_BaseCheckboxGroup = _resolveComponent("BaseCheckboxGroup")!
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_BaseInput = _resolveComponent("BaseInput")!
  const _component_BaseTextarea = _resolveComponent("BaseTextarea")!
  const _component_BaseSelect = _resolveComponent("BaseSelect")!
  const _component_BaseButton = _resolveComponent("BaseButton")!
  const _component_BaseCard = _resolveComponent("BaseCard")!

  return (_openBlock(), _createBlock(_component_BaseCard, { class: "my-4" }, {
    default: _withCtx(() => [
      _createElementVNode("div", null, [
        _createVNode(_component_BaseLabel, { size: "xl" }, {
          default: _withCtx(() => _cache[7] || (_cache[7] = [
            _createTextVNode("Adres Bilgileri")
          ])),
          _: 1
        })
      ]),
      _createVNode(_component_BaseLabel, null, {
        default: _withCtx(() => _cache[8] || (_cache[8] = [
          _createTextVNode("Kayıtlı adreslerinden seç")
        ])),
        _: 1
      }),
      (addresses.value.length)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createVNode(_component_BaseCheckboxGroup, {
              options: addressOptions.value,
              modelValue: selectedAddress.value,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((selectedAddress).value = $event)),
              multiple: false
            }, null, 8, ["options", "modelValue"])
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_2, "Lütfen bir adres kayıt edin.")),
      _createElementVNode("div", {
        class: "text-center w-full mt-4 cursor-pointer text-secondary hover:bg-lightTint rounded-md py-2",
        onClick: _cache[1] || (_cache[1] = ($event: any) => (isFormVisible.value = !isFormVisible.value))
      }, [
        _createVNode(_component_font_awesome_icon, { icon: "plus-circle" }),
        _cache[9] || (_cache[9] = _createTextVNode(" Yeni bir adres oluştur "))
      ]),
      (isFormVisible.value)
        ? (_openBlock(), _createElementBlock("form", {
            key: 2,
            onSubmit: createAddress,
            class: "flex flex-col gap-4 mt-2"
          }, [
            _createElementVNode("div", _hoisted_3, [
              _cache[11] || (_cache[11] = _createElementVNode("hr", { class: "pb-4" }, null, -1)),
              _createVNode(_component_BaseLabel, null, {
                default: _withCtx(() => _cache[10] || (_cache[10] = [
                  _createTextVNode("Yeni bir adres kayıt et")
                ])),
                _: 1
              })
            ]),
            _createElementVNode("div", _hoisted_4, [
              _createVNode(_component_BaseInput, {
                modelValue: address.value.name,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((address.value.name) = $event)),
                label: "İsim *",
                placeholder: "Adresinize bir isim verin"
              }, null, 8, ["modelValue"])
            ]),
            _createElementVNode("div", _hoisted_5, [
              _createVNode(_component_BaseTextarea, {
                modelValue: address.value.detail,
                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((address.value.detail) = $event)),
                label: "Adres *",
                placeholder: "Lütfen açık adresinizi girin"
              }, null, 8, ["modelValue"])
            ]),
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("div", _hoisted_7, [
                _createVNode(_component_BaseSelect, {
                  modelValue: address.value.city,
                  "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((address.value.city) = $event)),
                  label: "İl *",
                  options: _unref(provinces)
                }, null, 8, ["modelValue", "options"])
              ]),
              _createElementVNode("div", _hoisted_8, [
                _createVNode(_component_BaseSelect, {
                  modelValue: address.value.district,
                  "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((address.value.district) = $event)),
                  label: "İlçe *",
                  options: districts.value
                }, null, 8, ["modelValue", "options"])
              ])
            ]),
            _createElementVNode("div", _hoisted_9, [
              _createVNode(_component_BaseInput, {
                modelValue: address.value.zipCode,
                "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((address.value.zipCode) = $event)),
                label: "Posta Kodu"
              }, null, 8, ["modelValue"])
            ]),
            _createVNode(_component_BaseButton, {
              type: "submit",
              loading: loading.value,
              disabled: 
          !(address.value.city && address.value.detail && address.value.district && address.value.name)
        
            }, {
              default: _withCtx(() => _cache[12] || (_cache[12] = [
                _createTextVNode("YENİ ADRES OLUŞTUR")
              ])),
              _: 1
            }, 8, ["loading", "disabled"]),
            _cache[13] || (_cache[13] = _createElementVNode("span", { class: "text-xs" }, "* Lütfen bütün bilgileri eksiksiz doldurunuz.", -1))
          ], 32))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}
}

})