<template>
  <div class="relative inline-flex">
    <div
      class="absolute h-full w-full bg-primary opacity-0 hover:opacity-50 transition-all duration-100 z-[999] cursor-zoom-in"
      @click="isModalOpen = true"
    ></div>
    <img :src="props.source" class="max-h-96" />

    <BaseDrawer
      :isOpen="isModalOpen"
      @close="isModalOpen = false"
      :isFullScreen="true"
    >
      <img :src="props.source" />
    </BaseDrawer>
  </div>
</template>

<script setup lang="ts">
import { defineProps, ref } from "vue";
const props = defineProps<{
  source: string;
}>();

const isModalOpen = ref(false);
</script>
