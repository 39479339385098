import store from "@/store";
import type { AxiosInstance } from "axios";

import CryptoJS from "crypto-js";

export function addAxiosInterceptors(instance: AxiosInstance) {
  instance.interceptors.request.use((config) => {
    const token = localStorage.getItem("accessToken");

    const refreshToken = localStorage.getItem("refreshToken");

    if (token) {
      (config.headers as Record<string, any>).Authorization = token;
      (config.headers as Record<string, any>)["Refresh-Token"] = refreshToken;
    }

    const timestamp = Date.now();
    const method = config.method?.toUpperCase() || "";
    const endpoint = config.url || "";
    const secret = process.env.VUE_APP_CLIENT_SECRET;

    const message = `${method}|${endpoint}|${timestamp}`;
    console.log(message);
    const signature = CryptoJS.HmacSHA256(message, secret).toString(
      CryptoJS.enc.Hex
    );

    (config.headers as Record<string, any>)["x-ts"] = timestamp;
    (config.headers as Record<string, any>)["x-signature"] = signature;

    return config;
  });

  instance.interceptors.response.use(
    (res) => res,
    async (axiosErr) => {
      if (axiosErr?.response?.status === 498) {
        await store.dispatch("REFRESH_ACCESS_TOKEN");

        const newToken = localStorage.getItem("accessToken");

        axiosErr.config.headers.Authorization = newToken;

        return await instance(axiosErr.config);
      }
      return Promise.reject(axiosErr);
    }
  );

  return instance;
}
