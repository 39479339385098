import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, renderSlot as _renderSlot, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Teleport as _Teleport, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "bg-white rounded-lg shadow-lg max-h-[800px] md:max-w-[80%] max-w-[95%] p-6 relative mt-10" }

import { ref, watch } from "vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'BaseModal',
  props: {
    modelValue: { type: Boolean }
  },
  emits: ["update:modelValue"],
  setup(__props: any, { emit: __emit }) {

const props = __props;

const emit = __emit;

const isVisible = ref(props.modelValue);

watch(
  () => props.modelValue,
  (newValue) => {
    isVisible.value = newValue;
  }
);

const close = () => {
  isVisible.value = false;
  emit("update:modelValue", false);
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_Teleport, { to: "body" }, [
    (isVisible.value)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: "fixed inset-0 flex items-center justify-center bg-primaryTint bg-opacity-80 z-[100000] pt-12",
          onClick: _withModifiers(close, ["self"])
        }, [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("button", {
              onClick: close,
              class: "absolute top-2 right-2 text-typograhy-header hover:text-typograhy-text"
            }, " × "),
            _renderSlot(_ctx.$slots, "default")
          ])
        ]))
      : _createCommentVNode("", true)
  ]))
}
}

})