import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "w-full relative" }
const _hoisted_2 = {
  key: 0,
  class: "text-xs font-semibold"
}
const _hoisted_3 = { class: "flex" }
const _hoisted_4 = {
  key: 0,
  class: "text-red-500 absolute -bottom-4 text-xs"
}

import { ref, watch, computed } from "vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'BasePhoneInput',
  props: {
  modelValue: {
    type: String || Date,
    required: true,
  },
  placeholder: {
    type: String,
    default: "",
  },
  label: {
    type: String,
    default: "",
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  errorText: {
    type: String,
    default: "Lütfen geçerli bir telefon numarası giriniz.",
  },
},
  emits: ["update:modelValue"],
  setup(__props, { emit: __emit }) {

const touched = ref(false);

const props = __props;

function isRegexCompatible(input: string, pattern: RegExp): boolean {
  try {
    const regex = new RegExp(pattern);
    return regex.test(input);
  } catch (e) {
    console.error("Invalid regex pattern:", (e as Error).message);
    return false;
  }
}

const pattern = new RegExp(/^\+905\d{9}$/);

const isValid = computed(() => {
  return isRegexCompatible(props.modelValue, pattern);
});

const emit = __emit;

const inputValue = ref(props.modelValue);

watch(
  () => props.modelValue,
  (newValue) => {
    inputValue.value = newValue;
  }
);

watch(inputValue, (newValue) => {
  emit("update:modelValue", newValue.replace(/\s+/g, ""));
});

return (_ctx: any,_cache: any) => {
  const _component_vue_tel_input = _resolveComponent("vue-tel-input")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (__props.label)
      ? (_openBlock(), _createElementBlock("label", _hoisted_2, _toDisplayString(__props.label), 1))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_vue_tel_input, {
        modelValue: inputValue.value,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((inputValue).value = $event)),
        class: "'w-full pr-3 py-1 !rounded-lg !border-2 w-full !border-gray-200 !outline-none focus-within:!border-secondary focus-within:!shadow-none",
        "valid-characters-only": true,
        onFocus: _cache[1] || (_cache[1] = ($event: any) => (touched.value = true)),
        disabled: __props.disabled
      }, null, 8, ["modelValue", "disabled"]),
      (!isValid.value && touched.value)
        ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(__props.errorText), 1))
        : _createCommentVNode("", true)
    ])
  ]))
}
}

})