<template>
  <form @submit.prevent="handleSubmit" class="relative">
    <div class="relative">
      <textarea
        v-model="message"
        placeholder="Mesajınızı yazın..."
        class="w-full rounded-lg border p-2 pr-12 block"
        @keydown.enter.prevent="handleEnterKey"
      />
      <button
        type="submit"
        class="absolute right-2 top-2 font-semibold bg-primaryTint text-white rounded-md px-3 py-1.5 disabled:opacity-50 disabled:cursor-not-allowed"
        :disabled="(!message.trim() && !selectedFile) || isLoading"
      >
        <div class="flex items-center gap-1">
          <font-awesome-icon
            v-if="isLoading"
            icon="stethoscope"
            class="fast-spin"
          />
          <font-awesome-icon v-else icon="paper-plane" />

          <font-awesome-icon icon="arrow-right-long" />
        </div>
      </button>
    </div>

    <div class="flex items-center mt-2">
      <button
        type="button"
        @click="triggerFileInput"
        class="text-gray-500 hover:text-primaryTint flex items-center gap-1 text-sm"
        :disabled="isLoading"
      >
        <font-awesome-icon icon="paperclip" />
        <span>PDF Dosyası Ekle</span>
      </button>
      <input
        type="file"
        ref="fileInput"
        @change="onFileSelect"
        accept=".pdf"
        class="hidden"
      />
    </div>

    <div v-if="selectedFile" class="mt-2 flex items-center text-sm">
      <span class="text-primaryTint mr-2">
        <font-awesome-icon icon="file-pdf" />
      </span>
      <span class="truncate">{{ selectedFile.name }}</span>
      <button
        type="button"
        @click="removeFile"
        class="ml-2 text-red-500 hover:text-red-700"
      >
        <font-awesome-icon icon="times" />
      </button>
    </div>
  </form>
</template>

<script setup lang="ts">
import { ref, defineProps, defineEmits } from "vue";

const props = defineProps({
  isLoading: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(["send"]);
const message = ref("");
const selectedFile = ref<File | null>(null);
const fileInput = ref<HTMLInputElement | null>(null);

const handleSubmit = () => {
  if ((!message.value.trim() && !selectedFile.value) || props.isLoading) return;
  emit("send", message.value, selectedFile.value);
  message.value = "";
  selectedFile.value = null;
};

const handleEnterKey = (e: KeyboardEvent) => {
  if (e.shiftKey) return; // Allow shift+enter for new line
  handleSubmit();
};

const triggerFileInput = () => {
  if (props.isLoading) return;
  fileInput.value?.click();
};

const onFileSelect = (event: Event) => {
  const input = event.target as HTMLInputElement;
  if (input.files && input.files.length > 0) {
    selectedFile.value = input.files[0];
  }
};

const removeFile = () => {
  selectedFile.value = null;
  if (fileInput.value) {
    fileInput.value.value = "";
  }
};
</script>

<style scoped>
.fast-spin {
  animation: fast-spin 1s linear infinite;
}

@keyframes fast-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
