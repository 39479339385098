<template>
  <div
    class="max-w-2xl sm:max-w-sm md:max-w-sm lg:max-w-sm xl:max-w-sm bg-white shadow-md rounded-lg text-gray-900"
  >
    <div class="rounded-t-lg h-32 overflow-hidden">
      <img
        class="object-cover object-top w-full"
        src="https://images.unsplash.com/photo-1549880338-65ddcdfd017b?ixlib=rb-1.2.1&q=80&fm=jpg&crop=entropy&cs=tinysrgb&w=400&fit=max&ixid=eyJhcHBfaWQiOjE0NTg5fQ"
        alt="Mountain"
      />
    </div>
    <div
      class="mx-auto w-32 h-32 relative -mt-16 border-4 border-white rounded-full overflow-hidden"
    >
      <img
        class="object-cover object-center h-32"
        src="https://cepdoktorum-static-files.s3.eu-north-1.amazonaws.com/pp-placeholder.jpg"
        alt="profile-picture"
      />
    </div>
    <div class="text-center mt-2">
      <h2 class="font-semibold text-primary text-lg">{{ fullName }}</h2>
      <p class="text-gray-500 text-sm" v-if="props.user">
        <font-awesome-icon icon="phone" /> {{ props.user.phoneNumber }}
      </p>
      <p class="text-gray-500 text-sm">
        <font-awesome-icon :icon="gender.icon" /> {{ gender.text }}
      </p>
    </div>
    <ul class="py-4 mt-2 text-gray-700 flex items-center justify-around">
      <li class="flex flex-col items-center justify-around">
        <font-awesome-icon icon="ruler-vertical" class="text-secondaryShade" />
        <div>{{ props.profile?.height }} cm</div>
      </li>
      <li class="flex flex-col items-center justify-between">
        <font-awesome-icon icon="weight-scale" class="text-secondaryShade" />
        <div>{{ props.profile?.weight }} kg</div>
      </li>
      <li class="flex flex-col items-center justify-around">
        <font-awesome-icon icon="birthday-cake" class="text-secondaryShade" />
        <div>
          {{ birthday }}
        </div>
      </li>
    </ul>
    <div class="border-t pt-4 mx-8">
      <li class="flex flex-col items-center justify-around">
        <span class="font-semibold">
          <font-awesome-icon
            icon="square-root-variable"
            class="text-secondaryShade"
          />
          Vücut/Kitle Endeksim
        </span>
        <div class="text-lg">
          {{ calculateBMI(props.profile?.weight, props.profile?.height) }}
        </div>
      </li>
    </div>
    <div v-if="props.user" class="mx-8 border-t py-3 text-center">
      <span class="font-semibold mr-1">Kayıt Olma Tarihi:</span
      >{{ formatDate(props.user.createdAt) }}
    </div>
    <div class="p-4 border-t mx-8 mt-2" v-if="!props.user && !isAdmin">
      <BaseButton @click="isDrawerOpen = true">DÜZENLE</BaseButton>
    </div>

    <BaseDrawer :isOpen="isDrawerOpen" @close="isDrawerOpen = false">
      <template #title>
        <h2 class="font-semibold text-primary text-xl">Profili Düzenle</h2>
      </template>
      <ProfileForm
        type="update"
        :profile="props.profile"
        @update:profile="handleProfileUpdate"
        v-if="props.profile"
      />
    </BaseDrawer>
  </div>
</template>

<script setup lang="ts">
import { Profile, UserModel } from "@/utils/api";
import { calculateBMI } from "@/utils/calculate-bmi";
import moment from "moment";
import { defineProps, computed, ref, defineEmits } from "vue";
moment.locale("tr");

const isDrawerOpen = ref(false);

const props = defineProps<{
  profile: Profile;
  user: UserModel;
  isAdmin: boolean;
}>();

const emit = defineEmits(["update:profile"]);

function handleProfileUpdate(p: Profile) {
  isDrawerOpen.value = false;
  emit("update:profile", p);
}

const fullName = computed(
  () => `${props.profile?.firstName} ${props.profile?.lastName}`
);

const birthday = computed(() =>
  moment(props.profile?.dateOfBirth).format("D MMM YYYY")
);
const gender = computed(() => {
  switch (props.profile?.gender) {
    case "male":
      return { text: "Erkek", icon: "mars" };
    case "female":
      return { text: "Kadın", icon: "venus" };
    default:
      return { text: "Diğer", icon: "venus-mars" };
  }
});

function formatDate(value: Date) {
  return moment(value).format("D MMMM YYYY HH:mm");
}
</script>
