import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "markdown-renderer text-left" }
const _hoisted_2 = ["innerHTML"]

import { ref, onMounted } from "vue";
import markdownIt from "markdown-it";
import { watch } from "vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'MarkdownRenderer',
  props: {
    url: {},
    content: {}
  },
  setup(__props: any) {

const props = __props;

const htmlContent = ref<string>("");

async function setHtmlContent() {
  if (props.url) {
    try {
      const response = await fetch(props.url as string);
      if (!response.ok) {
        throw new Error(`Failed to fetch: ${response.statusText}`);
      }
      const markdown = await response.text();
      const md = new markdownIt();
      htmlContent.value = md.render(markdown);
    } catch (error) {
      console.error("Error loading Markdown:", error);
      htmlContent.value = "<p>Rapor yüklenirken bir hata oluştu.</p>";
    }
  } else if (props.content) {
    const md = new markdownIt();
    htmlContent.value = md.render(props.content);
  }
}

watch(
  () => props.content,
  (val) => {
    if (val !== undefined) {
      const md = new markdownIt();
      htmlContent.value = md.render(val);
    }
  }
);

onMounted(async () => {
  await setHtmlContent();
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", { innerHTML: htmlContent.value }, null, 8, _hoisted_2)
  ]))
}
}

})