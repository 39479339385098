<template>
  <BaseCard
    :class="[
      'relative rounded-3xl border border-transparent cursor-pointer transition duration-500 hover:border-secondary hover:bg-secondary/20',
      props.selectedService?.ID === props.service.ID
        ? '!border-secondary !bg-secondary/20'
        : 'bg-white',
    ]"
  >
    <div
      class="mx-auto flex w-16 h-16 items-center justify-center p-4 rounded-full bg-primary shadow-[0_15px_30px_rgba(71,189,255,0.4)]"
    >
      <img :src="service.IMAGE" />
    </div>
    <div class="font-semibold text-primary text-xl my-4">
      {{ props.service.NAME }}
    </div>
    <div class="flex flex-col items-center">
      <div class="relative">
        <span
          class="bg-secondary p-1 text-2xl rounded-md text-white shadow-[0_15px_30px_rgba(71,189,255,0.4)] px-4"
        >
          <span
            :class="[
              'text-xl font-semibold mb-0',
              user.isInFreeTrial ? ' line-through opacity-50' : '',
            ]"
          >
            {{ props.service.PRICE }}₺
          </span>
          <span
            class="text-sm font-semibold mb-0 ml-2"
            v-if="user.isInFreeTrial"
          >
            İlk danışmanlığınızda ücretsiz!
          </span>
        </span>
      </div>
      <p class="text-grat text-xs mt-2 font-medium" v-if="user.isInFreeTrial">
        Ücretsiz danışmanlık hakkınızdan 1 adet kalmıştır.
      </p>
    </div>
  </BaseCard>
</template>

<script setup lang="ts">
import { defineProps } from "vue";
import { Service } from "@/utils/api";
import { useStore } from "vuex";
import { computed } from "vue";

const props = defineProps<{
  service: Service;
  selectedService: Service | undefined;
}>();

const store = useStore();
const user = computed(() => store.getters["user"]);
</script>
