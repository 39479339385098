import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "relative inline-flex" }
const _hoisted_2 = ["src"]
const _hoisted_3 = ["src"]

import { ref } from "vue";

export default /*@__PURE__*/_defineComponent({
  __name: 'ImageViewer',
  props: {
    source: {}
  },
  setup(__props: any) {

const props = __props;

const isModalOpen = ref(false);

return (_ctx: any,_cache: any) => {
  const _component_BaseDrawer = _resolveComponent("BaseDrawer")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: "absolute h-full w-full bg-primary opacity-0 hover:opacity-50 transition-all duration-100 z-[999] cursor-zoom-in",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (isModalOpen.value = true))
    }),
    _createElementVNode("img", {
      src: props.source,
      class: "max-h-96"
    }, null, 8, _hoisted_2),
    _createVNode(_component_BaseDrawer, {
      isOpen: isModalOpen.value,
      onClose: _cache[1] || (_cache[1] = ($event: any) => (isModalOpen.value = false)),
      isFullScreen: true
    }, {
      default: _withCtx(() => [
        _createElementVNode("img", {
          src: props.source
        }, null, 8, _hoisted_3)
      ]),
      _: 1
    }, 8, ["isOpen"])
  ]))
}
}

})