import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import { computed } from "vue";

interface Props {
  maxHeight: string;
  className?: string;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'ScrollArea',
  props: {
    maxHeight: {},
    className: {}
  },
  setup(__props: any) {

const props = __props;

const computedClass = computed(() => {
  return props.className || "";
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([computedClass.value, "overflow-y-auto"]),
    style: _normalizeStyle({ maxHeight: _ctx.maxHeight })
  }, [
    _renderSlot(_ctx.$slots, "default")
  ], 6))
}
}

})