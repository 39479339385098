<template>
  <form class="flex flex-col gap-4 px-3 text-left">
    <div class="">
      <BaseCheckboxGroup
        :options="descriptionOptions"
        v-model="formData.description"
        label="Ana Şikayet"
      />
    </div>
    <div>
      <BaseCheckboxGroup
        :options="typeOptions"
        v-model="formData.type"
        label="Lezyon Tipi"
        :hasImage="true"
      />
    </div>
    <div>
      <BaseCheckboxGroup
        :options="symptomOptions"
        v-model="formData.symptoms"
        label="Eşlik Eden Semptomlar"
        :multiple="true"
      />
    </div>

    <div>
      <BaseCheckboxGroup
        v-model="formData.location"
        label="Lokasyon"
        :options="locationOptions"
        :multiple="true"
      />
    </div>

    <div class="text-left">
      <BaseCheckboxGroup
        :options="timeOptions"
        v-model="formData.when"
        label="Şikayetin Başlangıcı"
      />
    </div>
  </form>
</template>

<script setup lang="ts">
import { ConsultationDetail } from "@/utils/api";
import { ref, defineProps, defineEmits, watch } from "vue";
import { onMounted } from "vue";

const emit = defineEmits(["update:modelValue"]);

const props = defineProps<{
  modelValue: ConsultationDetail;
}>();

const formData = ref<ConsultationDetail>({
  description: "",
  type: "",
  location: [],
  symptoms: [],
  when: "",
});

const timeOptions = ref([
  {
    label: "Dakikalar-saatler",
    value: "Dakikalar-saatler",
  },
  {
    label: "Günler-haftalar",
    value: "Günler-haftalar",
  },
  {
    label: "Aylar-yıllar",
    value: "Aylar-yıllar",
  },
  {
    label: "Belli aralıklarla tekrarlayan şikayetler",
    value: "Belli aralıklarla tekrarlayan şikayetler",
  },
  {
    label: "Doğuştan beri",
    value: "Doğuştan beri",
  },
]);

const descriptionOptions = ref([
  {
    label: "Birden çok lezyon",
    value: "Birden çok lezyon",
  },
  {
    label: "Tek cilt lezyonu",
    value: "Tek cilt lezyonu",
  },
  {
    label: "Tırnak lezyonu",
    value: "Tırnak lezyonu",
  },
  {
    label: "Saç lezyonu",
    value: "Saç lezyonu",
  },
  {
    label: "Kaşıntı",
    value: "Kaşıntı",
  },
  {
    label: "Diğer",
    value: "Diğer",
  },
]);

const typeOptions = ref([
  {
    label: "Su toplaması",
    value: "Su toplaması",
    image: "/img/derma/su.jpeg",
  },
  {
    label: "Kabarıklık",
    value: "Kabarıklık",
    image: "/img/derma/kabariklik.jpeg",
  },
  {
    label: "Kızarıklık",
    value: "Kızarıklık",
    image: "/img/derma/kizariklik.jpeg",
  },
  {
    label: "Yara",
    value: "Yara",
    image: "/img/derma/yara.jpeg",
  },
  {
    label: "Diğer",
    value: "Diğer",
  },
]);

const locationOptions = ref([
  {
    label: "Saçlı deri",
    value: "Saçlı deri",
  },
  {
    label: "Yüz",
    value: "Yüz",
  },
  {
    label: "Boyun",
    value: "Boyun",
  },
  {
    label: "Kollar",
    value: "Kollar",
  },
  {
    label: "Eller",
    value: "Eller",
  },
  {
    label: "Gövde",
    value: "Gövde",
  },
  {
    label: "Genital bölge",
    value: "Genital bölge",
  },
  {
    label: "Bacaklar",
    value: "Bacaklar",
  },
  {
    label: "Ayaklar",
    value: "Ayaklar",
  },
]);

const symptomOptions = ref([
  {
    label: "Kaşıntı",
    value: "Kaşıntı",
  },
  {
    label: "Ağrı",
    value: "Ağrı",
  },
  {
    label: "Ateş",
    value: "Ateş",
  },
]);

onMounted(() => {
  formData.value = { ...props.modelValue };
});
watch(
  formData,
  (newValue) => {
    emit("update:modelValue", newValue);
  },
  { deep: true }
);
</script>
