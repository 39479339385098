<template>
  <div
    :class="computedClass"
    :style="{ maxHeight: maxHeight }"
    class="overflow-y-auto"
  >
    <slot />
  </div>
</template>

<script lang="ts" setup>
import { computed, defineProps } from "vue";

interface Props {
  maxHeight: string;
  className?: string;
}

const props = defineProps<Props>();

const computedClass = computed(() => {
  return props.className || "";
});
</script>
