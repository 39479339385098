import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, renderSlot as _renderSlot } from "vue"

const _hoisted_1 = { class: "relative overflow-x-auto shadow-md sm:rounded-lg" }
const _hoisted_2 = { class: "w-full text-md text-left text-gray-500" }
const _hoisted_3 = { class: "text-md text-primaryTint bg-gray-50" }
const _hoisted_4 = { class: "flex items-center" }
const _hoisted_5 = {
  key: 0,
  class: "ml-2 flex flex-col items-center space-y-[-12px] cursor-pointer"
}
const _hoisted_6 = { key: 0 }
const _hoisted_7 = ["colspan"]
const _hoisted_8 = { key: 1 }
const _hoisted_9 = { class: "px-6 py-4 text-right" }

import moment from "moment";
import { ref, computed } from "vue";

interface Column {
  label: string;
  prop: string;
  type: "string" | "float" | "integer" | "date" | "boolean";
  isSortable: boolean;
  minWidth?: number;
}

interface DataRow {
  [key: string]: any;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'BaseTable',
  props: {
    columns: {},
    data: {},
    keyProp: {},
    loading: { type: Boolean },
    fixedColumns: { default: 0 }
  },
  setup(__props: any) {

const props = __props;

const sortState = ref<{ prop: string; direction: "asc" | "desc" | null }>({
  prop: "",
  direction: null,
});

const sortColumn = (prop: string, direction: "asc" | "desc") => {
  if (
    sortState.value.prop === prop &&
    sortState.value.direction === direction
  ) {
    sortState.value.prop = "";
    sortState.value.direction = null;
  } else {
    sortState.value.prop = prop;
    sortState.value.direction = direction;
  }
};

const getSortIconClass = (prop: string, direction: "asc" | "desc") => {
  if (sortState.value.prop !== prop) return "text-primary";
  return sortState.value.direction === direction
    ? "text-secondary"
    : "text-primary";
};

const sortedData = computed(() => {
  if (!sortState.value.direction) return props.data;

  return [...props.data].sort((a, b) => {
    const valA = getNestedValue(a, sortState.value.prop);
    const valB = getNestedValue(b, sortState.value.prop);

    if (sortState.value.direction === "asc") {
      return valA > valB ? 1 : valA < valB ? -1 : 0;
    } else {
      return valA < valB ? 1 : valA > valB ? -1 : 0;
    }
  });
});

const formatData = (value: any, type: Column["type"]) => {
  switch (type) {
    case "date":
      return moment(value).format("D MMMM YYYY HH:mm");
    case "float":
      return parseFloat(value).toFixed(2);
    case "integer":
      return parseInt(value, 10);
    case "boolean":
      return value ? "Evet" : "Hayır";
    default:
      return value;
  }
};

const getNestedValue = (obj: any, path: string) => {
  return path.split(".").reduce((acc, part) => acc && acc[part], obj);
};

const getFixedColumnClass = (index: number) => {
  return index < props.fixedColumns ? "sticky left-0 bg-white z-10" : "";
};

return (_ctx: any,_cache: any) => {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("table", _hoisted_2, [
      _createElementVNode("thead", _hoisted_3, [
        _createElementVNode("tr", null, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.columns, (column, index) => {
            return (_openBlock(), _createElementBlock("th", {
              key: index,
              scope: "col",
              class: _normalizeClass(["px-6 py-3", getFixedColumnClass(index)]),
              style: _normalizeStyle({
              minWidth: column.minWidth ? column.minWidth + 'px' : 'auto',
            })
            }, [
              _createElementVNode("div", _hoisted_4, [
                _createTextVNode(_toDisplayString(column.label) + " ", 1),
                (column.isSortable)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                      _createVNode(_component_font_awesome_icon, {
                        icon: "sort-up",
                        class: _normalizeClass([getSortIconClass(column.prop, 'asc')]),
                        onClick: ($event: any) => (sortColumn(column.prop, 'asc'))
                      }, null, 8, ["class", "onClick"]),
                      _createVNode(_component_font_awesome_icon, {
                        icon: "sort-down",
                        class: _normalizeClass([getSortIconClass(column.prop, 'desc')]),
                        onClick: ($event: any) => (sortColumn(column.prop, 'desc'))
                      }, null, 8, ["class", "onClick"])
                    ]))
                  : _createCommentVNode("", true)
              ])
            ], 6))
          }), 128)),
          _cache[0] || (_cache[0] = _createElementVNode("th", {
            scope: "col",
            class: "px-6 py-3"
          }, [
            _createElementVNode("span", { class: "sr-only" })
          ], -1))
        ])
      ]),
      (_ctx.loading)
        ? (_openBlock(), _createElementBlock("tbody", _hoisted_6, [
            _createElementVNode("tr", null, [
              _createElementVNode("td", {
                colspan: _ctx.columns.length + 1,
                class: "text-center py-10 text-secondary"
              }, [
                _createVNode(_component_font_awesome_icon, {
                  icon: "stethoscope",
                  class: "fast-spin",
                  size: "xl"
                })
              ], 8, _hoisted_7)
            ])
          ]))
        : (_openBlock(), _createElementBlock("tbody", _hoisted_8, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(sortedData.value, (row) => {
              return (_openBlock(), _createElementBlock("tr", {
                key: row[_ctx.keyProp],
                class: "bg-white border-b hover:bg-gray-50"
              }, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.columns, (column, colIndex) => {
                  return (_openBlock(), _createElementBlock("td", {
                    key: colIndex,
                    class: _normalizeClass(["px-6 py-4", getFixedColumnClass(colIndex)]),
                    style: _normalizeStyle({
              minWidth: column.minWidth ? column.minWidth + 'px' : 'auto',
            })
                  }, _toDisplayString(formatData(getNestedValue(row, column.prop), column.type)), 7))
                }), 128)),
                _createElementVNode("td", _hoisted_9, [
                  _renderSlot(_ctx.$slots, "default", {
                    row: row,
                    id: row[_ctx.keyProp]
                  })
                ])
              ]))
            }), 128))
          ]))
    ])
  ]))
}
}

})