import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "whitespace-pre-wrap" }


import moment from "moment";

export default /*@__PURE__*/_defineComponent({
  __name: 'ChatMessage',
  props: {
  content: {
    type: String,
    required: true,
  },
  isUser: {
    type: Boolean,
    default: false,
  },
  timestamp: {
    type: Date,
    default: null,
  },
},
  setup(__props) {

moment.locale("tr");

const props = __props;

const formatTime = (date: Date) => {
  return moment(date).format("HH:mm");
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([
      'border-2 rounded-2xl font-medium text-sm p-2 pl-4 text-left',
      __props.isUser
        ? 'border-secondary rounded-br-none bg-secondary bg-opacity-35 text-primary md:w-2/3 md:ml-auto ml-16'
        : 'border-[#2625251F] bg-[#00000005] rounded-bl-none md:mr-auto mr-16 md:w-2/3 self-start',
    ])
  }, [
    _createElementVNode("div", _hoisted_1, _toDisplayString(__props.content), 1),
    (__props.timestamp)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass([
        'text-xs mt-1 text-right',
        __props.isUser ? 'text-gray-600' : 'text-gray-500',
      ])
        }, _toDisplayString(formatTime(__props.timestamp)), 3))
      : _createCommentVNode("", true)
  ], 2))
}
}

})