import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, unref as _unref, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "max-w-2xl sm:max-w-sm md:max-w-sm lg:max-w-sm xl:max-w-sm bg-white shadow-md rounded-lg text-gray-900" }
const _hoisted_2 = { class: "text-center mt-2" }
const _hoisted_3 = { class: "font-semibold text-primary text-lg" }
const _hoisted_4 = {
  key: 0,
  class: "text-gray-500 text-sm"
}
const _hoisted_5 = { class: "text-gray-500 text-sm" }
const _hoisted_6 = { class: "py-4 mt-2 text-gray-700 flex items-center justify-around" }
const _hoisted_7 = { class: "flex flex-col items-center justify-around" }
const _hoisted_8 = { class: "flex flex-col items-center justify-between" }
const _hoisted_9 = { class: "flex flex-col items-center justify-around" }
const _hoisted_10 = { class: "border-t pt-4 mx-8" }
const _hoisted_11 = { class: "flex flex-col items-center justify-around" }
const _hoisted_12 = { class: "font-semibold" }
const _hoisted_13 = { class: "text-lg" }
const _hoisted_14 = {
  key: 0,
  class: "mx-8 border-t py-3 text-center"
}
const _hoisted_15 = {
  key: 1,
  class: "p-4 border-t mx-8 mt-2"
}

import { Profile, UserModel } from "@/utils/api";
import { calculateBMI } from "@/utils/calculate-bmi";
import moment from "moment";
import { computed, ref } from "vue";

export default /*@__PURE__*/_defineComponent({
  __name: 'ProfileCard',
  props: {
    profile: {},
    user: {},
    isAdmin: { type: Boolean }
  },
  emits: ["update:profile"],
  setup(__props: any, { emit: __emit }) {

moment.locale("tr");

const isDrawerOpen = ref(false);

const props = __props;

const emit = __emit;

function handleProfileUpdate(p: Profile) {
  isDrawerOpen.value = false;
  emit("update:profile", p);
}

const fullName = computed(
  () => `${props.profile?.firstName} ${props.profile?.lastName}`
);

const birthday = computed(() =>
  moment(props.profile?.dateOfBirth).format("D MMM YYYY")
);
const gender = computed(() => {
  switch (props.profile?.gender) {
    case "male":
      return { text: "Erkek", icon: "mars" };
    case "female":
      return { text: "Kadın", icon: "venus" };
    default:
      return { text: "Diğer", icon: "venus-mars" };
  }
});

function formatDate(value: Date) {
  return moment(value).format("D MMMM YYYY HH:mm");
}

return (_ctx: any,_cache: any) => {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_BaseButton = _resolveComponent("BaseButton")!
  const _component_ProfileForm = _resolveComponent("ProfileForm")!
  const _component_BaseDrawer = _resolveComponent("BaseDrawer")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[6] || (_cache[6] = _createElementVNode("div", { class: "rounded-t-lg h-32 overflow-hidden" }, [
      _createElementVNode("img", {
        class: "object-cover object-top w-full",
        src: "https://images.unsplash.com/photo-1549880338-65ddcdfd017b?ixlib=rb-1.2.1&q=80&fm=jpg&crop=entropy&cs=tinysrgb&w=400&fit=max&ixid=eyJhcHBfaWQiOjE0NTg5fQ",
        alt: "Mountain"
      })
    ], -1)),
    _cache[7] || (_cache[7] = _createElementVNode("div", { class: "mx-auto w-32 h-32 relative -mt-16 border-4 border-white rounded-full overflow-hidden" }, [
      _createElementVNode("img", {
        class: "object-cover object-center h-32",
        src: "https://cepdoktorum-static-files.s3.eu-north-1.amazonaws.com/pp-placeholder.jpg",
        alt: "profile-picture"
      })
    ], -1)),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h2", _hoisted_3, _toDisplayString(fullName.value), 1),
      (props.user)
        ? (_openBlock(), _createElementBlock("p", _hoisted_4, [
            _createVNode(_component_font_awesome_icon, { icon: "phone" }),
            _createTextVNode(" " + _toDisplayString(props.user.phoneNumber), 1)
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("p", _hoisted_5, [
        _createVNode(_component_font_awesome_icon, {
          icon: gender.value.icon
        }, null, 8, ["icon"]),
        _createTextVNode(" " + _toDisplayString(gender.value.text), 1)
      ])
    ]),
    _createElementVNode("ul", _hoisted_6, [
      _createElementVNode("li", _hoisted_7, [
        _createVNode(_component_font_awesome_icon, {
          icon: "ruler-vertical",
          class: "text-secondaryShade"
        }),
        _createElementVNode("div", null, _toDisplayString(props.profile?.height) + " cm", 1)
      ]),
      _createElementVNode("li", _hoisted_8, [
        _createVNode(_component_font_awesome_icon, {
          icon: "weight-scale",
          class: "text-secondaryShade"
        }),
        _createElementVNode("div", null, _toDisplayString(props.profile?.weight) + " kg", 1)
      ]),
      _createElementVNode("li", _hoisted_9, [
        _createVNode(_component_font_awesome_icon, {
          icon: "birthday-cake",
          class: "text-secondaryShade"
        }),
        _createElementVNode("div", null, _toDisplayString(birthday.value), 1)
      ])
    ]),
    _createElementVNode("div", _hoisted_10, [
      _createElementVNode("li", _hoisted_11, [
        _createElementVNode("span", _hoisted_12, [
          _createVNode(_component_font_awesome_icon, {
            icon: "square-root-variable",
            class: "text-secondaryShade"
          }),
          _cache[2] || (_cache[2] = _createTextVNode(" Vücut/Kitle Endeksim "))
        ]),
        _createElementVNode("div", _hoisted_13, _toDisplayString(_unref(calculateBMI)(props.profile?.weight, props.profile?.height)), 1)
      ])
    ]),
    (props.user)
      ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
          _cache[3] || (_cache[3] = _createElementVNode("span", { class: "font-semibold mr-1" }, "Kayıt Olma Tarihi:", -1)),
          _createTextVNode(_toDisplayString(formatDate(props.user.createdAt)), 1)
        ]))
      : _createCommentVNode("", true),
    (!props.user && !_ctx.isAdmin)
      ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
          _createVNode(_component_BaseButton, {
            onClick: _cache[0] || (_cache[0] = ($event: any) => (isDrawerOpen.value = true))
          }, {
            default: _withCtx(() => _cache[4] || (_cache[4] = [
              _createTextVNode("DÜZENLE")
            ])),
            _: 1
          })
        ]))
      : _createCommentVNode("", true),
    _createVNode(_component_BaseDrawer, {
      isOpen: isDrawerOpen.value,
      onClose: _cache[1] || (_cache[1] = ($event: any) => (isDrawerOpen.value = false))
    }, {
      title: _withCtx(() => _cache[5] || (_cache[5] = [
        _createElementVNode("h2", { class: "font-semibold text-primary text-xl" }, "Profili Düzenle", -1)
      ])),
      default: _withCtx(() => [
        (props.profile)
          ? (_openBlock(), _createBlock(_component_ProfileForm, {
              key: 0,
              type: "update",
              profile: props.profile,
              "onUpdate:profile": handleProfileUpdate
            }, null, 8, ["profile"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["isOpen"])
  ]))
}
}

})