<template>
  <BaseCard class="my-4">
    <BaseLabel size="xl">Fatura Bilgileri</BaseLabel>
    <form class="flex flex-col gap-4 mt-2">
      <div class="w-full">
        <!-- <div class="w-1/2">
          <BaseInput
            v-model="formData.identityNumber"
            label="Kimlik Numarası *"
            placeholder=""
          />
        </div> -->
        <div class="w-1/2">
          <div class="w-full">
            <BaseInput
              v-model="formData.email"
              label="Email *"
              placeholder=""
            />
          </div>
        </div>
      </div>
      <span class="text-xs">* Lütfen bütün bilgileri eksiksiz doldurunuz.</span>
    </form>
  </BaseCard>
</template>

<script setup lang="ts">
import { ref, watch, defineEmits, defineProps } from "vue";

defineProps<{ modelValue: { identityNumber: string; email: string } }>();

const emit = defineEmits(["update:modelValue"]);

const formData = ref<{ identityNumber: string; email: string }>({
  identityNumber: "",
  email: "",
});

watch(
  () => formData.value,
  (newValue) => {
    emit("update:modelValue", newValue);
  },
  { deep: true }
);
</script>
