import type { AxiosInstance } from "axios";
import { Conversation, Message } from "./types";

export * from "./types";

export class ChatService {
  private readonly instance: AxiosInstance;

  constructor(instance: AxiosInstance) {
    this.instance = instance;
  }

  async getConversations(): Promise<Conversation[]> {
    try {
      const response = await this.instance.get("/chat/list", {
        withCredentials: true,
      });
      return response.data.data;
    } catch (error) {
      throw error;
    }
  }

  async getConversation(id: string): Promise<Conversation> {
    try {
      const response = await this.instance.get(`/chat/${id}`, {
        withCredentials: true,
      });
      return response.data.data;
    } catch (error) {
      throw error;
    }
  }

  async createConversation(): Promise<Conversation> {
    try {
      const response = await this.instance.post(
        "/chat",
        {},
        {
          withCredentials: true,
        }
      );
      return response.data.data;
    } catch (error) {
      throw error;
    }
  }

  async sendMessage(
    id: string,
    message: string,
    document?: File
  ): Promise<{ message: Message }> {
    try {
      const formData = new FormData();
      formData.append("message", message);

      if (document) {
        formData.append("document", document);
      }

      const response = await this.instance.post(
        `/chat/${id}/message`,
        formData,
        {
          withCredentials: true,
          headers: {
            "Content-Type": document
              ? "multipart/form-data"
              : "application/json",
          },
        }
      );
      return response.data.data;
    } catch (error) {
      throw error;
    }
  }
}
