import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, vModelText as _vModelText, withModifiers as _withModifiers, withKeys as _withKeys, withDirectives as _withDirectives, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "flex h-full" }
const _hoisted_2 = { class: "flex w-full h-full flex-col lg:flex-row gap-2" }
const _hoisted_3 = { class: "w-full lg:w-1/2 overflow-y-auto flex relative min-h-96" }
const _hoisted_4 = { class: "absolute w-full text-center" }
const _hoisted_5 = { class: "bg-gray-100 rounded-t-lg shadow-md relative" }
const _hoisted_6 = {
  key: 0,
  class: "-bottom-5 right-0 absolute text-xs text-gray-400"
}
const _hoisted_7 = ["src"]
const _hoisted_8 = ["onKeydown"]
const _hoisted_9 = { class: "w-full lg:w-1/2 h-full" }

import { ref, watch } from "vue";

import { ApiService } from "@/utils/api";

const logo = "/logo.svg";

const logoLight = "/logo-light.svg";


export default /*@__PURE__*/_defineComponent({
  __name: 'MarkdownEditor',
  props: {
    modelValue: {}
  },
  emits: ["update:modelValue"],
  setup(__props: any, { emit: __emit }) {

const props = __props;

const emit = __emit;

const markdownInput = ref<string>(props.modelValue);

const textarea = ref<HTMLTextAreaElement | null>(null);

const history = ref<string[]>([]);

const future = ref<string[]>([]);

const loading = ref(false);

const imageSrc = ref(logo);

const onHover = () => {
  imageSrc.value = logoLight;
};

const onLeave = () => {
  imageSrc.value = logo;
};

const handleEnterKey = (event: KeyboardEvent) => {
  saveState();
  const textareaEl = event.target as HTMLTextAreaElement;
  const start = textareaEl.selectionStart;
  const end = textareaEl.selectionEnd;

  markdownInput.value =
    markdownInput.value.substring(0, start) +
    "\n" +
    markdownInput.value.substring(end);

  textareaEl.selectionStart = textareaEl.selectionEnd = start + 2;
};

const saveState = () => {
  history.value.push(markdownInput.value);
  future.value = [];
};

const undo = () => {
  if (history.value.length > 0) {
    future.value.push(markdownInput.value);
    markdownInput.value = history.value.pop() || "";
  }
};

const redo = () => {
  if (future.value.length > 0) {
    history.value.push(markdownInput.value);
    markdownInput.value = future.value.pop() || "";
  }
};

const formatText = (type: "bold" | "italic") => {
  saveState();
  const textareaEl = textarea.value;
  if (!textareaEl) return;

  const start = textareaEl.selectionStart;
  const end = textareaEl.selectionEnd;

  const selectedText = markdownInput.value.substring(start, end);
  let formattedText = selectedText;

  if (type === "bold") {
    formattedText = `**${selectedText}**`;
  } else if (type === "italic") {
    formattedText = `*${selectedText}*`;
  }

  markdownInput.value =
    markdownInput.value.substring(0, start) +
    formattedText +
    markdownInput.value.substring(end);

  textareaEl.selectionStart = start + formattedText.length;
  textareaEl.selectionEnd = start + formattedText.length;
  textareaEl.focus();
};

const addHeader = (prefix: string) => {
  saveState();
  const textareaEl = textarea.value;
  if (!textareaEl) return;

  const start = textareaEl.selectionStart;
  const lineStart = markdownInput.value.lastIndexOf("\n", start - 1) + 1;

  markdownInput.value =
    markdownInput.value.substring(0, lineStart) +
    `${prefix} ` +
    markdownInput.value.substring(lineStart);

  textareaEl.selectionStart = textareaEl.selectionEnd =
    start + prefix.length + 1;
  textareaEl.focus();
};

const addList = (type: "ordered" | "unordered") => {
  saveState();
  const textareaEl = textarea.value;
  if (!textareaEl) return;

  const start = textareaEl.selectionStart;
  const end = textareaEl.selectionEnd;
  const selectedText = markdownInput.value.substring(start, end);

  const prefix = type === "ordered" ? (i: number) => `${i + 1}. ` : () => "- ";
  const lines = selectedText.split("\n");

  const formattedLines = lines.map((line, index) => prefix(index) + line);
  const formattedText = formattedLines.join("\n");

  markdownInput.value =
    markdownInput.value.substring(0, start) +
    formattedText +
    markdownInput.value.substring(end);

  textareaEl.selectionStart = start;
  textareaEl.selectionEnd = start + formattedText.length;
  textareaEl.focus();
};

const addSeparator = () => {
  saveState();
  const textareaEl = textarea.value;
  if (!textareaEl) return;

  const start = textareaEl.selectionStart;
  markdownInput.value =
    markdownInput.value.substring(0, start) +
    "\n***\n" +
    markdownInput.value.substring(start);

  textareaEl.selectionStart = textareaEl.selectionEnd = start + 5;
  textareaEl.focus();
};

const addLink = () => {
  saveState();
  const textareaEl = textarea.value;
  if (!textareaEl) return;

  const start = textareaEl.selectionStart;
  const end = textareaEl.selectionEnd;
  const selectedText =
    markdownInput.value.substring(start, end) || "Link eklenecek yazı";

  markdownInput.value =
    markdownInput.value.substring(0, start) +
    `[${selectedText}](url)` +
    markdownInput.value.substring(end);

  textareaEl.selectionStart = start + selectedText.length + 3;
  textareaEl.selectionEnd = start + selectedText.length + 3;
  textareaEl.focus();
};

const addLogo = (alt = "", url = "") => {
  saveState();
  const textareaEl = textarea.value;
  if (!textareaEl) return;
  const start = textareaEl.selectionStart;
  markdownInput.value =
    markdownInput.value.substring(0, start) +
    `![${alt || "Görsel Açıklaması"}](${url || "url"})` +
    markdownInput.value.substring(start);
  textareaEl.focus();
};

const addImage = async () => {
  saveState();
  const textareaEl = textarea.value;
  if (!textareaEl) return;

  const input = document.createElement("input");
  input.type = "file";
  input.accept =
    "image/png, image/jpeg, image/jpg, image/heic, image/HEIC, image/svg";
  input.multiple = true;

  input.addEventListener("change", async () => {
    if (input.files) {
      const files = Array.from(input.files);

      try {
        loading.value = true;
        const uploadedUrls = await ApiService.storage.upload(files, "images");

        const imageMarkdown = uploadedUrls
          .map((url) => `![image](${url})`)
          .join("\n");

        const start = textareaEl.selectionStart;
        markdownInput.value =
          markdownInput.value.substring(0, start) +
          imageMarkdown +
          markdownInput.value.substring(start);

        textareaEl.focus();
      } catch (error) {
        console.error("Image upload failed", error);
      } finally {
        loading.value = false;
      }
    }
  });

  input.click();
};

const addBlockquote = () => {
  saveState();
  const textareaEl = textarea.value;
  if (!textareaEl) return;
  const start = textareaEl.selectionStart;
  markdownInput.value =
    markdownInput.value.substring(0, start) +
    "> " +
    markdownInput.value.substring(start);
  textareaEl.focus();
};

const addStrikethrough = () => {
  saveState();
  const textareaEl = textarea.value;
  if (!textareaEl) return;

  const start = textareaEl.selectionStart;
  const end = textareaEl.selectionEnd;
  const selectedText = markdownInput.value.substring(start, end);

  markdownInput.value =
    markdownInput.value.substring(0, start) +
    `~~${selectedText}~~` +
    markdownInput.value.substring(end);

  textareaEl.focus();
};

watch(
  () => props.modelValue,
  (newValue) => {
    markdownInput.value = newValue;
  }
);

watch(markdownInput, (newValue) => {
  emit("update:modelValue", newValue);
});

return (_ctx: any,_cache: any) => {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_MarkdownRenderer = _resolveComponent("MarkdownRenderer")!
  const _component_ScrollArea = _resolveComponent("ScrollArea")!
  const _component_BaseCard = _resolveComponent("BaseCard")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            (loading.value)
              ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                  _createVNode(_component_font_awesome_icon, {
                    icon: "stethoscope",
                    class: "fast-spin text-secondary"
                  }),
                  _cache[20] || (_cache[20] = _createTextVNode(" Görseller yükleniyor, lütfen bekleyiniz... "))
                ]))
              : _createCommentVNode("", true),
            _createElementVNode("button", {
              onClick: _cache[0] || (_cache[0] = ($event: any) => (formatText('bold'))),
              class: "toolbar-button",
              title: "Kalın"
            }, [
              _createVNode(_component_font_awesome_icon, {
                icon: "bold",
                class: "font-bold"
              })
            ]),
            _createElementVNode("button", {
              onClick: _cache[1] || (_cache[1] = ($event: any) => (formatText('italic'))),
              class: "toolbar-button",
              title: "İtalik"
            }, [
              _createVNode(_component_font_awesome_icon, { icon: "italic" })
            ]),
            _createElementVNode("button", {
              onClick: _cache[2] || (_cache[2] = ($event: any) => (addStrikethrough())),
              class: "toolbar-button",
              title: "Üstü Çizili"
            }, [
              _createVNode(_component_font_awesome_icon, { icon: "strikethrough" })
            ]),
            _createElementVNode("button", {
              onClick: _cache[3] || (_cache[3] = ($event: any) => (addHeader('#'))),
              class: "toolbar-button",
              title: "Ana Başlık"
            }, [
              _createVNode(_component_font_awesome_icon, {
                icon: "heading",
                class: "text-lg"
              })
            ]),
            _createElementVNode("button", {
              onClick: _cache[4] || (_cache[4] = ($event: any) => (addHeader('##'))),
              class: "toolbar-button",
              title: "Normal Başlık"
            }, [
              _createVNode(_component_font_awesome_icon, {
                icon: "heading",
                class: "text-md"
              })
            ]),
            _createElementVNode("button", {
              onClick: _cache[5] || (_cache[5] = ($event: any) => (addHeader('###'))),
              class: "toolbar-button",
              title: "Alt Başlık"
            }, [
              _createVNode(_component_font_awesome_icon, {
                icon: "heading",
                class: "text-xs"
              })
            ]),
            _createElementVNode("button", {
              onClick: _cache[6] || (_cache[6] = ($event: any) => (addList('ordered'))),
              class: "toolbar-button",
              title: "Sıralı Liste"
            }, [
              _createVNode(_component_font_awesome_icon, { icon: "list-ol" })
            ]),
            _createElementVNode("button", {
              onClick: _cache[7] || (_cache[7] = ($event: any) => (addList('unordered'))),
              class: "toolbar-button",
              title: "Sırasız Liste"
            }, [
              _createVNode(_component_font_awesome_icon, { icon: "list-ul" })
            ]),
            _createElementVNode("button", {
              onClick: _cache[8] || (_cache[8] = ($event: any) => (addBlockquote())),
              class: "toolbar-button",
              title: "Alıntı"
            }, [
              _createVNode(_component_font_awesome_icon, { icon: "quote-left" })
            ]),
            _createElementVNode("button", {
              onClick: _cache[9] || (_cache[9] = ($event: any) => (addSeparator())),
              class: "toolbar-button",
              title: "Ayırıcı Çizgi"
            }, [
              _createVNode(_component_font_awesome_icon, { icon: "minus" })
            ]),
            _createElementVNode("button", {
              onClick: _cache[10] || (_cache[10] = ($event: any) => (addLink())),
              class: "toolbar-button",
              title: "Link"
            }, [
              _createVNode(_component_font_awesome_icon, { icon: "link" })
            ]),
            _createElementVNode("button", {
              onClick: _cache[11] || (_cache[11] = ($event: any) => (addImage())),
              class: "toolbar-button",
              title: "Görsel"
            }, [
              _createVNode(_component_font_awesome_icon, { icon: "image" })
            ]),
            _createElementVNode("button", {
              onClick: _cache[12] || (_cache[12] = ($event: any) => (addLogo('Cep Doktorum Logo', '/logo-report.svg'))),
              class: "toolbar-button",
              title: "Görsel"
            }, [
              _createElementVNode("img", {
                src: imageSrc.value,
                onMouseover: onHover,
                onMouseleave: onLeave,
                class: "h-5 -mb-1.5"
              }, null, 40, _hoisted_7)
            ]),
            _createElementVNode("button", {
              onClick: _cache[13] || (_cache[13] = ($event: any) => (undo())),
              class: "toolbar-button",
              title: "Geri Al"
            }, [
              _createVNode(_component_font_awesome_icon, { icon: "rotate-left" })
            ]),
            _createElementVNode("button", {
              onClick: _cache[14] || (_cache[14] = ($event: any) => (redo())),
              class: "toolbar-button",
              title: "İleri Al"
            }, [
              _createVNode(_component_font_awesome_icon, { icon: "rotate-right" })
            ])
          ])
        ]),
        _withDirectives(_createElementVNode("textarea", {
          "onUpdate:modelValue": _cache[15] || (_cache[15] = ($event: any) => ((markdownInput).value = $event)),
          class: "w-full h-full px-4 pt-20 lg:pt-16 text-xl rounded-lg shadow-lg focus:outline-none lg:resize-none",
          onKeydown: [
            _withKeys(_withModifiers(handleEnterKey, ["prevent"]), ["enter"]),
            _cache[16] || (_cache[16] = _withKeys(_withModifiers(() => formatText('bold'), ["meta","prevent"]), ["b"])),
            _cache[17] || (_cache[17] = _withKeys(_withModifiers(() => formatText('bold'), ["ctrl","prevent"]), ["b"])),
            _cache[18] || (_cache[18] = _withKeys(_withModifiers(() => formatText('italic'), ["meta","prevent"]), ["i"])),
            _cache[19] || (_cache[19] = _withKeys(_withModifiers(() => formatText('italic'), ["ctrl","prevent"]), ["i"])),
            _withKeys(_withModifiers(undo, ["meta","prevent"]), ["z"]),
            _withKeys(_withModifiers(undo, ["ctrl","prevent"]), ["z"])
          ],
          ref_key: "textarea",
          ref: textarea,
          placeholder: "Lütfen buraya yazınız..."
        }, "        ", 40, _hoisted_8), [
          [_vModelText, markdownInput.value]
        ])
      ]),
      _createElementVNode("div", _hoisted_9, [
        _createVNode(_component_BaseCard, { class: "h-full text-left max-h-full" }, {
          default: _withCtx(() => [
            _cache[21] || (_cache[21] = _createElementVNode("h2", { class: "font-semibold text-xl" }, "Önizleme", -1)),
            _createVNode(_component_ScrollArea, {
              maxHeight: "950px",
              class: "pb-20 whitespace-pre-wrap"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_MarkdownRenderer, { content: markdownInput.value }, null, 8, ["content"])
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ])
    ])
  ]))
}
}

})