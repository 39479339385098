import type { AxiosInstance } from "axios";

import {
  AuthService,
  ProfileService,
  ConfigService,
  ConsultationService,
  PaymentService,
  AdminService,
  StorageService,
  BlogService,
  ChatService,
} from "./services";

import { axiosInstance } from "./instance";
import { addAxiosInterceptors } from "./interceptors";

export * from "./services";

export class Api {
  abortController: AbortController;

  readonly instance: AxiosInstance;

  readonly auth: AuthService;

  readonly profile: ProfileService;

  readonly config: ConfigService;

  readonly consultation: ConsultationService;

  readonly payment: PaymentService;

  readonly admin: AdminService;

  readonly storage: StorageService;

  readonly blog: BlogService;

  readonly chat: ChatService;

  constructor(instance: AxiosInstance) {
    this.instance = instance;

    this.auth = new AuthService(instance);

    this.profile = new ProfileService(instance);

    this.config = new ConfigService(instance);

    this.consultation = new ConsultationService(instance);

    this.payment = new PaymentService(instance);

    this.admin = new AdminService(instance);

    this.storage = new StorageService(instance);

    this.blog = new BlogService(instance);

    this.chat = new ChatService(instance);

    this.abortController = new AbortController();

    this.instance.interceptors.request.use((config) => {
      config.signal = this.abortController.signal;
      return config;
    });
  }
}

export const ApiService = new Api(addAxiosInterceptors(axiosInstance));
