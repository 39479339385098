<template>
  <div
    :class="[
      'border-2 rounded-2xl font-medium text-sm p-2 pl-4 text-left',
      isUser
        ? 'border-secondary rounded-br-none bg-secondary bg-opacity-35 text-primary md:w-2/3 md:ml-auto ml-16'
        : 'border-[#2625251F] bg-[#00000005] rounded-bl-none md:mr-auto mr-16 md:w-2/3 self-start',
    ]"
  >
    <div class="whitespace-pre-wrap">{{ content }}</div>
    <div
      :class="[
        'text-xs mt-1 text-right',
        isUser ? 'text-gray-600' : 'text-gray-500',
      ]"
      v-if="timestamp"
    >
      {{ formatTime(timestamp) }}
    </div>
  </div>
</template>

<script setup lang="ts">
import { defineProps } from "vue";
import moment from "moment";
moment.locale("tr");

const props = defineProps({
  content: {
    type: String,
    required: true,
  },
  isUser: {
    type: Boolean,
    default: false,
  },
  timestamp: {
    type: Date,
    default: null,
  },
});

const formatTime = (date: Date) => {
  return moment(date).format("HH:mm");
};
</script>
