import type { AxiosInstance } from "axios";
import { Consultation, ConsultationModel, ConsultationDocument } from "./types";
import { toRaw } from "vue";

export * from "./types";

export class ConsultationService {
  private readonly instance: AxiosInstance;

  constructor(instance: AxiosInstance) {
    this.instance = instance;
  }

  async create(params: Consultation): Promise<ConsultationModel> {
    try {
      const response = await this.instance.post("/consultation", params, {
        withCredentials: true,
      });
      return response.data.data;
    } catch (error) {
      throw error;
    }
  }

  async list(service: string | undefined): Promise<ConsultationModel[]> {
    try {
      const response = await this.instance.get(
        `/consultation/list${service ? `?service=${service}` : ""}`,
        {
          withCredentials: true,
        }
      );
      return response.data.data;
    } catch (error) {
      throw error;
    }
  }

  async get(id: string): Promise<ConsultationModel> {
    try {
      const response = await this.instance.get(`/consultation/${id}`, {
        withCredentials: true,
      });
      return response.data.data;
    } catch (error) {
      throw error;
    }
  }

  async update(
    id: string,
    params: {
      paymentConversationId?: string;
      paytr_token?: string;
    }
  ): Promise<ConsultationModel> {
    try {
      const response = await this.instance.patch(
        `/consultation/${id}`,
        params,
        {
          withCredentials: true,
        }
      );
      return response.data.data;
    } catch (error) {
      throw error;
    }
  }

  async listDocuments(): Promise<ConsultationDocument[]> {
    try {
      const response = await this.instance.get(`/consultation/documents/all`, {
        withCredentials: true,
      });
      return response.data.data;
    } catch (error) {
      throw error;
    }
  }

  async upload(files: File[]): Promise<string[]> {
    try {
      const formData = new FormData();

      toRaw(files).forEach((file) => {
        formData.append("documents", file);
      });

      const response = await this.instance.post(
        `/consultation/documents/upload`,
        formData,
        {
          withCredentials: true,
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      return response.data.data;
    } catch (error) {
      throw error;
    }
  }
}
