import { defineComponent as _defineComponent } from 'vue'
import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex flex-col gap-4" }
const _hoisted_2 = { class: "w-full" }
const _hoisted_3 = { class: "w-full" }
const _hoisted_4 = { class: "w-full flex items-center gap-2" }
const _hoisted_5 = { class: "flex" }

import { ref, watch } from "vue";
import { PaymentCard } from "@/utils/api";


export default /*@__PURE__*/_defineComponent({
  __name: 'PaymentForm',
  props: {
    modelValue: {}
  },
  emits: ["update:modelValue"],
  setup(__props: any, { emit: __emit }) {

const emit = __emit;
const props = __props;

const monthOptions = [
  { key: "01", label: "Ocak" },
  { key: "02", label: "Şubat" },
  { key: "03", label: "Mart" },
  { key: "04", label: "Nisan" },
  { key: "05", label: "Mayıs" },
  { key: "06", label: "Haziran" },
  { key: "07", label: "Temmuz" },
  { key: "08", label: "Ağustos" },
  { key: "09", label: "Eylül" },
  { key: "10", label: "Ekim" },
  { key: "11", label: "Kasım" },
  { key: "12", label: "Aralık" },
];

const yearOptions = [
  { key: "2024", label: "2024" },
  { key: "2025", label: "2025" },
  { key: "2026", label: "2026" },
  { key: "2027", label: "2027" },
  { key: "2028", label: "2028" },
  { key: "2029", label: "2029" },
  { key: "2030", label: "2030" },
  { key: "2031", label: "2031" },
  { key: "2032", label: "2032" },
  { key: "2033", label: "2033" },
  { key: "2034", label: "2034" },
];

const card = ref<PaymentCard>({
  cardHolderName: "PAYTR TEST",
  cardNumber: "4355084355084358",
  expireMonth: "12",
  expireYear: "2030",
  cvc: "000",
});

watch(
  card,
  (newValue) => {
    emit("update:modelValue", newValue);
  },
  { deep: true }
);

return (_ctx: any,_cache: any) => {
  const _component_BaseLabel = _resolveComponent("BaseLabel")!
  const _component_BaseInput = _resolveComponent("BaseInput")!
  const _component_BaseSelect = _resolveComponent("BaseSelect")!
  const _component_BaseCard = _resolveComponent("BaseCard")!

  return (_openBlock(), _createBlock(_component_BaseCard, null, {
    default: _withCtx(() => [
      _createVNode(_component_BaseLabel, { size: "xl" }, {
        default: _withCtx(() => _cache[5] || (_cache[5] = [
          _createTextVNode("Kart Bilgileri")
        ])),
        _: 1
      }),
      _createElementVNode("form", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_BaseInput, {
            modelValue: card.value.cardHolderName,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((card.value.cardHolderName) = $event)),
            label: " Kart Üzerinde Yazan İsim",
            placeholder: "İsim Soyisim"
          }, null, 8, ["modelValue"])
        ]),
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_BaseInput, {
            modelValue: card.value.cardNumber,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((card.value.cardNumber) = $event)),
            label: "Kart Numarası",
            placeholder: "1234 1234 1234 1234"
          }, null, 8, ["modelValue"])
        ]),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_BaseSelect, {
              modelValue: card.value.expireMonth,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((card.value.expireMonth) = $event)),
              options: monthOptions,
              label: "Ay",
              class: "w-16"
            }, null, 8, ["modelValue"]),
            _createVNode(_component_BaseSelect, {
              modelValue: card.value.expireYear,
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((card.value.expireYear) = $event)),
              options: yearOptions,
              label: "Yıl",
              class: "w-16"
            }, null, 8, ["modelValue"])
          ])
        ]),
        _createVNode(_component_BaseInput, {
          modelValue: card.value.cvc,
          "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((card.value.cvc) = $event)),
          label: "CVC",
          class: "!w-24"
        }, null, 8, ["modelValue"])
      ])
    ]),
    _: 1
  }))
}
}

})