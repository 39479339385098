import axios from "axios";

axios.defaults.baseURL = process.env.VUE_APP_SERVER_BASE_URL;

export function createAxiosInstance() {
  return axios.create({
    baseURL: process.env.VUE_APP_SERVER_BASE_URL,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
      "Cache-Control": "no-cache, no-store, must-revalidate",
      Pragma: "no-cache",
      Expires: "0",
      "X-Api-Key": process.env.VUE_APP_API_KEY,
    },
  });
}

export const axiosInstance = createAxiosInstance();
