import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, vModelText as _vModelText, withModifiers as _withModifiers, withKeys as _withKeys, withDirectives as _withDirectives, createTextVNode as _createTextVNode, normalizeStyle as _normalizeStyle, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "w-full relative" }
const _hoisted_2 = {
  key: 0,
  class: "text-xs font-semibold px-1"
}
const _hoisted_3 = { class: "flex items-center space-x-2 mt-2" }
const _hoisted_4 = { class: "text-sm font-medium" }
const _hoisted_5 = { class: "relative w-full" }
const _hoisted_6 = ["disabled", "onKeydown"]
const _hoisted_7 = ["min", "max", "step", "disabled"]
const _hoisted_8 = { class: "text-sm font-medium" }

import { ref, watch, onMounted } from "vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'BaseRangeInput',
  props: {
  modelValue: {
    type: Number,
    required: true,
  },
  min: {
    type: Number,
    default: 0,
  },
  max: {
    type: Number,
    default: 100,
  },
  step: {
    type: Number,
    default: 1,
  },
  label: {
    type: String,
    default: "",
  },
  icon: {
    type: String,
    default: "",
  },
  unit: {
    type: String,
    default: "",
  },
  disabled: {
    type: Boolean,
    default: false,
  },
},
  emits: ["update:modelValue"],
  setup(__props, { emit: __emit }) {

const props = __props;

const emit = __emit;

const rangeValue = ref(props.modelValue);
const inputValue = ref(props.modelValue);
const thumbPosition = ref(0);

const updateThumbPosition = () => {
  const percentage =
    ((rangeValue.value - props.min) / (props.max - props.min)) * 100;
  thumbPosition.value = percentage;
};

const validateInput = () => {
  let validatedValue = Number(inputValue.value);
  if (validatedValue < props.min) {
    validatedValue = props.min;
  } else if (validatedValue > props.max) {
    validatedValue = props.max;
  }
  rangeValue.value = validatedValue;
  inputValue.value = validatedValue;
  updateThumbPosition();
};

watch(
  () => props.modelValue,
  (newValue) => {
    rangeValue.value = newValue;
    inputValue.value = newValue;
    updateThumbPosition();
  }
);

watch(rangeValue, (newValue) => {
  emit("update:modelValue", newValue);
});

onMounted(() => {
  updateThumbPosition();
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (__props.label)
      ? (_openBlock(), _createElementBlock("label", _hoisted_2, _toDisplayString(__props.label), 1))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("span", _hoisted_4, _toDisplayString(__props.min), 1),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("div", {
          class: "absolute -top-10 left-1/2 transform -translate-x-1/2 bg-primary text-light text-sm font-bold px-2 py-1 rounded flex items-center",
          style: _normalizeStyle({ left: `${thumbPosition.value}%` })
        }, [
          _withDirectives(_createElementVNode("input", {
            type: "number",
            class: "w-12 text-center bg-transparent border-none outline-none text-light font-bold",
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((inputValue).value = $event)),
            disabled: __props.disabled,
            onBlur: validateInput,
            onKeydown: _withKeys(_withModifiers(validateInput, ["prevent"]), ["enter"])
          }, null, 40, _hoisted_6), [
            [_vModelText, inputValue.value]
          ]),
          _createTextVNode(" " + _toDisplayString(__props.unit), 1)
        ], 4),
        _withDirectives(_createElementVNode("input", {
          type: "range",
          id: "range-slider",
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((rangeValue).value = $event)),
          min: __props.min,
          max: __props.max,
          step: __props.step,
          disabled: __props.disabled,
          onInput: updateThumbPosition,
          class: _normalizeClass([
            'w-full h-3 rounded-lg appearance-none cursor-pointer',
            __props.disabled ? 'cursor-not-allowed opacity-50' : '',
          ])
        }, null, 42, _hoisted_7), [
          [
            _vModelText,
            rangeValue.value,
            void 0,
            { number: true }
          ]
        ]),
        _createElementVNode("div", {
          class: "absolute top-1 left-0 h-3 rounded-lg bg-primary z-0",
          style: _normalizeStyle({ width: `${thumbPosition.value}%` })
        }, null, 4)
      ]),
      _createElementVNode("span", _hoisted_8, _toDisplayString(__props.max), 1)
    ])
  ]))
}
}

})