import type { infer as Infer } from "zod";
import { date, number, object, string, z } from "zod";

export const ProfileSchema = object({
  __v: number().optional(),
  _id: string().optional(),
  user: string().optional(),
  firstName: string().min(1),
  lastName: string().min(1),
  gender: z.union([z.enum(["male", "female", "other"]), z.null()]),
  height: number().min(50).max(250),
  weight: number().min(20).max(300),
  dateOfBirth: z.union([date(), z.null()]).optional(),
  createdAt: z.union([date(), z.null()]).optional(),
  updatedAt: z.union([date(), z.null()]).optional(),
});

export interface Medicine {
  medicine: string;
  frequency: string;
}

export interface Smoking {
  isActive: boolean;
  dailyConsumption?: string;
  quitDate?: Date | null;
}

export interface Alcohol {
  isActive: boolean;
  weeklyConsumption?: string;
  detail?: string;
}
export interface MedicalHistory {
  current?: string;
  past?: string;
  medicines?: Medicine[];
  allergies?: string[];
  cronicDiseases?: string[];
  familyDiseases?: string[];
  diet?: string;
  smoking?: Smoking;
  alcohol?: Alcohol;
  activityFrequency?: string;
}

export type MedicalHistoryFields = (keyof MedicalHistory)[];

export interface MedicalHistoryModel extends MedicalHistory {
  profile: string;
  __v: number;
  _id: string;
  createdAt: Date;
  updatedAt: Date;
}

export interface Address {
  name: string;
  detail: string;
  city: string;
  district: string;
  zipCode?: string;
  country?: string;
}

export interface AddressModel extends Address {
  profile: string;
  __v: number;
  _id: string;
  createdAt: Date;
  updatedAt: Date;
}

export type Profile = Infer<typeof ProfileSchema>;
export type ProfilePayload = Omit<
  Profile,
  "_id" | "createdAt" | "updatedAt" | "user" | "__v"
>;
