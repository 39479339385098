import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "bg-gradient-to-r from-primary to-secondary text-white p-3 text-center flex justify-between items-center font-bold text-[18px]" }
const _hoisted_2 = { class: "flex items-center gap-2" }




export default /*@__PURE__*/_defineComponent({
  __name: 'ChatHeader',
  props: {
  title: {
    type: String,
    default: "Sağlık Asistanı",
  },
},
  setup(__props) {

const props = __props;

return (_ctx: any,_cache: any) => {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_font_awesome_icon, { icon: "comment-dots" }),
      _createElementVNode("span", null, _toDisplayString(__props.title), 1)
    ]),
    _renderSlot(_ctx.$slots, "default")
  ]))
}
}

})