<template>
  <div class="w-full relative">
    <label v-if="label" class="text-xs font-semibold px-1">
      <font-awesome-icon
        v-if="icon"
        :icon="icon"
        class="absolute top-[38px] left-3 text-gray-400"
      />
      {{ label }}
    </label>
    <div class="flex">
      <textarea
        v-model="inputValue"
        :placeholder="placeholder"
        :disabled="disabled"
        :class="[
          'w-full pr-3 py-2 rounded-lg border-2 border-gray-200 outline-none focus:border-secondary',
          icon ? 'pl-7' : 'pl-3',
        ]"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { defineProps, defineEmits, ref, watch } from "vue";

const props = defineProps({
  modelValue: {
    type: String || Date,
    required: true,
  },

  placeholder: {
    type: String,
    default: "",
  },
  label: {
    type: String,
    default: "",
  },
  icon: {
    type: String,
    default: "",
  },
  disabled: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(["update:modelValue"]);

const inputValue = ref(props.modelValue);

watch(
  () => props.modelValue,
  (newValue) => {
    inputValue.value = newValue;
  }
);

watch(inputValue, (newValue) => {
  emit("update:modelValue", newValue);
});
</script>

<style scoped>
input {
  transition: border-color 0.2s;
}
</style>
