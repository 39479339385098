import axios from "axios";

axios.defaults.baseURL = process.env.VUE_APP_PAYTR_BASE_URL;

export function createAxiosInstance() {
  return axios.create({
    baseURL: process.env.VUE_APP_PAYTR_BASE_URL,
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  });
}

export const paytrInstance = createAxiosInstance();
