<template>
  <div>
    <div class="flex items-baseline gap-2">
      <input type="checkbox" v-model="isApproved" />
      <span>
        <span
          v-for="(policy, index) in selectedPolicies"
          :key="policy.key"
          class="underline hover:text-secondaryShade cursor-pointer"
          @click="loadTxt(policy)"
        >
          {{ policy.name }}
          <span v-if="index < selectedPolicies.length - 2">, </span>
          <span v-if="index === selectedPolicies.length - 2"> ve </span> </span
        >'ni okudum ve onaylıyorum.
      </span>
    </div>
    <BaseModal v-model="isModelOpened">
      <BaseButton @click="downloadAsTxt" class="w-56" type="alternative"
        ><font-awesome-icon icon="download" class="mr-2" />Sözleşmeyi
        İndir</BaseButton
      >
      <ScrollArea maxHeight="600px" class="pb-20 whitespace-pre-wrap">
        {{ renderedContent }}
      </ScrollArea></BaseModal
    >
  </div>
</template>

<script lang="ts" setup>
import {
  ref,
  defineProps,
  withDefaults,
  computed,
  defineEmits,
  watch,
} from "vue";

const isApproved = ref(false);

interface Policy {
  key: string;
  name: string;
  filename: string;
}

const props = withDefaults(
  defineProps<{
    policies: Policy["key"][];
    modelValue: boolean;
    variables?: Record<any, any>;
  }>(),
  {
    policies: () => ["privacy"],
    modelValue: false,
  }
);

const emit = defineEmits(["update:modelValue"]);

const policies = ref<Policy[]>([
  {
    key: "privacy",
    name: "Gizlilik ve Güvenlik Politikası",
    filename: "/legal/privacy.txt",
  },
  {
    key: "sale",
    name: "Mesafeli Satış Sözleşmesi",
    filename: "/legal/sale.txt",
  },
  {
    key: "consent",
    name: "Şikayet Bildirim Formu Açık Rıza Onayı",
    filename: "/legal/consent.txt",
  },
  {
    key: "customer-kvkk",
    name: "Müşteri Aydınlatma Metni",
    filename: "/legal/customer-kvkk.txt",
  },
  {
    key: "kvkk",
    name: "Kişisel Verilerin Korunması, İşlenmesi ve İmhası Politikası",
    filename: "/legal/kvkk.txt",
  },
  {
    key: "medical-approval",
    name: "Tıbbi Onam",
    filename: "/legal/medical-approval.txt",
  },
  {
    key: "price",
    name: "Ücret ve İade Politikası",
    filename: "/legal/price.txt",
  },
  {
    key: "terms-conditions",
    name: "Kullanım Koşulları Sözleşmesi",
    filename: "/legal/terms-conditions.txt",
  },
]);

const selectedPolicies = computed(() =>
  policies.value.filter((p) => props.policies.includes(p.key))
);

const isModelOpened = ref(false);
const txtContent = ref("");
const renderedContent = ref("");

const loadTxt = async (policy: Policy) => {
  isModelOpened.value = true;
  const dynamicData = props.variables ? props.variables : {};
  try {
    const response = await fetch(policy.filename);
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    txtContent.value = await response.text();
    renderedContent.value = txtContent.value.replace(
      /\{(\w+)\}/g,
      (_, key) => dynamicData[key] || `{${key}}`
    );
  } catch (error) {
    console.error("Error loading .txt file:", error);
    renderedContent.value = "Dosya yüklenirken bir hata oluştu.";
  }
};

const downloadAsTxt = () => {
  if (!renderedContent.value) {
    console.error("No content to download.");
    return;
  }

  const blob = new Blob([renderedContent.value], { type: "text/plain" });
  const link = document.createElement("a");
  link.href = URL.createObjectURL(blob);
  link.download = `${selectedPolicies.value[0]?.name}.txt`;
  link.click();
};

watch(isApproved, (val) => {
  emit("update:modelValue", val);
});
</script>
