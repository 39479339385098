import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "relative inline-flex" }
const _hoisted_2 = { class: "flex gap-4 sticky top-0 py-4 z-[9999]" }

import { ref } from "vue";
import VuePdfEmbed from "vue-pdf-embed";


export default /*@__PURE__*/_defineComponent({
  __name: 'PdfViewer',
  props: {
    source: {}
  },
  setup(__props: any) {

const props = __props;

const page = ref(1);
const pageCount = ref(0);
const isModalOpen = ref(false);
function handleDocumentLoad({ numPages }: { numPages: number }) {
  pageCount.value = numPages;
}

return (_ctx: any,_cache: any) => {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_BaseButton = _resolveComponent("BaseButton")!
  const _component_BaseDrawer = _resolveComponent("BaseDrawer")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: "absolute h-full w-full bg-primary opacity-0 hover:opacity-50 transition-all duration-100 z-[999] cursor-zoom-in",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (isModalOpen.value = true))
    }),
    _createVNode(_unref(VuePdfEmbed), {
      source: props.source,
      onLoaded: handleDocumentLoad,
      page: 1
    }, null, 8, ["source"]),
    _createVNode(_component_BaseDrawer, {
      isOpen: isModalOpen.value,
      onClose: _cache[3] || (_cache[3] = ($event: any) => (isModalOpen.value = false)),
      isFullScreen: true
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_BaseButton, {
            onClick: _cache[1] || (_cache[1] = ($event: any) => (page.value -= 1)),
            type: "primary",
            disabled: page.value === 1,
            class: "w-12 h-12"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_font_awesome_icon, { icon: "chevron-left" })
            ]),
            _: 1
          }, 8, ["disabled"]),
          _createVNode(_component_BaseButton, {
            onClick: _cache[2] || (_cache[2] = ($event: any) => (page.value += 1)),
            type: "primary",
            disabled: page.value === pageCount.value
          }, {
            default: _withCtx(() => [
              _createVNode(_component_font_awesome_icon, { icon: "chevron-right" })
            ]),
            _: 1
          }, 8, ["disabled"])
        ]),
        _createVNode(_unref(VuePdfEmbed), {
          source: props.source,
          page: page.value,
          scale: 1.5
        }, null, 8, ["source", "page"])
      ]),
      _: 1
    }, 8, ["isOpen"])
  ]))
}
}

})