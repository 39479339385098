<template>
  <form class="flex flex-col gap-4 px-3 text-left" @submit.prevent>
    <div class="" v-if="props.fields.includes('current')">
      <BaseTextarea
        v-model="formData.current"
        label="Mevcut Sağlık Durumunuz"
        icon="notes-medical"
        placeholder="Mevcut hastalıklar, şikayetler"
      />
    </div>
    <div v-if="props.fields.includes('past')">
      <BaseTextarea
        v-model="formData.past"
        label="Geçmişteki Sağlık Sorunları"
        icon="notes-medical"
        placeholder="Geçirilmiş hastalıklar, ameliyatlar"
      />
    </div>

    <div
      class="flex gap-2 flex-col md:flex-row"
      v-if="props.fields.includes('activityFrequency')"
    >
      <div class="">
        <BaseInput
          v-model="formData.activityFrequency"
          label="Hareket Sıklığınız"
          icon="person-running"
          placeholder="Haftada 3 gün koşuyorum"
        />
      </div>
      <div class="">
        <BaseInput
          v-model="formData.diet"
          label="Diyet"
          placeholder="Karbonhidrat tüketmiyorum"
          icon="utensils"
        />
      </div>
    </div>
    <div v-if="props.fields.includes('allergies')">
      <BaseArrayInput
        v-model="formData.allergies"
        label="Allerjiler"
        icon="hand-dots"
        placeholder="Güneş, laktoz"
      />
    </div>
    <div v-if="props.fields.includes('familyDiseases')">
      <BaseArrayInput
        v-model="formData.familyDiseases"
        label="Ailedeki Hastalıklar"
        placeholder="Diyabet, hipertansiyon, astım, kalp hastalığı"
        icon="people-roof"
      />
    </div>

    <div v-if="props.fields.includes('cronicDiseases')">
      <BaseArrayInput
        v-model="formData.cronicDiseases"
        placeholder="Diyabet, hipertansiyon, astım, kalp hastalığı"
        label="Kronik Hastalıklar"
        icon="hospital"
      />
    </div>

    <div v-if="props.fields.includes('medicines')">
      <div class="flex gap-2 items-center mb-1 max-w-full overflow-auto">
        <BaseLabel>Kullanılan İlaçlar</BaseLabel>
        <div
          v-for="medicine in formData.medicines"
          :key="medicine.medicine"
          class="bg-secondaryTint text-secondaryShade border-2 border-secondaryShade pl-2 py-1 rounded-full text-xs h-6 font-semibold flex items-center justify-center bg-opacity-30 min-w-24"
          :title="medicine.medicine"
        >
          <div class="max-w-48 truncate overflow-auto">
            <strong>{{ medicine.medicine }}</strong> - {{ medicine.frequency }}
          </div>
          <font-awesome-icon
            icon="x"
            size="2xs"
            class="p-1 rounded-full cursor-pointer hover:bg-opacity-30 bg-secondaryShade bg-opacity-0 ml-1"
            @click="removeMedicine(medicine)"
          />
        </div>
      </div>
      <form @submit="addMedicine" class="flex gap-3">
        <BaseInput v-model="medicine.medicine" placeholder="İlaç İsmi" />
        <BaseInput v-model="medicine.frequency" placeholder="Dozu" />
        <BaseButton type="secondary" native-type="submit" class=""
          >EKLE</BaseButton
        >
      </form>
    </div>

    <div v-if="formData.smoking && props.fields.includes('smoking')" class="">
      <div class="flex items-start flex-col md:flex-row gap-4">
        <div>
          <BaseCheckboxGroup
            :multiple="false"
            :options="[
              { label: 'Evet', value: true },
              { label: 'Hayır', value: false },
            ]"
            v-model="formData.smoking.isActive"
            label="Sigara Kullanımı"
          />
        </div>
        <div>
          <BaseInput
            v-if="formData.smoking.isActive"
            v-model="formData.smoking.dailyConsumption"
            placeholder="Günde 1 paket"
            label="Günlük Kullanım"
          />
          <BaseInput
            v-else
            v-model="formData.smoking.quitDate"
            label="Kullandıysanız Bırakma Tarihi"
            type="date"
          />
        </div>
      </div>
    </div>
    <div v-if="formData.alcohol && props.fields.includes('alcohol')" class="">
      <div class="flex items-start flex-col md:flex-row gap-4">
        <div>
          <BaseCheckboxGroup
            :multiple="false"
            :options="[
              { label: 'Evet', value: true },
              { label: 'Hayır', value: false },
            ]"
            v-model="formData.alcohol.isActive"
            label="Alkol Kullanımı"
          />
        </div>
        <div>
          <BaseInput
            v-if="formData.alcohol.isActive"
            v-model="formData.alcohol.weeklyConsumption"
            placeholder="Haftada 2 kez"
            label="Haftalık Kullanım"
          />
        </div>
        <div>
          <BaseInput v-if="formData.alcohol.isActive" label="Detaylar" />
        </div>
      </div>
    </div>
    <slot />
  </form>
</template>

<script setup lang="ts">
import {
  MedicalHistory,
  MedicalHistoryModel,
  Medicine,
  MedicalHistoryFields,
} from "@/utils/api";
import { ref, defineProps, onMounted, withDefaults, defineEmits } from "vue";
import { watch } from "vue";

const props = withDefaults(
  defineProps<{
    modelValue: MedicalHistory | MedicalHistoryModel;
    fields: MedicalHistoryFields;
    type: "create" | "update";
  }>(),
  {
    fields: () =>
      [
        "activityFrequency",
        "alcohol",
        "allergies",
        "cronicDiseases",
        "current",
        "diet",
        "familyDiseases",
        "medicines",
        "smoking",
        "past",
      ] as MedicalHistoryFields,
  }
);

const emit = defineEmits(["update:modelValue"]);

const medicine = ref<Medicine>({ medicine: "", frequency: "" });

const formData = ref<MedicalHistory>({
  current: "",
  past: "",
  medicines: [],
  allergies: [],
  cronicDiseases: [],
  familyDiseases: [],
  diet: "",
  smoking: {
    isActive: false,
    dailyConsumption: "",
    quitDate: null,
  },
  alcohol: {
    isActive: false,
    weeklyConsumption: "",
    detail: "",
  },
  activityFrequency: "",
});

function addMedicine(e: Event) {
  e.preventDefault();
  formData.value.medicines?.push(medicine.value);
  medicine.value = { medicine: "", frequency: "" };
}

function removeMedicine(medicine: Medicine) {
  formData.value.medicines = formData.value.medicines?.filter(
    (m) =>
      m.medicine !== medicine.medicine || m.frequency !== medicine.frequency
  );
}

onMounted(async () => {
  if (props.modelValue) {
    formData.value = { ...props.modelValue };
  }
});

watch(
  () => props.modelValue,
  (newVal, oldVal) => {
    if (!oldVal && newVal) {
      formData.value = { ...newVal };
    }
  },
  { deep: true }
);
watch(
  formData,
  (newValue) => {
    emit("update:modelValue", newValue);
  },
  { deep: true }
);
</script>
