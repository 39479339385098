import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementBlock as _createElementBlock, vModelDynamic as _vModelDynamic, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "w-full relative" }
const _hoisted_2 = {
  key: 0,
  class: "text-xs font-semibold"
}
const _hoisted_3 = { class: "flex" }
const _hoisted_4 = ["type", "placeholder", "disabled"]
const _hoisted_5 = {
  key: 0,
  class: "text-red-500 absolute -bottom-4 text-xs"
}

import { ref, watch, computed } from "vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'BaseInput',
  props: {
  modelValue: {
    type: String || Date,
    required: true,
  },
  type: {
    type: String,
    default: "text",
  },
  placeholder: {
    type: String,
    default: "",
  },
  label: {
    type: String,
    default: "",
  },
  icon: {
    type: String,
    default: "",
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  minLength: {
    type: Number,
    default: 0,
  },
  errorText: {
    type: String,
    default: "",
  },
},
  emits: ["update:modelValue"],
  setup(__props, { emit: __emit }) {

const isPasswordVisible = ref(false);

const touched = ref(false);

const inputType = computed(() =>
  props.type === "password"
    ? isPasswordVisible.value
      ? "text"
      : "password"
    : props.type
);

const props = __props;

const emit = __emit;

const inputValue = ref(props.modelValue);

watch(
  () => props.modelValue,
  (newValue) => {
    inputValue.value = newValue;
  }
);

watch(inputValue, (newValue) => {
  emit("update:modelValue", newValue);
});

return (_ctx: any,_cache: any) => {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (__props.label)
      ? (_openBlock(), _createElementBlock("label", _hoisted_2, [
          (__props.icon)
            ? (_openBlock(), _createBlock(_component_font_awesome_icon, {
                key: 0,
                icon: __props.icon,
                class: "absolute top-[38px] left-3 text-gray-400"
              }, null, 8, ["icon"]))
            : _createCommentVNode("", true),
          _createTextVNode(" " + _toDisplayString(__props.label), 1)
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_3, [
      _withDirectives(_createElementVNode("input", {
        type: inputType.value,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((inputValue).value = $event)),
        placeholder: __props.placeholder,
        disabled: __props.disabled,
        onFocus: _cache[1] || (_cache[1] = ($event: any) => (touched.value = true)),
        onBlur: _cache[2] || (_cache[2] = ($event: any) => (touched.value = false)),
        class: _normalizeClass([
          'w-full pr-3 py-2 rounded-lg border-2 border-gray-200 outline-none focus:border-secondary',
          __props.icon ? 'pl-7' : 'pl-3',
        ])
      }, null, 42, _hoisted_4), [
        [_vModelDynamic, inputValue.value]
      ]),
      (inputValue.value?.length < props.minLength && touched.value)
        ? (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(__props.errorText), 1))
        : _createCommentVNode("", true)
    ]),
    (__props.type === 'password')
      ? (_openBlock(), _createBlock(_component_font_awesome_icon, {
          key: 1,
          onClick: _cache[3] || (_cache[3] = ($event: any) => (isPasswordVisible.value = !isPasswordVisible.value)),
          icon: isPasswordVisible.value ? 'eye' : 'eye-slash',
          class: "absolute top-[38px] right-3 text-gray-500 cursor-pointer"
        }, null, 8, ["icon"]))
      : _createCommentVNode("", true)
  ]))
}
}

})