import { defineComponent as _defineComponent } from 'vue'
import { vModelText as _vModelText, withModifiers as _withModifiers, withKeys as _withKeys, createElementVNode as _createElementVNode, withDirectives as _withDirectives, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "relative" }
const _hoisted_2 = ["onKeydown"]
const _hoisted_3 = ["disabled"]
const _hoisted_4 = { class: "flex items-center gap-1" }
const _hoisted_5 = { class: "flex items-center mt-2" }
const _hoisted_6 = ["disabled"]
const _hoisted_7 = {
  key: 0,
  class: "mt-2 flex items-center text-sm"
}
const _hoisted_8 = { class: "text-primaryTint mr-2" }
const _hoisted_9 = { class: "truncate" }

import { ref } from "vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'ChatInput',
  props: {
  isLoading: {
    type: Boolean,
    default: false,
  },
},
  emits: ["send"],
  setup(__props, { emit: __emit }) {

const props = __props;

const emit = __emit;
const message = ref("");
const selectedFile = ref<File | null>(null);
const fileInput = ref<HTMLInputElement | null>(null);

const handleSubmit = () => {
  if ((!message.value.trim() && !selectedFile.value) || props.isLoading) return;
  emit("send", message.value, selectedFile.value);
  message.value = "";
  selectedFile.value = null;
};

const handleEnterKey = (e: KeyboardEvent) => {
  if (e.shiftKey) return; // Allow shift+enter for new line
  handleSubmit();
};

const triggerFileInput = () => {
  if (props.isLoading) return;
  fileInput.value?.click();
};

const onFileSelect = (event: Event) => {
  const input = event.target as HTMLInputElement;
  if (input.files && input.files.length > 0) {
    selectedFile.value = input.files[0];
  }
};

const removeFile = () => {
  selectedFile.value = null;
  if (fileInput.value) {
    fileInput.value.value = "";
  }
};

return (_ctx: any,_cache: any) => {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock("form", {
    onSubmit: _withModifiers(handleSubmit, ["prevent"]),
    class: "relative"
  }, [
    _createElementVNode("div", _hoisted_1, [
      _withDirectives(_createElementVNode("textarea", {
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((message).value = $event)),
        placeholder: "Mesajınızı yazın...",
        class: "w-full rounded-lg border p-2 pr-12 block",
        onKeydown: _withKeys(_withModifiers(handleEnterKey, ["prevent"]), ["enter"])
      }, null, 40, _hoisted_2), [
        [_vModelText, message.value]
      ]),
      _createElementVNode("button", {
        type: "submit",
        class: "absolute right-2 top-2 font-semibold bg-primaryTint text-white rounded-md px-3 py-1.5 disabled:opacity-50 disabled:cursor-not-allowed",
        disabled: (!message.value.trim() && !selectedFile.value) || __props.isLoading
      }, [
        _createElementVNode("div", _hoisted_4, [
          (__props.isLoading)
            ? (_openBlock(), _createBlock(_component_font_awesome_icon, {
                key: 0,
                icon: "stethoscope",
                class: "fast-spin"
              }))
            : (_openBlock(), _createBlock(_component_font_awesome_icon, {
                key: 1,
                icon: "paper-plane"
              })),
          _createVNode(_component_font_awesome_icon, { icon: "arrow-right-long" })
        ])
      ], 8, _hoisted_3)
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("button", {
        type: "button",
        onClick: triggerFileInput,
        class: "text-gray-500 hover:text-primaryTint flex items-center gap-1 text-sm",
        disabled: __props.isLoading
      }, [
        _createVNode(_component_font_awesome_icon, { icon: "paperclip" }),
        _cache[1] || (_cache[1] = _createElementVNode("span", null, "PDF Dosyası Ekle", -1))
      ], 8, _hoisted_6),
      _createElementVNode("input", {
        type: "file",
        ref_key: "fileInput",
        ref: fileInput,
        onChange: onFileSelect,
        accept: ".pdf",
        class: "hidden"
      }, null, 544)
    ]),
    (selectedFile.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
          _createElementVNode("span", _hoisted_8, [
            _createVNode(_component_font_awesome_icon, { icon: "file-pdf" })
          ]),
          _createElementVNode("span", _hoisted_9, _toDisplayString(selectedFile.value.name), 1),
          _createElementVNode("button", {
            type: "button",
            onClick: removeFile,
            class: "ml-2 text-red-500 hover:text-red-700"
          }, [
            _createVNode(_component_font_awesome_icon, { icon: "times" })
          ])
        ]))
      : _createCommentVNode("", true)
  ], 32))
}
}

})