<template>
  <div class="flex flex-col md:flex-row w-full">
    <div class="w-full md:w-1/2">
      <DoughnutChart
        :chart-data="genderChartData"
        :options="genderChartOptions"
      />
    </div>
    <div class="w-full md:w-1/2">
      <PieChart :chart-data="ageChartData" :options="ageChartOptions" />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref, onMounted } from "vue";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  Title,
  DoughnutController,
  PieController,
} from "chart.js";
import { DoughnutChart, PieChart } from "vue-chart-3";
import { ApiService } from "@/utils/api";

ChartJS.register(
  ArcElement,
  Tooltip,
  Legend,
  Title,
  DoughnutController,
  PieController
);

const genderChartData = ref({
  labels: [] as string[],
  datasets: [
    {
      data: [] as number[],
      backgroundColor: ["#003366", "#99CCFF", "#E6F2FF"],
    },
  ],
});

const ageChartData = ref({
  labels: [] as string[],
  datasets: [
    {
      data: [] as number[],
      backgroundColor: [
        "#003366",
        "#1a4d80",
        "#002a59",
        "#99CCFF",
        "#9ecfff",
        "#91c2f2",
        "#E6F2FF",
        "#E9F3FF",
        "#CFDCEA",
        "#5A6E8A",
      ],
    },
  ],
});

const genderChartOptions = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      position: "bottom",
      labels: {
        color: "#003366",
      },
    },
    title: {
      display: true,
      text: "Cinsiyet Dağılımı",
      font: {
        size: 16,
      },
      color: "#003366",
    },
  },
};

const ageChartOptions = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      position: "bottom",
      labels: {
        color: "#003366",
      },
    },
    title: {
      display: true,
      text: "Yaş Dağılımı",
      font: {
        size: 16,
      },
      color: "#003366",
    },
  },
};

const fetchData = async () => {
  const genders: Record<string, string> = {
    male: "Erkek",
    female: "Kadın",
    other: "Diğer",
  };
  try {
    const response = await ApiService.admin.getDistributions();

    genderChartData.value.labels = response.genderDistribution.map(
      (item) => genders[item._id]
    );
    genderChartData.value.datasets[0].data = response.genderDistribution.map(
      (item) => item.count
    );

    ageChartData.value.labels = response.ageDistribution.map(
      (item) => item.label
    );
    ageChartData.value.datasets[0].data = response.ageDistribution.map(
      (item) => item.count
    );
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};

onMounted(fetchData);
</script>
