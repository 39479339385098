import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    component: () =>
      import(/* webpackChunkName: "layout" */ "../layout/Auth/index.vue"),
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: "/",
        name: "home",
        component: () =>
          import(/* webpackChunkName: "home" */ "../views/Home.vue"),
      },
      {
        path: "/profilim",
        name: "profile",
        component: () =>
          import(
            /* webpackChunkName: "profile" */ "../views/Profile/index.vue"
          ),
      },
      {
        path: "/gecmis-hizmetlerim",
        name: "previousConsultations",
        component: () =>
          import(
            /* webpackChunkName: "previousConsultations" */ "../views/PreviousConsultations.vue"
          ),
      },
      {
        path: "/gecmis-hizmetlerim/:id",
        name: "consultation",
        component: () =>
          import(
            /* webpackChunkName: "consultation" */ "../views/Consultation.vue"
          ),
      },
      {
        path: "/cep-asistanim",
        name: "healthAssistant",
        component: () =>
          import(
            /* webpackChunkName: "chatAssistant" */ "../views/ChatAssistant.vue"
          ),
      },
      {
        path: "/belgelerim",
        name: "documents",
        component: () =>
          import(/* webpackChunkName: "notFound" */ "../views/Documents.vue"),
      },
    ],
  },
  {
    path: "/admin",
    component: () =>
      import(/* webpackChunkName: "layout" */ "../layout/Admin/index.vue"),
    meta: {
      requiresAuth: true,
      requiresAdmin: true,
    },
    children: [
      {
        path: "",
        name: "adminHome",
        component: () =>
          import(/* webpackChunkName: "adminHome" */ "../views/Admin/Home.vue"),
      },
      {
        path: "kullanicilar",
        name: "adminUsers",
        component: () =>
          import(
            /* webpackChunkName: "adminUsers" */ "../views/Admin/Users.vue"
          ),
        meta: {
          requiresSuperAdmin: true,
        },
      },
      {
        path: "kullanicilar/:id",
        name: "adminUserDetail",
        component: () =>
          import(
            /* webpackChunkName: "adminUserDetail" */ "../views/Admin/UserDetail.vue"
          ),
        props: true,
        meta: {
          requiresSuperAdmin: true,
        },
      },
      {
        path: "branslar",
        name: "adminServices",
        component: () =>
          import(/* webpackChunkName: "adminServices" */ "../views/Soon.vue"),
        meta: {
          requiresSuperAdmin: true,
        },
      },
      {
        path: "danismanliklar",
        name: "adminConsultations",
        component: () =>
          import(
            /* webpackChunkName: "adminConsultations" */ "../views/Admin/Consultations.vue"
          ),
      },
      {
        path: "danismanliklar/:id",
        name: "adminConsultationDetail",
        component: () =>
          import(
            /* webpackChunkName: "adminConsultationDetail" */ "../views/Admin/ConsultationDetail.vue"
          ),
        props: true,
      },
      {
        path: "raporlar",
        name: "adminReports",
        component: () =>
          import(
            /* webpackChunkName: "adminReports" */ "../views/Admin/Reports.vue"
          ),
      },
      {
        path: "blog",
        meta: {
          requiresSuperAdmin: true,
        },
        children: [
          {
            path: "",
            name: "adminBlog",
            component: () =>
              import(
                /* webpackChunkName: "adminBlog" */ "../views/Admin/Blog.vue"
              ),
          },
          {
            path: ":slug",
            name: "adminBlogDetail",
            component: () =>
              import(
                /* webpackChunkName: "adminBlogDetail" */ "../views/Admin/BlogDetail.vue"
              ),
            props: true,
          },
        ],
      },
    ],
  },
  {
    path: "/giris-yap",
    name: "login",
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/Login.vue"),
  },
  {
    path: "/kayit-ol",
    name: "register",
    component: () =>
      import(/* webpackChunkName: "register" */ "../views/Register.vue"),
  },
  {
    path: "/dogrula",
    name: "verify",
    component: () =>
      import(/* webpackChunkName: "register" */ "../views/Verify.vue"),
  },
  {
    path: "/:pathMatch(.*)*",
    name: "notFound",
    component: () =>
      import(/* webpackChunkName: "notFound" */ "../views/NotFound.vue"),
  },
  {
    path: "/sifremi-unuttum",
    name: "forgotPassword",
    component: () =>
      import(
        /* webpackChunkName: "forgotPassword" */ "../views/ForgotPassword.vue"
      ),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  const isAuthenticated = !!localStorage.getItem("accessToken");

  if (to.meta?.requiresAuth) {
    if (isAuthenticated) {
      next();
    } else {
      next({
        name: "login",
      });
    }
  } else {
    if (isAuthenticated) {
      next("/");
    } else {
      next();
    }
  }
});

router.beforeEach((to, from, next) => {
  const user = JSON.parse(localStorage.getItem("user") as string);

  if (to.meta?.requiresAdmin) {
    if (user.role > 0) {
      next();
    } else {
      next({
        name: "home",
      });
    }
  } else {
    next();
  }
});

router.beforeEach((to, from, next) => {
  const user = JSON.parse(localStorage.getItem("user") as string);

  if (to.meta?.requiresSuperAdmin) {
    if (user.role === 2) {
      next();
    } else {
      next({
        name: "adminHome",
      });
    }
  } else {
    next();
  }
});

router.beforeEach((to, from, next) => {
  const isTestEnvironment = window.location.hostname.includes("test");

  if (isTestEnvironment && to.name === "register") {
    next({
      name: "login",
    });
  } else {
    next();
  }
});

router.afterEach((to) => {
  window.gtag("event", "page_view", {
    page_path: to.fullPath,
  });
});

export default router;
