import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, createBlock as _createBlock, renderSlot as _renderSlot, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = {
  key: 0,
  class: ""
}
const _hoisted_2 = { key: 1 }
const _hoisted_3 = {
  key: 2,
  class: "flex gap-2 flex-col md:flex-row"
}
const _hoisted_4 = { class: "" }
const _hoisted_5 = { class: "" }
const _hoisted_6 = { key: 3 }
const _hoisted_7 = { key: 4 }
const _hoisted_8 = { key: 5 }
const _hoisted_9 = { key: 6 }
const _hoisted_10 = { class: "flex gap-2 items-center mb-1 max-w-full overflow-auto" }
const _hoisted_11 = ["title"]
const _hoisted_12 = { class: "max-w-48 truncate overflow-auto" }
const _hoisted_13 = {
  key: 7,
  class: ""
}
const _hoisted_14 = { class: "flex items-start flex-col md:flex-row gap-4" }
const _hoisted_15 = {
  key: 8,
  class: ""
}
const _hoisted_16 = { class: "flex items-start flex-col md:flex-row gap-4" }

import {
  MedicalHistory,
  MedicalHistoryModel,
  Medicine,
  MedicalHistoryFields,
} from "@/utils/api";
import { ref, onMounted } from "vue";
import { watch } from "vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'MedicalHistoryForm',
  props: {
    modelValue: {},
    fields: { default: () =>
      [
        "activityFrequency",
        "alcohol",
        "allergies",
        "cronicDiseases",
        "current",
        "diet",
        "familyDiseases",
        "medicines",
        "smoking",
        "past",
      ] as MedicalHistoryFields },
    type: {}
  },
  emits: ["update:modelValue"],
  setup(__props: any, { emit: __emit }) {

const props = __props;

const emit = __emit;

const medicine = ref<Medicine>({ medicine: "", frequency: "" });

const formData = ref<MedicalHistory>({
  current: "",
  past: "",
  medicines: [],
  allergies: [],
  cronicDiseases: [],
  familyDiseases: [],
  diet: "",
  smoking: {
    isActive: false,
    dailyConsumption: "",
    quitDate: null,
  },
  alcohol: {
    isActive: false,
    weeklyConsumption: "",
    detail: "",
  },
  activityFrequency: "",
});

function addMedicine(e: Event) {
  e.preventDefault();
  formData.value.medicines?.push(medicine.value);
  medicine.value = { medicine: "", frequency: "" };
}

function removeMedicine(medicine: Medicine) {
  formData.value.medicines = formData.value.medicines?.filter(
    (m) =>
      m.medicine !== medicine.medicine || m.frequency !== medicine.frequency
  );
}

onMounted(async () => {
  if (props.modelValue) {
    formData.value = { ...props.modelValue };
  }
});

watch(
  () => props.modelValue,
  (newVal, oldVal) => {
    if (!oldVal && newVal) {
      formData.value = { ...newVal };
    }
  },
  { deep: true }
);
watch(
  formData,
  (newValue) => {
    emit("update:modelValue", newValue);
  },
  { deep: true }
);

return (_ctx: any,_cache: any) => {
  const _component_BaseTextarea = _resolveComponent("BaseTextarea")!
  const _component_BaseInput = _resolveComponent("BaseInput")!
  const _component_BaseArrayInput = _resolveComponent("BaseArrayInput")!
  const _component_BaseLabel = _resolveComponent("BaseLabel")!
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_BaseButton = _resolveComponent("BaseButton")!
  const _component_BaseCheckboxGroup = _resolveComponent("BaseCheckboxGroup")!

  return (_openBlock(), _createElementBlock("form", {
    class: "flex flex-col gap-4 px-3 text-left",
    onSubmit: _cache[14] || (_cache[14] = _withModifiers(() => {}, ["prevent"]))
  }, [
    (props.fields.includes('current'))
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_BaseTextarea, {
            modelValue: formData.value.current,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((formData.value.current) = $event)),
            label: "Mevcut Sağlık Durumunuz",
            icon: "notes-medical",
            placeholder: "Mevcut hastalıklar, şikayetler"
          }, null, 8, ["modelValue"])
        ]))
      : _createCommentVNode("", true),
    (props.fields.includes('past'))
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_BaseTextarea, {
            modelValue: formData.value.past,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((formData.value.past) = $event)),
            label: "Geçmişteki Sağlık Sorunları",
            icon: "notes-medical",
            placeholder: "Geçirilmiş hastalıklar, ameliyatlar"
          }, null, 8, ["modelValue"])
        ]))
      : _createCommentVNode("", true),
    (props.fields.includes('activityFrequency'))
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_BaseInput, {
              modelValue: formData.value.activityFrequency,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((formData.value.activityFrequency) = $event)),
              label: "Hareket Sıklığınız",
              icon: "person-running",
              placeholder: "Haftada 3 gün koşuyorum"
            }, null, 8, ["modelValue"])
          ]),
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_BaseInput, {
              modelValue: formData.value.diet,
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((formData.value.diet) = $event)),
              label: "Diyet",
              placeholder: "Karbonhidrat tüketmiyorum",
              icon: "utensils"
            }, null, 8, ["modelValue"])
          ])
        ]))
      : _createCommentVNode("", true),
    (props.fields.includes('allergies'))
      ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
          _createVNode(_component_BaseArrayInput, {
            modelValue: formData.value.allergies,
            "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((formData.value.allergies) = $event)),
            label: "Allerjiler",
            icon: "hand-dots",
            placeholder: "Güneş, laktoz"
          }, null, 8, ["modelValue"])
        ]))
      : _createCommentVNode("", true),
    (props.fields.includes('familyDiseases'))
      ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
          _createVNode(_component_BaseArrayInput, {
            modelValue: formData.value.familyDiseases,
            "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((formData.value.familyDiseases) = $event)),
            label: "Ailedeki Hastalıklar",
            placeholder: "Diyabet, hipertansiyon, astım, kalp hastalığı",
            icon: "people-roof"
          }, null, 8, ["modelValue"])
        ]))
      : _createCommentVNode("", true),
    (props.fields.includes('cronicDiseases'))
      ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
          _createVNode(_component_BaseArrayInput, {
            modelValue: formData.value.cronicDiseases,
            "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((formData.value.cronicDiseases) = $event)),
            placeholder: "Diyabet, hipertansiyon, astım, kalp hastalığı",
            label: "Kronik Hastalıklar",
            icon: "hospital"
          }, null, 8, ["modelValue"])
        ]))
      : _createCommentVNode("", true),
    (props.fields.includes('medicines'))
      ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
          _createElementVNode("div", _hoisted_10, [
            _createVNode(_component_BaseLabel, null, {
              default: _withCtx(() => _cache[15] || (_cache[15] = [
                _createTextVNode("Kullanılan İlaçlar")
              ])),
              _: 1
            }),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(formData.value.medicines, (medicine) => {
              return (_openBlock(), _createElementBlock("div", {
                key: medicine.medicine,
                class: "bg-secondaryTint text-secondaryShade border-2 border-secondaryShade pl-2 py-1 rounded-full text-xs h-6 font-semibold flex items-center justify-center bg-opacity-30 min-w-24",
                title: medicine.medicine
              }, [
                _createElementVNode("div", _hoisted_12, [
                  _createElementVNode("strong", null, _toDisplayString(medicine.medicine), 1),
                  _createTextVNode(" - " + _toDisplayString(medicine.frequency), 1)
                ]),
                _createVNode(_component_font_awesome_icon, {
                  icon: "x",
                  size: "2xs",
                  class: "p-1 rounded-full cursor-pointer hover:bg-opacity-30 bg-secondaryShade bg-opacity-0 ml-1",
                  onClick: ($event: any) => (removeMedicine(medicine))
                }, null, 8, ["onClick"])
              ], 8, _hoisted_11))
            }), 128))
          ]),
          _createElementVNode("form", {
            onSubmit: addMedicine,
            class: "flex gap-3"
          }, [
            _createVNode(_component_BaseInput, {
              modelValue: medicine.value.medicine,
              "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((medicine.value.medicine) = $event)),
              placeholder: "İlaç İsmi"
            }, null, 8, ["modelValue"]),
            _createVNode(_component_BaseInput, {
              modelValue: medicine.value.frequency,
              "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((medicine.value.frequency) = $event)),
              placeholder: "Dozu"
            }, null, 8, ["modelValue"]),
            _createVNode(_component_BaseButton, {
              type: "secondary",
              "native-type": "submit",
              class: ""
            }, {
              default: _withCtx(() => _cache[16] || (_cache[16] = [
                _createTextVNode("EKLE")
              ])),
              _: 1
            })
          ], 32)
        ]))
      : _createCommentVNode("", true),
    (formData.value.smoking && props.fields.includes('smoking'))
      ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
          _createElementVNode("div", _hoisted_14, [
            _createElementVNode("div", null, [
              _createVNode(_component_BaseCheckboxGroup, {
                multiple: false,
                options: [
              { label: 'Evet', value: true },
              { label: 'Hayır', value: false },
            ],
                modelValue: formData.value.smoking.isActive,
                "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((formData.value.smoking.isActive) = $event)),
                label: "Sigara Kullanımı"
              }, null, 8, ["modelValue"])
            ]),
            _createElementVNode("div", null, [
              (formData.value.smoking.isActive)
                ? (_openBlock(), _createBlock(_component_BaseInput, {
                    key: 0,
                    modelValue: formData.value.smoking.dailyConsumption,
                    "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((formData.value.smoking.dailyConsumption) = $event)),
                    placeholder: "Günde 1 paket",
                    label: "Günlük Kullanım"
                  }, null, 8, ["modelValue"]))
                : (_openBlock(), _createBlock(_component_BaseInput, {
                    key: 1,
                    modelValue: formData.value.smoking.quitDate,
                    "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((formData.value.smoking.quitDate) = $event)),
                    label: "Kullandıysanız Bırakma Tarihi",
                    type: "date"
                  }, null, 8, ["modelValue"]))
            ])
          ])
        ]))
      : _createCommentVNode("", true),
    (formData.value.alcohol && props.fields.includes('alcohol'))
      ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
          _createElementVNode("div", _hoisted_16, [
            _createElementVNode("div", null, [
              _createVNode(_component_BaseCheckboxGroup, {
                multiple: false,
                options: [
              { label: 'Evet', value: true },
              { label: 'Hayır', value: false },
            ],
                modelValue: formData.value.alcohol.isActive,
                "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((formData.value.alcohol.isActive) = $event)),
                label: "Alkol Kullanımı"
              }, null, 8, ["modelValue"])
            ]),
            _createElementVNode("div", null, [
              (formData.value.alcohol.isActive)
                ? (_openBlock(), _createBlock(_component_BaseInput, {
                    key: 0,
                    modelValue: formData.value.alcohol.weeklyConsumption,
                    "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((formData.value.alcohol.weeklyConsumption) = $event)),
                    placeholder: "Haftada 2 kez",
                    label: "Haftalık Kullanım"
                  }, null, 8, ["modelValue"]))
                : _createCommentVNode("", true)
            ]),
            _createElementVNode("div", null, [
              (formData.value.alcohol.isActive)
                ? (_openBlock(), _createBlock(_component_BaseInput, {
                    key: 0,
                    label: "Detaylar"
                  }))
                : _createCommentVNode("", true)
            ])
          ])
        ]))
      : _createCommentVNode("", true),
    _renderSlot(_ctx.$slots, "default")
  ], 32))
}
}

})