import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import {
  faStethoscope,
  faEye,
  faEyeSlash,
  faAnglesLeft,
  faAnglesRight,
  faHospitalUser,
  faLayerGroup,
  faPills,
  faUserNurse,
  faBookMedical,
  faShoePrints,
  faRightFromBracket,
  faCircleExclamation,
  faMars,
  faVenus,
  faVenusMars,
  faWeightScale,
  faRulerVertical,
  faBirthdayCake,
  faX,
  faSquareRootVariable,
  faPersonWalkingArrowRight,
  faFile,
  faSortUp,
  faSortDown,
  faNotesMedical,
  faPersonRunning,
  faUtensils,
  faHandDots,
  faPeopleRoof,
  faDisease,
  faCapsules,
  faHospital,
  faCommentDots,
  faMagnifyingGlass,
  faDownload,
  faHourglassStart,
  faArrowRightLong,
  faPlusCircle,
  faHome,
  faUsers,
  faSquareUpRight,
  faPhone,
  faBold,
  faItalic,
  faLink,
  faImage,
  faListOl,
  faListUl,
  faRotateLeft,
  faRotateRight,
  faHeading,
  faQuoteLeft,
  faStrikethrough,
  faMinus,
  faPenToSquare,
  faChevronLeft,
  faChevronRight,
  faBars,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";

library.add(
  faStethoscope,
  faEye,
  faQuoteLeft,
  faStrikethrough,
  faEyeSlash,
  faAnglesLeft,
  faPhone,
  faMagnifyingGlass,
  faBold,
  faItalic,
  faPenToSquare,
  faMinus,
  faLink,
  faImage,
  faHeading,
  faListOl,
  faListUl,
  faRotateLeft,
  faRotateRight,
  faDownload,
  faArrowRightLong,
  faPlusCircle,
  faAnglesRight,
  faHospitalUser,
  faHome,
  faUsers,
  faLayerGroup,
  faShoePrints,
  faHourglassStart,
  faSquareUpRight,
  faBookMedical,
  faPills,
  faUserNurse,
  faRightFromBracket,
  faPersonWalkingArrowRight,
  faFile,
  faCircleExclamation,
  faCommentDots,
  faSortUp,
  faSortDown,
  faNotesMedical,
  faPersonRunning,
  faUtensils,
  faCapsules,
  faHandDots,
  faHospital,
  faPeopleRoof,
  faDisease,
  faChevronLeft,
  faChevronRight,
  faMars,
  faVenus,
  faWeightScale,
  faX,
  faRulerVertical,
  faBirthdayCake,
  faVenusMars,
  faSquareRootVariable,
  faBars,
  faTimes
);

export default FontAwesomeIcon;
