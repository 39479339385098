import { defineComponent as _defineComponent } from 'vue'
import { vModelCheckbox as _vModelCheckbox, createElementVNode as _createElementVNode, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "flex items-baseline gap-2" }
const _hoisted_2 = ["onClick"]
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { key: 1 }

import {
  ref,
  computed,
  watch,
} from "vue";

interface Policy {
  key: string;
  name: string;
  filename: string;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'LegalDocs',
  props: {
    policies: { default: () => ["privacy"] },
    modelValue: { type: Boolean, default: false },
    variables: {}
  },
  emits: ["update:modelValue"],
  setup(__props: any, { emit: __emit }) {

const isApproved = ref(false);

const props = __props;

const emit = __emit;

const policies = ref<Policy[]>([
  {
    key: "privacy",
    name: "Gizlilik ve Güvenlik Politikası",
    filename: "/legal/privacy.txt",
  },
  {
    key: "sale",
    name: "Mesafeli Satış Sözleşmesi",
    filename: "/legal/sale.txt",
  },
  {
    key: "consent",
    name: "Şikayet Bildirim Formu Açık Rıza Onayı",
    filename: "/legal/consent.txt",
  },
  {
    key: "customer-kvkk",
    name: "Müşteri Aydınlatma Metni",
    filename: "/legal/customer-kvkk.txt",
  },
  {
    key: "kvkk",
    name: "Kişisel Verilerin Korunması, İşlenmesi ve İmhası Politikası",
    filename: "/legal/kvkk.txt",
  },
  {
    key: "medical-approval",
    name: "Tıbbi Onam",
    filename: "/legal/medical-approval.txt",
  },
  {
    key: "price",
    name: "Ücret ve İade Politikası",
    filename: "/legal/price.txt",
  },
  {
    key: "terms-conditions",
    name: "Kullanım Koşulları Sözleşmesi",
    filename: "/legal/terms-conditions.txt",
  },
]);

const selectedPolicies = computed(() =>
  policies.value.filter((p) => props.policies.includes(p.key))
);

const isModelOpened = ref(false);
const txtContent = ref("");
const renderedContent = ref("");

const loadTxt = async (policy: Policy) => {
  isModelOpened.value = true;
  const dynamicData = props.variables ? props.variables : {};
  try {
    const response = await fetch(policy.filename);
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    txtContent.value = await response.text();
    renderedContent.value = txtContent.value.replace(
      /\{(\w+)\}/g,
      (_, key) => dynamicData[key] || `{${key}}`
    );
  } catch (error) {
    console.error("Error loading .txt file:", error);
    renderedContent.value = "Dosya yüklenirken bir hata oluştu.";
  }
};

const downloadAsTxt = () => {
  if (!renderedContent.value) {
    console.error("No content to download.");
    return;
  }

  const blob = new Blob([renderedContent.value], { type: "text/plain" });
  const link = document.createElement("a");
  link.href = URL.createObjectURL(blob);
  link.download = `${selectedPolicies.value[0]?.name}.txt`;
  link.click();
};

watch(isApproved, (val) => {
  emit("update:modelValue", val);
});

return (_ctx: any,_cache: any) => {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_BaseButton = _resolveComponent("BaseButton")!
  const _component_ScrollArea = _resolveComponent("ScrollArea")!
  const _component_BaseModal = _resolveComponent("BaseModal")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _withDirectives(_createElementVNode("input", {
        type: "checkbox",
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((isApproved).value = $event))
      }, null, 512), [
        [_vModelCheckbox, isApproved.value]
      ]),
      _createElementVNode("span", null, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(selectedPolicies.value, (policy, index) => {
          return (_openBlock(), _createElementBlock("span", {
            key: policy.key,
            class: "underline hover:text-secondaryShade cursor-pointer",
            onClick: ($event: any) => (loadTxt(policy))
          }, [
            _createTextVNode(_toDisplayString(policy.name) + " ", 1),
            (index < selectedPolicies.value.length - 2)
              ? (_openBlock(), _createElementBlock("span", _hoisted_3, ", "))
              : _createCommentVNode("", true),
            (index === selectedPolicies.value.length - 2)
              ? (_openBlock(), _createElementBlock("span", _hoisted_4, " ve "))
              : _createCommentVNode("", true)
          ], 8, _hoisted_2))
        }), 128)),
        _cache[2] || (_cache[2] = _createTextVNode("'ni okudum ve onaylıyorum. "))
      ])
    ]),
    _createVNode(_component_BaseModal, {
      modelValue: isModelOpened.value,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((isModelOpened).value = $event))
    }, {
      default: _withCtx(() => [
        _createVNode(_component_BaseButton, {
          onClick: downloadAsTxt,
          class: "w-56",
          type: "alternative"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_font_awesome_icon, {
              icon: "download",
              class: "mr-2"
            }),
            _cache[3] || (_cache[3] = _createTextVNode("Sözleşmeyi İndir"))
          ]),
          _: 1
        }),
        _createVNode(_component_ScrollArea, {
          maxHeight: "600px",
          class: "pb-20 whitespace-pre-wrap"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(renderedContent.value), 1)
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["modelValue"])
  ]))
}
}

})