<template>
  <div class="flex h-full">
    <div class="flex w-full h-full flex-col lg:flex-row gap-2">
      <div class="w-full lg:w-1/2 overflow-y-auto flex relative min-h-96">
        <div class="absolute w-full text-center">
          <div class="bg-gray-100 rounded-t-lg shadow-md relative">
            <div
              class="-bottom-5 right-0 absolute text-xs text-gray-400"
              v-if="loading"
            >
              <font-awesome-icon
                icon="stethoscope"
                class="fast-spin text-secondary"
              />
              Görseller yükleniyor, lütfen bekleyiniz...
            </div>
            <button
              @click="formatText('bold')"
              class="toolbar-button"
              title="Kalın"
            >
              <font-awesome-icon icon="bold" class="font-bold" />
            </button>

            <button
              @click="formatText('italic')"
              class="toolbar-button"
              title="İtalik"
            >
              <font-awesome-icon icon="italic" />
            </button>

            <button
              @click="addStrikethrough()"
              class="toolbar-button"
              title="Üstü Çizili"
            >
              <font-awesome-icon icon="strikethrough" />
            </button>

            <button
              @click="addHeader('#')"
              class="toolbar-button"
              title="Ana Başlık"
            >
              <font-awesome-icon icon="heading" class="text-lg" />
            </button>

            <button
              @click="addHeader('##')"
              class="toolbar-button"
              title="Normal Başlık"
            >
              <font-awesome-icon icon="heading" class="text-md" />
            </button>

            <button
              @click="addHeader('###')"
              class="toolbar-button"
              title="Alt Başlık"
            >
              <font-awesome-icon icon="heading" class="text-xs" />
            </button>

            <button
              @click="addList('ordered')"
              class="toolbar-button"
              title="Sıralı Liste"
            >
              <font-awesome-icon icon="list-ol" />
            </button>

            <button
              @click="addList('unordered')"
              class="toolbar-button"
              title="Sırasız Liste"
            >
              <font-awesome-icon icon="list-ul" />
            </button>

            <button
              @click="addBlockquote()"
              class="toolbar-button"
              title="Alıntı"
            >
              <font-awesome-icon icon="quote-left" />
            </button>

            <button
              @click="addSeparator()"
              class="toolbar-button"
              title="Ayırıcı Çizgi"
            >
              <font-awesome-icon icon="minus" />
            </button>

            <button @click="addLink()" class="toolbar-button" title="Link">
              <font-awesome-icon icon="link" />
            </button>

            <button @click="addImage()" class="toolbar-button" title="Görsel">
              <font-awesome-icon icon="image" />
            </button>

            <button
              @click="addLogo('Cep Doktorum Logo', '/logo-report.svg')"
              class="toolbar-button"
              title="Görsel"
            >
              <img
                :src="imageSrc"
                @mouseover="onHover"
                @mouseleave="onLeave"
                class="h-5 -mb-1.5"
              />
            </button>

            <button @click="undo()" class="toolbar-button" title="Geri Al">
              <font-awesome-icon icon="rotate-left" />
            </button>

            <button @click="redo()" class="toolbar-button" title="İleri Al">
              <font-awesome-icon icon="rotate-right" />
            </button>
          </div>
        </div>

        <textarea
          v-model="markdownInput"
          class="w-full h-full px-4 pt-20 lg:pt-16 text-xl rounded-lg shadow-lg focus:outline-none lg:resize-none"
          @keydown.enter.prevent="handleEnterKey"
          @keydown.meta.b.prevent="() => formatText('bold')"
          @keydown.ctrl.b.prevent="() => formatText('bold')"
          @keydown.meta.i.prevent="() => formatText('italic')"
          @keydown.ctrl.i.prevent="() => formatText('italic')"
          @keydown.meta.z.prevent="undo"
          @keydown.ctrl.z.prevent="undo"
          ref="textarea"
          placeholder="Lütfen buraya yazınız..."
        >
        </textarea>
      </div>

      <div class="w-full lg:w-1/2 h-full">
        <BaseCard class="h-full text-left max-h-full">
          <h2 class="font-semibold text-xl">Önizleme</h2>

          <ScrollArea maxHeight="950px" class="pb-20 whitespace-pre-wrap">
            <MarkdownRenderer :content="markdownInput" />
          </ScrollArea>
        </BaseCard>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, defineProps, watch, defineEmits } from "vue";

import { ApiService } from "@/utils/api";

const props = defineProps<{
  modelValue: string;
}>();

const emit = defineEmits(["update:modelValue"]);

const markdownInput = ref<string>(props.modelValue);

const textarea = ref<HTMLTextAreaElement | null>(null);

const history = ref<string[]>([]);

const future = ref<string[]>([]);

const loading = ref(false);

const logo = "/logo.svg";

const logoLight = "/logo-light.svg";

const imageSrc = ref(logo);

const onHover = () => {
  imageSrc.value = logoLight;
};

const onLeave = () => {
  imageSrc.value = logo;
};

const handleEnterKey = (event: KeyboardEvent) => {
  saveState();
  const textareaEl = event.target as HTMLTextAreaElement;
  const start = textareaEl.selectionStart;
  const end = textareaEl.selectionEnd;

  markdownInput.value =
    markdownInput.value.substring(0, start) +
    "\n" +
    markdownInput.value.substring(end);

  textareaEl.selectionStart = textareaEl.selectionEnd = start + 2;
};

const saveState = () => {
  history.value.push(markdownInput.value);
  future.value = [];
};

const undo = () => {
  if (history.value.length > 0) {
    future.value.push(markdownInput.value);
    markdownInput.value = history.value.pop() || "";
  }
};

const redo = () => {
  if (future.value.length > 0) {
    history.value.push(markdownInput.value);
    markdownInput.value = future.value.pop() || "";
  }
};

const formatText = (type: "bold" | "italic") => {
  saveState();
  const textareaEl = textarea.value;
  if (!textareaEl) return;

  const start = textareaEl.selectionStart;
  const end = textareaEl.selectionEnd;

  const selectedText = markdownInput.value.substring(start, end);
  let formattedText = selectedText;

  if (type === "bold") {
    formattedText = `**${selectedText}**`;
  } else if (type === "italic") {
    formattedText = `*${selectedText}*`;
  }

  markdownInput.value =
    markdownInput.value.substring(0, start) +
    formattedText +
    markdownInput.value.substring(end);

  textareaEl.selectionStart = start + formattedText.length;
  textareaEl.selectionEnd = start + formattedText.length;
  textareaEl.focus();
};

const addHeader = (prefix: string) => {
  saveState();
  const textareaEl = textarea.value;
  if (!textareaEl) return;

  const start = textareaEl.selectionStart;
  const lineStart = markdownInput.value.lastIndexOf("\n", start - 1) + 1;

  markdownInput.value =
    markdownInput.value.substring(0, lineStart) +
    `${prefix} ` +
    markdownInput.value.substring(lineStart);

  textareaEl.selectionStart = textareaEl.selectionEnd =
    start + prefix.length + 1;
  textareaEl.focus();
};

const addList = (type: "ordered" | "unordered") => {
  saveState();
  const textareaEl = textarea.value;
  if (!textareaEl) return;

  const start = textareaEl.selectionStart;
  const end = textareaEl.selectionEnd;
  const selectedText = markdownInput.value.substring(start, end);

  const prefix = type === "ordered" ? (i: number) => `${i + 1}. ` : () => "- ";
  const lines = selectedText.split("\n");

  const formattedLines = lines.map((line, index) => prefix(index) + line);
  const formattedText = formattedLines.join("\n");

  markdownInput.value =
    markdownInput.value.substring(0, start) +
    formattedText +
    markdownInput.value.substring(end);

  textareaEl.selectionStart = start;
  textareaEl.selectionEnd = start + formattedText.length;
  textareaEl.focus();
};

const addSeparator = () => {
  saveState();
  const textareaEl = textarea.value;
  if (!textareaEl) return;

  const start = textareaEl.selectionStart;
  markdownInput.value =
    markdownInput.value.substring(0, start) +
    "\n***\n" +
    markdownInput.value.substring(start);

  textareaEl.selectionStart = textareaEl.selectionEnd = start + 5;
  textareaEl.focus();
};

const addLink = () => {
  saveState();
  const textareaEl = textarea.value;
  if (!textareaEl) return;

  const start = textareaEl.selectionStart;
  const end = textareaEl.selectionEnd;
  const selectedText =
    markdownInput.value.substring(start, end) || "Link eklenecek yazı";

  markdownInput.value =
    markdownInput.value.substring(0, start) +
    `[${selectedText}](url)` +
    markdownInput.value.substring(end);

  textareaEl.selectionStart = start + selectedText.length + 3;
  textareaEl.selectionEnd = start + selectedText.length + 3;
  textareaEl.focus();
};

const addLogo = (alt = "", url = "") => {
  saveState();
  const textareaEl = textarea.value;
  if (!textareaEl) return;
  const start = textareaEl.selectionStart;
  markdownInput.value =
    markdownInput.value.substring(0, start) +
    `![${alt || "Görsel Açıklaması"}](${url || "url"})` +
    markdownInput.value.substring(start);
  textareaEl.focus();
};

const addImage = async () => {
  saveState();
  const textareaEl = textarea.value;
  if (!textareaEl) return;

  const input = document.createElement("input");
  input.type = "file";
  input.accept =
    "image/png, image/jpeg, image/jpg, image/heic, image/HEIC, image/svg";
  input.multiple = true;

  input.addEventListener("change", async () => {
    if (input.files) {
      const files = Array.from(input.files);

      try {
        loading.value = true;
        const uploadedUrls = await ApiService.storage.upload(files, "images");

        const imageMarkdown = uploadedUrls
          .map((url) => `![image](${url})`)
          .join("\n");

        const start = textareaEl.selectionStart;
        markdownInput.value =
          markdownInput.value.substring(0, start) +
          imageMarkdown +
          markdownInput.value.substring(start);

        textareaEl.focus();
      } catch (error) {
        console.error("Image upload failed", error);
      } finally {
        loading.value = false;
      }
    }
  });

  input.click();
};

const addBlockquote = () => {
  saveState();
  const textareaEl = textarea.value;
  if (!textareaEl) return;
  const start = textareaEl.selectionStart;
  markdownInput.value =
    markdownInput.value.substring(0, start) +
    "> " +
    markdownInput.value.substring(start);
  textareaEl.focus();
};

const addStrikethrough = () => {
  saveState();
  const textareaEl = textarea.value;
  if (!textareaEl) return;

  const start = textareaEl.selectionStart;
  const end = textareaEl.selectionEnd;
  const selectedText = markdownInput.value.substring(start, end);

  markdownInput.value =
    markdownInput.value.substring(0, start) +
    `~~${selectedText}~~` +
    markdownInput.value.substring(end);

  textareaEl.focus();
};

watch(
  () => props.modelValue,
  (newValue) => {
    markdownInput.value = newValue;
  }
);

watch(markdownInput, (newValue) => {
  emit("update:modelValue", newValue);
});
</script>

<style scoped>
.toolbar-button {
  @apply text-primary p-2 hover:text-secondary disabled:text-gray-400;
}

.fast-spin {
  animation: fast-spin 1s linear infinite;
}

@keyframes fast-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
