<template>
  <form class="w-full relative" @submit="add">
    <label
      v-if="label"
      class="text-xs font-semibold px-1 flex items-center overflow-auto"
    >
      <font-awesome-icon
        v-if="icon"
        :icon="icon"
        class="absolute top-[38px] left-3 text-gray-400"
      />
      {{ label }}
      <div class="flex gap-1 ml-2">
        <div
          v-for="i in arr"
          :key="i"
          class="bg-secondaryTint text-secondaryShade border-2 border-secondaryShade pl-2 py-1 rounded-full min-w-12 text-xs h-6 font-semibold flex items-center justify-center bg-opacity-30"
        >
          {{ i }}
          <font-awesome-icon
            icon="x"
            size="2xs"
            class="p-1 rounded-full cursor-pointer hover:bg-opacity-30 bg-secondaryShade bg-opacity-0 ml-1"
            @click="remove(i)"
          />
        </div>
      </div>
    </label>
    <div class="flex items-center gap-3">
      <input
        v-model="inputValue"
        :placeholder="placeholder"
        :disabled="disabled"
        @blur="add"
        @keydown="handleKeydown"
        :class="[
          'w-full pr-3 py-2 rounded-lg border-2 border-gray-200 outline-none focus:border-secondary',
          icon ? 'pl-7' : 'pl-3',
        ]"
      />
      <BaseButton
        type="secondary"
        class="!w-32"
        native-type="submit"
        :disabled="props.max && arr.length >= props.max"
        :title="
          props.max && arr.length >= props.max
            ? `Daha fazla ekleyemezsiniz.`
            : ''
        "
        >EKLE</BaseButton
      >
    </div>
  </form>
</template>

<script lang="ts" setup>
import { defineProps, defineEmits, ref, watch } from "vue";

const props = defineProps({
  modelValue: { type: Array, required: true },
  placeholder: { type: String },
  label: { type: String },
  icon: { type: String },
  disabled: { type: Boolean },
  max: { type: Number },
});

const arr = ref<string[]>([...props.modelValue] as string[]);

const emit = defineEmits(["update:modelValue"]);

const inputValue = ref("");

function remove(j: string) {
  arr.value = arr.value.filter((i) => i !== j);
}

function add(e?: Event) {
  if (e) {
    e.preventDefault();
  }
  if (
    (!props.max || arr.value.length < props.max) &&
    inputValue.value.trim().length
  ) {
    arr.value.push(inputValue.value);
    inputValue.value = "";
  }
}

function handleKeydown(e: KeyboardEvent) {
  if (e.key === ",") {
    e.preventDefault();
    add();
  }
}

watch(
  () => props.modelValue,
  (newVal, oldVal) => {
    if (!oldVal.length && newVal.length) {
      arr.value = [...newVal] as string[];
    }
  },
  { deep: true }
);

watch(
  () => arr.value,
  (newValue) => {
    emit("update:modelValue", [...newValue]);
  },
  { deep: true }
);
</script>

<style scoped>
input {
  transition: border-color 0.2s;
}
</style>
