<template>
  <div
    @dragover.prevent="onDragOver"
    @dragleave="onDragLeave"
    @drop="onDrop"
    class="flex flex-col items-center justify-center p-8 border-2 border-dashed rounded-lg transition-colors duration-200"
    :class="{
      'border-primaryTint bg-lightShade bg-opacity-30': isDragging,
      'border-secondary bg-lightTint': !isDragging,
    }"
  >
    <input
      type="file"
      ref="fileInput"
      @change="onFileSelect"
      :multiple="multiple"
      :accept="acceptedFileExtensions.map((ext) => '.' + ext).join(',')"
      class="hidden"
    />
    <BaseButton type="alternative" class="!w-48" @click="triggerFileInput">
      DOSYA SEÇİN
    </BaseButton>
    <p v-if="!modelValue.length" class="text-gray-500 mt-4">
      veya buraya sürükleyin.
    </p>
    <p v-if="exceedsMaxFiles" class="text-red-500 mt-2">
      Maksimum {{ maxFileCount }} dosya yükleyebilirsiniz.
    </p>

    <div v-if="modelValue.length" class="mt-4 space-y-2 w-full max-w-xs">
      <div
        v-for="(file, index) in modelValue"
        :key="index"
        class="flex items-center space-x-4"
      >
        <div
          v-if="file.type.startsWith('image/')"
          class="flex text-sm items-center gap-4"
        >
          <img
            :src="previews.find((p) => p.name === file.name)?.src"
            alt="File Preview"
            class="w-12 h-12 object-cover rounded-md"
          />
          {{ file.name }}
        </div>
        <div v-else>
          <div class="flex items-center truncate text-sm">
            <font-awesome-icon icon="file" class="!text-5xl mr-6" size="5xl" />
            {{ file.name }}
          </div>
        </div>
        <font-awesome-icon
          @click="removeFile(index)"
          class="p-1 rounded-full cursor-pointer text-red-700 hover:bg-red-700 hover:bg-opacity-10"
          size="2xs"
          icon="x"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref, watch, defineProps, withDefaults, defineEmits } from "vue";

const props = withDefaults(
  defineProps<{
    multiple: boolean;
    acceptedFileExtensions: string[];
    modelValue: File[];
    maxFileCount?: number;
  }>(),
  {
    acceptedFileExtensions: () => ["png", "jpeg", "svg", "pdf", "jpg"],
    multiple: false,
    modelValue: () => [],
    maxFileCount: 1,
  }
);

const emit = defineEmits(["update:modelValue"]);

const isDragging = ref(false);
const previews = ref<{ src: string; name: string }[]>([]);
const fileInput = ref<HTMLInputElement | null>(null);
const exceedsMaxFiles = ref(false);

watch(
  () => props.modelValue,
  (newFiles) => {
    previews.value = [];
    newFiles.forEach((file) => {
      if (file.type.startsWith("image/")) {
        const reader = new FileReader();
        reader.onload = (e) => {
          if (e.target?.result) {
            previews.value.push({
              src: e.target.result as string,
              name: file.name,
            });
          }
        };
        reader.readAsDataURL(file);
      }
    });
    exceedsMaxFiles.value = newFiles.length > props.maxFileCount;
  },
  { immediate: true }
);

const onDragOver = () => {
  isDragging.value = true;
};

const onDragLeave = () => {
  isDragging.value = false;
};

const onDrop = (event: DragEvent) => {
  event.preventDefault();
  isDragging.value = false;
  if (event.dataTransfer?.files) {
    handleFiles(event.dataTransfer.files);
  }
};

const onFileSelect = () => {
  if (fileInput.value?.files) {
    handleFiles(fileInput.value.files);
  }
};

const triggerFileInput = () => {
  fileInput.value?.click();
};

const handleFiles = (fileList: FileList) => {
  const selectedFiles = Array.from(fileList).filter((file) => {
    const fileExtension = file.name.split(".").pop()?.toLowerCase();
    return (
      !props.acceptedFileExtensions.length ||
      props.acceptedFileExtensions.includes(fileExtension || "")
    );
  });

  const totalFiles = props.modelValue.length + selectedFiles.length;
  if (totalFiles > props.maxFileCount) {
    exceedsMaxFiles.value = true;
    return;
  }

  const filesToEmit = props.multiple
    ? [...props.modelValue, ...selectedFiles]
    : selectedFiles.slice(0, 1);

  emit("update:modelValue", filesToEmit);
};

const removeFile = (index: number) => {
  const updatedFiles = [...props.modelValue];
  updatedFiles.splice(index, 1);
  emit("update:modelValue", updatedFiles);
};
</script>
