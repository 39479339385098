import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "text-xs font-semibold"
}
const _hoisted_2 = { class: "flex flex-col md:flex-row md:items-center gap-4 mt-2" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "flex items-center cursor-pointer select-none" }
const _hoisted_5 = ["value", "onChange", "checked"]
const _hoisted_6 = ["value", "onChange", "checked"]
const _hoisted_7 = { class: "custom-label-text" }

import { PropType } from "vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'BaseCheckboxGroup',
  props: {
  options: {
    type: Array as PropType<
      Array<{ label: string; value: string | number | boolean; image?: string }>
    >,
    required: true,
  },
  modelValue: {
    type: [Array, String, Number, Boolean] as PropType<
      string | number | boolean | Array<string | number | boolean>
    >,
    required: true,
  },
  multiple: {
    type: Boolean,
    default: false,
  },
  label: {
    type: String,
    required: false,
  },
  hasImage: {
    type: Boolean,
    default: false,
  },
},
  emits: ["update:modelValue"],
  setup(__props, { emit: __emit }) {

const props = __props;

const emit = __emit;

const isChecked = (value: string | number | boolean) => {
  if (props.multiple && Array.isArray(props.modelValue)) {
    return props.modelValue.includes(value);
  }
  return props.modelValue === value;
};

const onCheckboxChange = (event: Event, value: string | number | boolean) => {
  const checked = (event.target as HTMLInputElement).checked;
  if (checked) {
    emit("update:modelValue", [
      ...(props.modelValue as Array<string | number | boolean>),
      value,
    ]);
  } else {
    emit(
      "update:modelValue",
      (props.modelValue as Array<string | number | boolean>).filter(
        (v) => v !== value
      )
    );
  }
};

const onRadioChange = (value: string | number | boolean) => {
  emit("update:modelValue", value);
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    (props.label)
      ? (_openBlock(), _createElementBlock("label", _hoisted_1, _toDisplayString(__props.label), 1))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.options, (option, i) => {
        return (_openBlock(), _createElementBlock("label", {
          key: i,
          class: "flex flex-col justify-end cursor-pointer select-none"
        }, [
          (props.hasImage && option.image)
            ? (_openBlock(), _createElementBlock("img", {
                key: 0,
                src: option.image,
                class: "w-32 h-32 mb-2"
              }, null, 8, _hoisted_3))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_4, [
            (__props.multiple)
              ? (_openBlock(), _createElementBlock("input", {
                  key: 0,
                  type: "checkbox",
                  value: option.value,
                  onChange: ($event: any) => (onCheckboxChange($event, option.value)),
                  checked: isChecked(option.value),
                  class: "custom-input-checkbox"
                }, null, 40, _hoisted_5))
              : (_openBlock(), _createElementBlock("input", {
                  key: 1,
                  type: "radio",
                  value: option.value,
                  onChange: ($event: any) => (onRadioChange(option.value)),
                  checked: __props.modelValue === option.value,
                  class: "custom-input-radio"
                }, null, 40, _hoisted_6)),
            _createElementVNode("span", {
              class: _normalizeClass(["custom-box", __props.multiple ? 'checkbox' : 'radio'])
            }, null, 2),
            _createElementVNode("span", _hoisted_7, _toDisplayString(option.label), 1)
          ])
        ]))
      }), 128))
    ])
  ]))
}
}

})