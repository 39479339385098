<template>
  <div class="relative inline-flex">
    <div
      class="absolute h-full w-full bg-primary opacity-0 hover:opacity-50 transition-all duration-100 z-[999] cursor-zoom-in"
      @click="isModalOpen = true"
    ></div>
    <vue-pdf-embed
      :source="props.source"
      @loaded="handleDocumentLoad"
      :page="1"
    />
    <BaseDrawer
      :isOpen="isModalOpen"
      @close="isModalOpen = false"
      :isFullScreen="true"
    >
      <div class="flex gap-4 sticky top-0 py-4 z-[9999]">
        <BaseButton
          @click="page -= 1"
          type="primary"
          :disabled="page === 1"
          class="w-12 h-12"
        >
          <font-awesome-icon icon="chevron-left" />
        </BaseButton>
        <BaseButton
          @click="page += 1"
          type="primary"
          :disabled="page === pageCount"
          ><font-awesome-icon icon="chevron-right" />
        </BaseButton>
      </div>
      <vue-pdf-embed :source="props.source" :page="page" :scale="1.5" />
    </BaseDrawer>
  </div>
</template>

<script setup lang="ts">
import { ref, defineProps } from "vue";
import VuePdfEmbed from "vue-pdf-embed";

const props = defineProps<{
  source: string;
}>();

const page = ref(1);
const pageCount = ref(0);
const isModalOpen = ref(false);
function handleDocumentLoad({ numPages }: { numPages: number }) {
  pageCount.value = numPages;
}
</script>
