import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "rounded-lg w-full h-full overflow-hidden flex flex-col" }
const _hoisted_2 = {
  key: 0,
  class: "text-center text-gray-500 mt-10"
}
const _hoisted_3 = {
  key: 1,
  class: "typing-indicator-container"
}
const _hoisted_4 = { class: "p-3 border-t border-gray-200" }

import { ref, watch, nextTick } from "vue";
import ChatHeader from "./ChatHeader.vue";
import ChatMessageComponent from "./ChatMessage.vue";
import ChatInput from "./ChatInput.vue";
import type { ChatMessage } from "@/types/chat";


export default /*@__PURE__*/_defineComponent({
  __name: 'ChatWindow',
  props: {
  title: {
    type: String,
    default: "Sağlık Asistanı",
  },
  messages: {
    type: Array as () => ChatMessage[],
    default: () => [],
  },
  isLoading: {
    type: Boolean,
    default: false,
  },
},
  emits: ["send"],
  setup(__props, { emit: __emit }) {

const props = __props;

const emit = __emit;
const messagesContainer = ref<HTMLDivElement | null>(null);

const handleSendMessage = (message: string, document?: File) => {
  emit("send", message, document);
};

const scrollToBottom = () => {
  nextTick(() => {
    if (messagesContainer.value) {
      messagesContainer.value.scrollTop = messagesContainer.value.scrollHeight;
    }
  });
};

watch(
  () => props.messages,
  () => {
    scrollToBottom();
  },
  { deep: true }
);

// Scroll to bottom on mount
scrollToBottom();

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(ChatHeader, { title: __props.title }, null, 8, ["title"]),
    _createElementVNode("div", {
      ref_key: "messagesContainer",
      ref: messagesContainer,
      class: "flex-grow overflow-y-auto p-4 space-y-4"
    }, [
      (__props.messages.length === 0)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, _cache[0] || (_cache[0] = [
            _createElementVNode("p", { class: "text-lg" }, "Sohbete başlamak için bir mesaj gönderin.", -1)
          ])))
        : _createCommentVNode("", true),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.messages, (message, index) => {
        return (_openBlock(), _createBlock(ChatMessageComponent, {
          key: index,
          content: message.content,
          isUser: message.role === 'user',
          timestamp: new Date(message.timestamp)
        }, null, 8, ["content", "isUser", "timestamp"]))
      }), 128)),
      (__props.isLoading)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, _cache[1] || (_cache[1] = [
            _createElementVNode("div", { class: "typing-indicator" }, [
              _createElementVNode("span"),
              _createElementVNode("span"),
              _createElementVNode("span")
            ], -1)
          ])))
        : _createCommentVNode("", true)
    ], 512),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(ChatInput, {
        onSend: handleSendMessage,
        isLoading: __props.isLoading
      }, null, 8, ["isLoading"])
    ])
  ]))
}
}

})