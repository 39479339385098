import type { AxiosInstance } from "axios";
import { toRaw } from "vue";

export class StorageService {
  private readonly instance: AxiosInstance;

  constructor(instance: AxiosInstance) {
    this.instance = instance;
  }

  async upload(files: File[], directory?: string): Promise<string[]> {
    try {
      const formData = new FormData();

      toRaw(files).forEach((file) => {
        formData.append("documents", file);
      });

      const response = await this.instance.post(
        `/storage/upload${directory ? `?directory=${directory}` : ""}`,
        formData,
        {
          withCredentials: true,
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      return response.data.data;
    } catch (error) {
      throw error;
    }
  }
}
