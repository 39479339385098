import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import "./assets/tailwind.css";
import FontAwesomeIcon from "./font-awesome-icons";
import VueTelInput from "vue-tel-input";
import "vue-tel-input/vue-tel-input.css";
import gtag from "vue-gtag-next";

const app = createApp(App);

const requireComponent = require.context("./components", true, /\.vue$/);

requireComponent.keys().forEach((filePath) => {
  const componentConfig = requireComponent(filePath);
  const componentName = filePath.split("/").pop()?.replace(".vue", "") || "";
  app.component(componentName, componentConfig.default || componentConfig);
});

app.component("font-awesome-icon", FontAwesomeIcon);

app.use(gtag, {
  property: {
    id: process.env.VUE_APP_GOOGLE_ANALYTICS_ID,
  },
});

const globalOptions = {
  mode: "international",
  inputOptions: {
    placeholder: "+90 555 555 55 55",
  },
};

app.use(VueTelInput, globalOptions);

app.use(store).use(router).mount("#app");
