<template>
  <Transition
    name="drawer"
    mode="out-in"
    :duration="{ enter: 300, leave: 300 }"
  >
    <div class="fixed inset-0 z-[1000]" v-if="isOpen">
      <div
        @click="closeDrawer"
        class="fixed inset-0 bg-black bg-opacity-70"
      ></div>

      <div
        :class="[
          'drawer-panel fixed right-0 bottom-0 shadow-lg w-full',
          isFullScreen
            ? 'sm:!w-[calc(100%-288px)] !bg-light'
            : 'sm:w-1/3 bg-white',
        ]"
      >
        <div class="py-2 px-4 h-full overflow-y-auto">
          <div class="flex items-center gap-4">
            <font-awesome-icon
              icon="x"
              size="xs"
              class="cursor-pointer text-gray-500"
              @click="closeDrawer"
            />
            <slot name="title"></slot>
          </div>
          <div class="p-4 h-full">
            <slot></slot>
          </div>
        </div>
      </div>
    </div>
  </Transition>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    isOpen: {
      type: Boolean,
      required: true,
    },
    title: {
      type: String,
      required: false,
    },
    isFullScreen: {
      type: Boolean,
      required: false,
    },
  },
  emits: ["close"],
  setup(_, { emit }) {
    const closeDrawer = () => {
      emit("close");
    };

    return {
      closeDrawer,
    };
  },
});
</script>

<style scoped>
.drawer-panel {
  height: 80%;
}

@media (min-width: 640px) {
  .drawer-panel {
    height: 100%;
    right: 0;
  }
}

.drawer-enter-active,
.drawer-leave-active {
  transition: transform 0.3s ease-in-out, opacity 0.2s ease-in-out;
}

.drawer-enter-from,
.drawer-leave-to {
  transform: translateX(100%);
  opacity: 0;
}

.drawer-enter-to,
.drawer-leave-from {
  transform: translateX(0);
  opacity: 1;
}
</style>
