import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"



export default /*@__PURE__*/_defineComponent({
  __name: 'BaseLabel',
  props: {
    size: {}
  },
  setup(__props: any) {

const props = __props;

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("label", {
    class: _normalizeClass(`font-semibold text-${props.size || 'xs'}`)
  }, [
    _renderSlot(_ctx.$slots, "default")
  ], 2))
}
}

})