import type { AxiosInstance } from "axios";
import { UserModel } from "../auth";
import { ConsultationModel } from "../consultation";
import { MedicalHistoryModel } from "../profile";

export class AdminService {
  private readonly instance: AxiosInstance;

  constructor(instance: AxiosInstance) {
    this.instance = instance;
  }

  async getDashboardStats(): Promise<{
    userCount: number;
    profileCount: number;
    consultationCount: number;
  }> {
    try {
      const response = await this.instance.get("/admin/dashboard");
      return response.data.data;
    } catch (error) {
      throw error;
    }
  }

  async listUsers(): Promise<
    {
      _id: string;
      phoneNumber: string;
      role: number;
      isInFreeTrial: boolean;
      createdAt: Date;
      profile?: {
        _id: string;
        firstName: string;
        lastName: string;
      };
    }[]
  > {
    try {
      const response = await this.instance.get("/admin/users");
      return response.data.data;
    } catch (error) {
      throw error;
    }
  }

  async latestUsers(): Promise<
    {
      _id: string;
      phoneNumber: string;
      createdAt: Date;
    }[]
  > {
    try {
      const response = await this.instance.get("/admin/users/latest");
      return response.data.data;
    } catch (error) {
      throw error;
    }
  }

  async getConsultations(
    isPending = false,
    isReportsOnly?: boolean
  ): Promise<
    {
      _id: string;
      service: string;
      subService?: string;
      report?: string;
      profile: {
        firstName: string;
        lastName: string;
      };
      createdAt: Date;
    }[]
  > {
    try {
      const params: any = {};
      if (isPending) {
        params.status = "pending";
      }
      if (isReportsOnly) {
        params.reportsOnly = "true";
      }
      const response = await this.instance.get("/admin/consultations", {
        withCredentials: true,
        params,
      });
      return response.data.data;
    } catch (error) {
      throw error;
    }
  }

  async getUser(id: string): Promise<UserModel> {
    try {
      const response = await this.instance.get(`/admin/users/${id}`);
      return response.data.data;
    } catch (error) {
      throw error;
    }
  }

  async listUsersConsultations(id: string): Promise<ConsultationModel[]> {
    try {
      const response = await this.instance.get(
        `/admin/users/${id}/consultations`,
        {
          withCredentials: true,
        }
      );
      return response.data.data;
    } catch (error) {
      throw error;
    }
  }

  async getUsersMedicalHistory(id: string): Promise<MedicalHistoryModel> {
    try {
      const response = await this.instance.get(
        `/admin/users/${id}/medical_history`,
        {
          withCredentials: true,
        }
      );
      return response.data.data;
    } catch (error) {
      throw error;
    }
  }

  async getConsultationDetail(id: string): Promise<{
    consultation: ConsultationModel;
    medicalHistory?: MedicalHistoryModel;
  }> {
    try {
      const response = await this.instance.get(`/admin/consultations/${id}`, {
        withCredentials: true,
      });
      return response.data.data;
    } catch (error) {
      throw error;
    }
  }

  async getDistributions(): Promise<{
    genderDistribution: { _id: string; count: number }[];
    ageDistribution: { label: string; count: number }[];
  }> {
    try {
      const response = await this.instance.get(
        `/admin/dashboard/distrubitons`,
        {
          withCredentials: true,
        }
      );
      return response.data.data;
    } catch (error) {
      throw error;
    }
  }

  async setFreeTrial(id: string): Promise<{
    user: UserModel;
  }> {
    try {
      const response = await this.instance.post(
        `/admin/users/${id}/free_trial`,
        {
          withCredentials: true,
        }
      );
      return response.data.data;
    } catch (error) {
      throw error;
    }
  }

  async updateReport(id: string, report: string): Promise<ConsultationModel> {
    try {
      const response = await this.instance.post(
        `/admin/consultations/${id}/report`,
        { report },
        {
          withCredentials: true,
        }
      );
      return response.data.data;
    } catch (error) {
      throw error;
    }
  }
}
