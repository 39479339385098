<template>
  <BaseCard>
    <BaseLabel size="xl">Kart Bilgileri</BaseLabel>

    <form class="flex flex-col gap-4">
      <div class="w-full">
        <BaseInput
          v-model="card.cardHolderName"
          label=" Kart Üzerinde Yazan İsim"
          placeholder="İsim Soyisim"
        />
      </div>

      <div class="w-full">
        <BaseInput
          v-model="card.cardNumber"
          label="Kart Numarası"
          placeholder="1234 1234 1234 1234"
        />
      </div>

      <div class="w-full flex items-center gap-2">
        <div class="flex">
          <BaseSelect
            v-model="card.expireMonth"
            :options="monthOptions"
            label="Ay"
            class="w-16"
          />
          <BaseSelect
            v-model="card.expireYear"
            :options="yearOptions"
            label="Yıl"
            class="w-16"
          />
        </div>
      </div>
      <BaseInput v-model="card.cvc" label="CVC" class="!w-24" />
    </form>
  </BaseCard>
</template>

<script lang="ts" setup>
import { ref, watch, defineProps, defineEmits } from "vue";
import { PaymentCard } from "@/utils/api";

const emit = defineEmits(["update:modelValue"]);
const props = defineProps<{ modelValue: PaymentCard }>();

const monthOptions = [
  { key: "01", label: "Ocak" },
  { key: "02", label: "Şubat" },
  { key: "03", label: "Mart" },
  { key: "04", label: "Nisan" },
  { key: "05", label: "Mayıs" },
  { key: "06", label: "Haziran" },
  { key: "07", label: "Temmuz" },
  { key: "08", label: "Ağustos" },
  { key: "09", label: "Eylül" },
  { key: "10", label: "Ekim" },
  { key: "11", label: "Kasım" },
  { key: "12", label: "Aralık" },
];

const yearOptions = [
  { key: "2024", label: "2024" },
  { key: "2025", label: "2025" },
  { key: "2026", label: "2026" },
  { key: "2027", label: "2027" },
  { key: "2028", label: "2028" },
  { key: "2029", label: "2029" },
  { key: "2030", label: "2030" },
  { key: "2031", label: "2031" },
  { key: "2032", label: "2032" },
  { key: "2033", label: "2033" },
  { key: "2034", label: "2034" },
];

const card = ref<PaymentCard>({
  cardHolderName: "PAYTR TEST",
  cardNumber: "4355084355084358",
  expireMonth: "12",
  expireYear: "2030",
  cvc: "000",
});

watch(
  card,
  (newValue) => {
    emit("update:modelValue", newValue);
  },
  { deep: true }
);
</script>
