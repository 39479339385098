<template>
  <div class="relative overflow-x-auto shadow-md sm:rounded-lg">
    <table class="w-full text-md text-left text-gray-500">
      <thead class="text-md text-primaryTint bg-gray-50">
        <tr>
          <th
            v-for="(column, index) in columns"
            :key="index"
            scope="col"
            class="px-6 py-3"
            :class="getFixedColumnClass(index)"
            :style="{
              minWidth: column.minWidth ? column.minWidth + 'px' : 'auto',
            }"
          >
            <div class="flex items-center">
              {{ column.label }}
              <div
                v-if="column.isSortable"
                class="ml-2 flex flex-col items-center space-y-[-12px] cursor-pointer"
              >
                <font-awesome-icon
                  icon="sort-up"
                  :class="[getSortIconClass(column.prop, 'asc')]"
                  @click="sortColumn(column.prop, 'asc')"
                />
                <font-awesome-icon
                  icon="sort-down"
                  :class="[getSortIconClass(column.prop, 'desc')]"
                  @click="sortColumn(column.prop, 'desc')"
                />
              </div>
            </div>
          </th>
          <th scope="col" class="px-6 py-3">
            <span class="sr-only"></span>
          </th>
        </tr>
      </thead>
      <tbody v-if="loading">
        <tr>
          <td
            :colspan="columns.length + 1"
            class="text-center py-10 text-secondary"
          >
            <font-awesome-icon icon="stethoscope" class="fast-spin" size="xl" />
          </td>
        </tr>
      </tbody>
      <tbody v-else>
        <tr
          v-for="row in sortedData"
          :key="row[keyProp]"
          class="bg-white border-b hover:bg-gray-50"
        >
          <td
            v-for="(column, colIndex) in columns"
            :key="colIndex"
            class="px-6 py-4"
            :class="getFixedColumnClass(colIndex)"
            :style="{
              minWidth: column.minWidth ? column.minWidth + 'px' : 'auto',
            }"
          >
            {{ formatData(getNestedValue(row, column.prop), column.type) }}
          </td>
          <td class="px-6 py-4 text-right">
            <slot :row="row" :id="row[keyProp]"></slot>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script lang="ts" setup>
import moment from "moment";
import { ref, computed, defineProps, withDefaults } from "vue";

interface Column {
  label: string;
  prop: string;
  type: "string" | "float" | "integer" | "date" | "boolean";
  isSortable: boolean;
  minWidth?: number;
}

interface DataRow {
  [key: string]: any;
}

const props = withDefaults(
  defineProps<{
    columns: Column[];
    data: DataRow[];
    keyProp: string;
    loading: boolean;
    fixedColumns?: number;
  }>(),
  { fixedColumns: 0 }
);

const sortState = ref<{ prop: string; direction: "asc" | "desc" | null }>({
  prop: "",
  direction: null,
});

const sortColumn = (prop: string, direction: "asc" | "desc") => {
  if (
    sortState.value.prop === prop &&
    sortState.value.direction === direction
  ) {
    sortState.value.prop = "";
    sortState.value.direction = null;
  } else {
    sortState.value.prop = prop;
    sortState.value.direction = direction;
  }
};

const getSortIconClass = (prop: string, direction: "asc" | "desc") => {
  if (sortState.value.prop !== prop) return "text-primary";
  return sortState.value.direction === direction
    ? "text-secondary"
    : "text-primary";
};

const sortedData = computed(() => {
  if (!sortState.value.direction) return props.data;

  return [...props.data].sort((a, b) => {
    const valA = getNestedValue(a, sortState.value.prop);
    const valB = getNestedValue(b, sortState.value.prop);

    if (sortState.value.direction === "asc") {
      return valA > valB ? 1 : valA < valB ? -1 : 0;
    } else {
      return valA < valB ? 1 : valA > valB ? -1 : 0;
    }
  });
});

const formatData = (value: any, type: Column["type"]) => {
  switch (type) {
    case "date":
      return moment(value).format("D MMMM YYYY HH:mm");
    case "float":
      return parseFloat(value).toFixed(2);
    case "integer":
      return parseInt(value, 10);
    case "boolean":
      return value ? "Evet" : "Hayır";
    default:
      return value;
  }
};

const getNestedValue = (obj: any, path: string) => {
  return path.split(".").reduce((acc, part) => acc && acc[part], obj);
};

const getFixedColumnClass = (index: number) => {
  return index < props.fixedColumns ? "sticky left-0 bg-white z-10" : "";
};
</script>

<style scoped>
.fast-spin {
  animation: fast-spin 1s linear infinite;
}

.sticky {
  box-shadow: 3px 0 5px -2px rgba(0, 0, 0, 0.1);
  position: sticky;
}

@keyframes fast-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
