import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, vModelSelect as _vModelSelect, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "w-full relative" }
const _hoisted_2 = {
  key: 0,
  class: "text-xs font-semibold px-1"
}
const _hoisted_3 = { class: "flex" }
const _hoisted_4 = ["disabled"]
const _hoisted_5 = ["value"]

import { ref, watch } from "vue";

export type Option = string | { key: string; label: string };


export default /*@__PURE__*/_defineComponent({
  __name: 'BaseSelect',
  props: {
    modelValue: {},
    placeholder: {},
    label: {},
    disabled: { type: Boolean },
    options: {}
  },
  emits: ["update:modelValue"],
  setup(__props: any, { emit: __emit }) {

const props = __props;

const emit = __emit;

const inputValue = ref(props.modelValue);

watch(
  () => props.modelValue,
  (newValue) => {
    inputValue.value = newValue;
  }
);

watch(inputValue, (newValue) => {
  emit("update:modelValue", newValue);
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.label)
      ? (_openBlock(), _createElementBlock("label", _hoisted_2, _toDisplayString(_ctx.label), 1))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_3, [
      _withDirectives(_createElementVNode("select", {
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((inputValue).value = $event)),
        disabled: _ctx.disabled,
        class: _normalizeClass([
          'w-full px-3 py-2 rounded-lg border-2 border-gray-200 outline-none focus:border-secondary bg-white',
        ])
      }, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (option) => {
          return (_openBlock(), _createElementBlock("option", {
            key: typeof option == 'string' ? option : option.key,
            value: typeof option == 'string' ? option : option.key
          }, _toDisplayString(typeof option == "string" ? option : option.label), 9, _hoisted_5))
        }), 128))
      ], 8, _hoisted_4), [
        [_vModelSelect, inputValue.value]
      ])
    ])
  ]))
}
}

})