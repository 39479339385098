import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import { computed } from "vue";

interface Disclaimer {
  text: string;
  type: "error" | "warning" | "success" | "primary";
}


export default /*@__PURE__*/_defineComponent({
  __name: 'Disclaimer',
  props: {
    text: {},
    type: {}
  },
  setup(__props: any) {

const props = __props;

const backgroundClass = computed(() => {
  switch (props.type) {
    case "error":
      return "bg-red-50";
    case "warning":
      return "bg-amber-50";
    case "success":
      return "bg-green-50";
    default:
      return "";
  }
});

const borderClass = computed(() => {
  switch (props.type) {
    case "error":
      return "border-l-red-600";
    case "warning":
      return "border-l-yellow-600";
    case "success":
      return "border-l-green-600";
    default:
      return "";
  }
});

const iconColorClass = computed(() => {
  switch (props.type) {
    case "error":
      return "text-red-600";
    case "warning":
      return "text-yellow-600";
    case "success":
      return "text-green-600";
    default:
      return "";
  }
});

return (_ctx: any,_cache: any) => {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([
      'px-4 py-4 text-left mt-2 mb-4 rounded-md border-l-4 shadow text-sm',
      backgroundClass.value,
      borderClass.value,
    ])
  }, [
    _createVNode(_component_font_awesome_icon, {
      icon: "circle-exclamation",
      class: _normalizeClass([iconColorClass.value, "mr-1"])
    }, null, 8, ["class"]),
    _createTextVNode(" " + _toDisplayString(_ctx.text), 1)
  ], 2))
}
}

})