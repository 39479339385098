<template>
  <div class="gap-[30px]">
    <BaseLabel size="3xl flex items-center gap-4 justify-center py-3">
      <img src="/logo.svg" class="h-12" /> Nasıl Çalışır?
    </BaseLabel>
    <div v-for="step in steps" :key="step.id" class="">
      <router-link
        :to="{ name: step.route }"
        :class="[
          'flex items-start gap-[10px] rounded-[10px] border border-transparent  py-6 px-5  border-secondary cursor-pointer',
          props.activeStep >= step.id
            ? 'bg-secondary border-primaryTint bg-opacity-50 hover:bg-opacity-75'
            : 'bg-secondary/10  hover:border-secondary hover:bg-transparent',
        ]"
      >
        <span
          :class="[
            'flex h-[50px] w-[50px] min-w-[50px] items-center justify-center rounded-full text-lg text-white',
            props.activeStep >= step.id
              ? 'bg-primaryTint'
              : 'bg-secondary text-white',
          ]"
          >{{ step.id }}</span
        >
        <div>
          <h6
            :class="[
              'mb-1 font-bold text-left',
              activeStep >= step.id ? 'text-primaryTint' : 'text-secondary',
            ]"
          >
            {{ step.title }}
          </h6>
          <p class="text-sm font-semibold text-black dark:text-gray text-left">
            {{ step.description }}
          </p>
        </div>
      </router-link>
      <div
        :class="[
          'py-4',
          props.activeStep >= step.id ? 'text-primary' : 'text-secondary',
        ]"
        v-if="step.id !== steps.length"
      >
        <font-awesome-icon icon="shoe-prints" class="rotate-90" />
        <font-awesome-icon icon="shoe-prints" class="rotate-90" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, defineProps } from "vue";

const props = defineProps({
  activeStep: {
    type: Number,
    default: 1,
    required: true,
  },
});

const steps = ref<
  { id: number; title: string; description: string; route: string }[]
>([
  {
    id: 1,
    title: "Profil Oluştur",
    description: "Seni daha iyi analiz edebilmemiz için profilini oluştur!",
    route: "profile",
  },
  {
    id: 2,
    title: "Hizmet Seç",
    description: "Şikayetinle ilgili olan branşı ve uzmanlık alanını seç!",
    route: "home",
  },
  {
    id: 3,
    title: "Belgelerini Yükle",
    description:
      " Yorumlanmasını istediğin tetkikini yükle, formunu doldur ve bize gönder!",
    route: "home",
  },
  {
    id: 4,
    title: "Yorumunu Öğren",
    description:
      " 24 saat içerisinde sana ulaşalım, yapay zeka destekli sağlık yorumunu öğren!",
    route: "home",
  },
]);
</script>
