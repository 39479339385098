import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "flex w-full" }
const _hoisted_2 = { class: "px-3 mb-5 text-left w-1/2" }
const _hoisted_3 = { class: "px-3 mb-5 text-left w-1/2" }
const _hoisted_4 = { class: "flex w-full" }
const _hoisted_5 = { class: "px-3 mb-5 text-left w-1/2" }
const _hoisted_6 = { class: "px-3 mb-5 text-left w-1/2" }
const _hoisted_7 = { class: "flex w-full" }
const _hoisted_8 = { class: "px-3 mb-5 text-left w-1/2" }
const _hoisted_9 = { class: "px-3 mb-5 text-left w-1/2" }
const _hoisted_10 = {
  key: 0,
  class: "text-red-500 text-sm mb-2"
}

import { ApiService, ProfilePayload, Profile } from "@/utils/api";
import type { Option } from "@/components/BaseSelect.vue";
import {
  ref,
  computed,
  onMounted,
  onUnmounted,
} from "vue";
import { watch } from "vue";
import { useStore } from "vuex";


export default /*@__PURE__*/_defineComponent({
  __name: 'ProfileForm',
  props: {
    profile: {},
    type: {}
  },
  emits: ["update:profile"],
  setup(__props: any, { emit: __emit }) {

const store = useStore();

const props = __props;

const emit = __emit;

const buttonText = computed(() =>
  props.type === "create" ? "PROFİLİNİ TAMAMLA" : "KAYDET"
);

const loading = ref(false);
const error = ref(false);

const genders: Option[] = [
  { key: "male", label: "Erkek" },
  { key: "female", label: "Kadın" },
  { key: "other", label: "Diğer" },
];

const formData = ref<ProfilePayload>({
  firstName: "",
  lastName: "",
  gender: null,
  height: 50,
  weight: 20,
  dateOfBirth: null,
});

async function submit(e: Event) {
  e.preventDefault();
  error.value = false;

  if (!isFormValidated.value) {
    error.value = true;
    return;
  }
  loading.value = true;

  ApiService.profile[props.type](formData.value)
    .then((res) => {
      emit("update:profile", res);

      if (props.type === "create") {
        store.commit("SET_USER_PROFILE", res);
      }
    })
    .catch((err) => {
      console.log(err.response);
    })
    .finally(() => {
      loading.value = false;
    });
}

const isFormValidated = computed(() => {
  const data: Profile = formData.value;
  return (
    data.firstName.length &&
    data.lastName.length &&
    data.gender?.length &&
    data.height > 50 &&
    data.height < 250 &&
    data.weight > 20 &&
    data.weight < 300 &&
    data.dateOfBirth
  );
});

const handleFocus = () => {
  error.value = false;
};

onMounted(async () => {
  const inputs = document.querySelectorAll(
    "form input, form textarea, form select"
  );
  inputs.forEach((input) => {
    input.addEventListener("focus", handleFocus);
  });
  if (props.profile) {
    const { _id, createdAt, updatedAt, user, __v, ...others } = props.profile;
    formData.value = {
      ...others,
    };
  }
});

onUnmounted(() => {
  const inputs = document.querySelectorAll(
    "form input, form textarea, form select"
  );
  inputs.forEach((input) => {
    input.removeEventListener("focus", handleFocus);
  });
});

watch(
  () => props.profile,
  (newValue) => {
    const { _id, createdAt, updatedAt, user, __v, ...others } = newValue;
    formData.value = {
      ...others,
    };
  },
  { deep: true }
);

return (_ctx: any,_cache: any) => {
  const _component_BaseInput = _resolveComponent("BaseInput")!
  const _component_BaseSelect = _resolveComponent("BaseSelect")!
  const _component_BaseRangeInput = _resolveComponent("BaseRangeInput")!
  const _component_BaseButton = _resolveComponent("BaseButton")!

  return (_openBlock(), _createElementBlock("form", {
    class: "mt-8",
    onSubmit: submit,
    onFocus: _cache[6] || (_cache[6] = ($event: any) => (error.value = false))
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_BaseInput, {
          modelValue: formData.value.firstName,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((formData.value.firstName) = $event)),
          label: "İsminiz"
        }, null, 8, ["modelValue"])
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_BaseInput, {
          modelValue: formData.value.lastName,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((formData.value.lastName) = $event)),
          label: "Soyisminiz"
        }, null, 8, ["modelValue"])
      ])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        _createVNode(_component_BaseSelect, {
          modelValue: formData.value.gender,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((formData.value.gender) = $event)),
          label: "Cinsiyetiniz",
          options: genders
        }, null, 8, ["modelValue"])
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createVNode(_component_BaseInput, {
          modelValue: formData.value.dateOfBirth,
          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((formData.value.dateOfBirth) = $event)),
          label: "Doğum Tarihiniz",
          type: "date"
        }, null, 8, ["modelValue"])
      ])
    ]),
    _createElementVNode("div", _hoisted_7, [
      _createElementVNode("div", _hoisted_8, [
        _createVNode(_component_BaseRangeInput, {
          modelValue: formData.value.height,
          "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((formData.value.height) = $event)),
          label: "Boyunuz",
          min: 50,
          max: 250,
          step: 1,
          unit: "cm"
        }, null, 8, ["modelValue"])
      ]),
      _createElementVNode("div", _hoisted_9, [
        _createVNode(_component_BaseRangeInput, {
          modelValue: formData.value.weight,
          "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((formData.value.weight) = $event)),
          label: "Kilonuz",
          min: 20,
          max: 200,
          step: 1,
          unit: "kg"
        }, null, 8, ["modelValue"])
      ])
    ]),
    (error.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_10, " * Lütfen formu eksiksiz doldurunuz. "))
      : _createCommentVNode("", true),
    _createVNode(_component_BaseButton, {
      "native-type": "submit",
      loading: loading.value
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(buttonText.value), 1)
      ]),
      _: 1
    }, 8, ["loading"])
  ], 32))
}
}

})