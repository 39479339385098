<template>
  <div
    :class="[
      'relative cursor-pointer rounded-3xl border border-transparent transition duration-500 hover:border-secondary hover:bg-secondary/20',
      props.selectedService?.ID === props.service.ID
        ? '!border-secondary !bg-secondary/20'
        : 'bg-white',
    ]"
  >
    <img
      :src="props.service.IMAGE"
      :alt="props.service.NAME"
      class="h-52 w-full rounded-t-3xl object-cover"
      loading="lazy"
    />
    <div class="p-5 text-sm font-bold">
      <h6 class="mb-1 text-black">
        {{ props.service.NAME }}
      </h6>
    </div>
  </div>
</template>

<script setup lang="ts">
import { defineProps } from "vue";
import { Service } from "@/utils/api";

const props = defineProps<{
  service: Service;
  selectedService: Service | undefined;
}>();
</script>
