import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "gap-[30px]" }
const _hoisted_2 = { class: "text-sm font-semibold text-black dark:text-gray text-left" }

import { ref } from "vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'Steps',
  props: {
  activeStep: {
    type: Number,
    default: 1,
    required: true,
  },
},
  setup(__props) {

const props = __props;

const steps = ref<
  { id: number; title: string; description: string; route: string }[]
>([
  {
    id: 1,
    title: "Profil Oluştur",
    description: "Seni daha iyi analiz edebilmemiz için profilini oluştur!",
    route: "profile",
  },
  {
    id: 2,
    title: "Hizmet Seç",
    description: "Şikayetinle ilgili olan branşı ve uzmanlık alanını seç!",
    route: "home",
  },
  {
    id: 3,
    title: "Belgelerini Yükle",
    description:
      " Yorumlanmasını istediğin tetkikini yükle, formunu doldur ve bize gönder!",
    route: "home",
  },
  {
    id: 4,
    title: "Yorumunu Öğren",
    description:
      " 24 saat içerisinde sana ulaşalım, yapay zeka destekli sağlık yorumunu öğren!",
    route: "home",
  },
]);

return (_ctx: any,_cache: any) => {
  const _component_BaseLabel = _resolveComponent("BaseLabel")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_BaseLabel, { size: "3xl flex items-center gap-4 justify-center py-3" }, {
      default: _withCtx(() => _cache[0] || (_cache[0] = [
        _createElementVNode("img", {
          src: "/logo.svg",
          class: "h-12"
        }, null, -1),
        _createTextVNode(" Nasıl Çalışır? ")
      ])),
      _: 1
    }),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(steps.value, (step) => {
      return (_openBlock(), _createElementBlock("div", {
        key: step.id,
        class: ""
      }, [
        _createVNode(_component_router_link, {
          to: { name: step.route },
          class: _normalizeClass([
          'flex items-start gap-[10px] rounded-[10px] border border-transparent  py-6 px-5  border-secondary cursor-pointer',
          props.activeStep >= step.id
            ? 'bg-secondary border-primaryTint bg-opacity-50 hover:bg-opacity-75'
            : 'bg-secondary/10  hover:border-secondary hover:bg-transparent',
        ])
        }, {
          default: _withCtx(() => [
            _createElementVNode("span", {
              class: _normalizeClass([
            'flex h-[50px] w-[50px] min-w-[50px] items-center justify-center rounded-full text-lg text-white',
            props.activeStep >= step.id
              ? 'bg-primaryTint'
              : 'bg-secondary text-white',
          ])
            }, _toDisplayString(step.id), 3),
            _createElementVNode("div", null, [
              _createElementVNode("h6", {
                class: _normalizeClass([
              'mb-1 font-bold text-left',
              __props.activeStep >= step.id ? 'text-primaryTint' : 'text-secondary',
            ])
              }, _toDisplayString(step.title), 3),
              _createElementVNode("p", _hoisted_2, _toDisplayString(step.description), 1)
            ])
          ]),
          _: 2
        }, 1032, ["to", "class"]),
        (step.id !== steps.value.length)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: _normalizeClass([
          'py-4',
          props.activeStep >= step.id ? 'text-primary' : 'text-secondary',
        ])
            }, [
              _createVNode(_component_font_awesome_icon, {
                icon: "shoe-prints",
                class: "rotate-90"
              }),
              _createVNode(_component_font_awesome_icon, {
                icon: "shoe-prints",
                class: "rotate-90"
              })
            ], 2))
          : _createCommentVNode("", true)
      ]))
    }), 128))
  ]))
}
}

})