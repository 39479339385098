<template>
  <router-link
    :to="`/admin/blog/${props.blog.slug}`"
    class="relative rounded-3xl border cursor-pointer border-transparent bg-white transition duration-500 hover:border-secondary hover:bg-secondary/20 dark:bg-gray-dark"
  >
    <span
      class="absolute top-2 right-2 bg-primaryTint text-light font-medium rounded-full py-1 text-sm opacity-95 px-4"
      v-if="props.blog.isActive"
      >Yayında</span
    >
    <img
      :src="props.blog.image"
      alt="blog"
      class="h-52 w-full rounded-t-3xl object-cover"
    />

    <div class="p-5 text-sm font-bold">
      <h6 class="font-extrabold text-secondary dark:text-secondary">
        {{ props.blog.tag }}
      </h6>
      <h5
        class="my-[10px] block text-lg font-extrabold leading-[23px] text-black line-clamp-2 dark:text-white"
      >
        {{ props.blog.title }}
      </h5>
      <p class="line-clamp-4 text-gray-500 font-medium">
        {{ props.blog.description }}
      </p>
    </div>
  </router-link>
</template>

<script setup lang="ts">
import { Blog } from "@/utils/api";
import { defineProps } from "vue";

const props = defineProps<{
  blog: Blog;
}>();
</script>
