import { defineComponent as _defineComponent } from 'vue'
import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, resolveComponent as _resolveComponent } from "vue"

import { watch, computed } from "vue";
import { useStore } from "vuex";
import { addAxiosInterceptors } from "./utils/api/interceptors";
import { axiosInstance } from "./utils/api/instance";


export default /*@__PURE__*/_defineComponent({
  __name: 'App',
  setup(__props) {

const store = useStore();
const token = computed(() => store.getters["token"]);

watch(
  () => token,
  () => {
    addAxiosInterceptors(axiosInstance);
  },
  { deep: true }
);

return (_ctx: any,_cache: any) => {
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createBlock(_component_router_view, null, {
    default: _withCtx(({ Component }) => [
      _createVNode(_Transition, {
        name: "page-flip",
        mode: "out-in"
      }, {
        default: _withCtx(() => [
          (_openBlock(), _createBlock(_resolveDynamicComponent(Component)))
        ]),
        _: 2
      }, 1024)
    ]),
    _: 1
  }))
}
}

})