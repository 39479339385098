<template>
  <BaseCard class="my-4">
    <div>
      <BaseLabel size="xl">Adres Bilgileri</BaseLabel>
    </div>

    <BaseLabel>Kayıtlı adreslerinden seç</BaseLabel>
    <div v-if="addresses.length">
      <BaseCheckboxGroup
        :options="addressOptions"
        v-model="selectedAddress"
        :multiple="false"
      />
    </div>

    <div v-else class="text-xs">Lütfen bir adres kayıt edin.</div>

    <div
      class="text-center w-full mt-4 cursor-pointer text-secondary hover:bg-lightTint rounded-md py-2"
      @click="isFormVisible = !isFormVisible"
    >
      <font-awesome-icon icon="plus-circle" /> Yeni bir adres oluştur
    </div>
    <form
      @submit="createAddress"
      class="flex flex-col gap-4 mt-2"
      v-if="isFormVisible"
    >
      <div class="my-4">
        <hr class="pb-4" />
        <BaseLabel>Yeni bir adres kayıt et</BaseLabel>
      </div>
      <div class="w-full">
        <BaseInput
          v-model="address.name"
          label="İsim *"
          placeholder="Adresinize bir isim verin"
        />
      </div>
      <div class="w-full">
        <BaseTextarea
          v-model="address.detail"
          label="Adres *"
          placeholder="Lütfen açık adresinizi girin"
        />
      </div>

      <div class="w-full flex items-center gap-2">
        <div class="w-1/2">
          <BaseSelect
            v-model="address.city"
            label="İl *"
            :options="provinces"
          />
        </div>
        <div class="w-1/2">
          <BaseSelect
            v-model="address.district"
            label="İlçe *"
            :options="districts"
          />
        </div>
      </div>

      <div class="w-full">
        <BaseInput v-model="address.zipCode" label="Posta Kodu" />
      </div>

      <BaseButton
        type="submit"
        :loading="loading"
        :disabled="
          !(address.city && address.detail && address.district && address.name)
        "
        >YENİ ADRES OLUŞTUR</BaseButton
      >
      <span class="text-xs">* Lütfen bütün bilgileri eksiksiz doldurunuz.</span>
    </form>
  </BaseCard>
</template>

<script setup lang="ts">
import { ApiService, Address, AddressModel } from "@/utils/api";
import cities from "@/assets/cities.json";

import { ref, computed, onMounted, watch, defineEmits, defineProps } from "vue";

defineProps<{ modelValue: AddressModel }>();

const isFormVisible = ref(false);

const emit = defineEmits(["update:modelValue"]);

const selectedAddress = ref<string>();
const loading = ref(false);

const addresses = ref<AddressModel[]>([]);

const address = ref<Address>({
  city: "",
  district: "",
  name: "",
  detail: "",
  zipCode: "",
});

const districts = computed(() => {
  const province = cities.filter(
    (city: { text: string; districts: { text: string }[] }) =>
      city.text === address.value.city
  )[0];

  return province
    ? province.districts.map((d: { text: string }) => {
        return { key: d.text, label: d.text };
      })
    : [];
});

const provinces = cities.map((city: { text: string }) => {
  return { key: city.text, label: city.text };
});

const addressOptions = computed(() =>
  addresses.value.map((a) => {
    return { label: a.name, value: a._id };
  })
);

async function listAddresses() {
  ApiService.profile.address
    .list()
    .then((res) => {
      addresses.value = res;
    })
    .catch((err) => {
      console.error(err);
      addresses.value = [];
    });
}

async function createAddress(e: Event) {
  loading.value = true;
  e.preventDefault();
  ApiService.profile.address
    .create(address.value as Address)
    .then((res) => {
      console.log(res);
      addresses.value.push(res);
      address.value = { city: "", district: "", name: "", detail: "" };
    })
    .catch((err) => {
      console.error(err);
    })
    .finally(() => {
      loading.value = false;
    });
}

watch(
  () => selectedAddress.value,
  (newValue) => {
    let address = addresses.value.find((a) => a._id === newValue);

    emit("update:modelValue", address);
  },
  { deep: true }
);

onMounted(async () => {
  await listAddresses();
});
</script>
