import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, withModifiers as _withModifiers, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["multiple", "accept"]
const _hoisted_2 = {
  key: 0,
  class: "text-gray-500 mt-4"
}
const _hoisted_3 = {
  key: 1,
  class: "text-red-500 mt-2"
}
const _hoisted_4 = {
  key: 2,
  class: "mt-4 space-y-2 w-full max-w-xs"
}
const _hoisted_5 = {
  key: 0,
  class: "flex text-sm items-center gap-4"
}
const _hoisted_6 = ["src"]
const _hoisted_7 = { key: 1 }
const _hoisted_8 = { class: "flex items-center truncate text-sm" }

import { ref, watch } from "vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'BaseFileUpload',
  props: {
    multiple: { type: Boolean, default: false },
    acceptedFileExtensions: { default: () => ["png", "jpeg", "svg", "pdf", "jpg"] },
    modelValue: { default: () => [] },
    maxFileCount: { default: 1 }
  },
  emits: ["update:modelValue"],
  setup(__props: any, { emit: __emit }) {

const props = __props;

const emit = __emit;

const isDragging = ref(false);
const previews = ref<{ src: string; name: string }[]>([]);
const fileInput = ref<HTMLInputElement | null>(null);
const exceedsMaxFiles = ref(false);

watch(
  () => props.modelValue,
  (newFiles) => {
    previews.value = [];
    newFiles.forEach((file) => {
      if (file.type.startsWith("image/")) {
        const reader = new FileReader();
        reader.onload = (e) => {
          if (e.target?.result) {
            previews.value.push({
              src: e.target.result as string,
              name: file.name,
            });
          }
        };
        reader.readAsDataURL(file);
      }
    });
    exceedsMaxFiles.value = newFiles.length > props.maxFileCount;
  },
  { immediate: true }
);

const onDragOver = () => {
  isDragging.value = true;
};

const onDragLeave = () => {
  isDragging.value = false;
};

const onDrop = (event: DragEvent) => {
  event.preventDefault();
  isDragging.value = false;
  if (event.dataTransfer?.files) {
    handleFiles(event.dataTransfer.files);
  }
};

const onFileSelect = () => {
  if (fileInput.value?.files) {
    handleFiles(fileInput.value.files);
  }
};

const triggerFileInput = () => {
  fileInput.value?.click();
};

const handleFiles = (fileList: FileList) => {
  const selectedFiles = Array.from(fileList).filter((file) => {
    const fileExtension = file.name.split(".").pop()?.toLowerCase();
    return (
      !props.acceptedFileExtensions.length ||
      props.acceptedFileExtensions.includes(fileExtension || "")
    );
  });

  const totalFiles = props.modelValue.length + selectedFiles.length;
  if (totalFiles > props.maxFileCount) {
    exceedsMaxFiles.value = true;
    return;
  }

  const filesToEmit = props.multiple
    ? [...props.modelValue, ...selectedFiles]
    : selectedFiles.slice(0, 1);

  emit("update:modelValue", filesToEmit);
};

const removeFile = (index: number) => {
  const updatedFiles = [...props.modelValue];
  updatedFiles.splice(index, 1);
  emit("update:modelValue", updatedFiles);
};

return (_ctx: any,_cache: any) => {
  const _component_BaseButton = _resolveComponent("BaseButton")!
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock("div", {
    onDragover: _withModifiers(onDragOver, ["prevent"]),
    onDragleave: onDragLeave,
    onDrop: onDrop,
    class: _normalizeClass(["flex flex-col items-center justify-center p-8 border-2 border-dashed rounded-lg transition-colors duration-200", {
      'border-primaryTint bg-lightShade bg-opacity-30': isDragging.value,
      'border-secondary bg-lightTint': !isDragging.value,
    }])
  }, [
    _createElementVNode("input", {
      type: "file",
      ref_key: "fileInput",
      ref: fileInput,
      onChange: onFileSelect,
      multiple: _ctx.multiple,
      accept: _ctx.acceptedFileExtensions.map((ext) => '.' + ext).join(','),
      class: "hidden"
    }, null, 40, _hoisted_1),
    _createVNode(_component_BaseButton, {
      type: "alternative",
      class: "!w-48",
      onClick: triggerFileInput
    }, {
      default: _withCtx(() => _cache[0] || (_cache[0] = [
        _createTextVNode(" DOSYA SEÇİN ")
      ])),
      _: 1
    }),
    (!_ctx.modelValue.length)
      ? (_openBlock(), _createElementBlock("p", _hoisted_2, " veya buraya sürükleyin. "))
      : _createCommentVNode("", true),
    (exceedsMaxFiles.value)
      ? (_openBlock(), _createElementBlock("p", _hoisted_3, " Maksimum " + _toDisplayString(_ctx.maxFileCount) + " dosya yükleyebilirsiniz. ", 1))
      : _createCommentVNode("", true),
    (_ctx.modelValue.length)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.modelValue, (file, index) => {
            return (_openBlock(), _createElementBlock("div", {
              key: index,
              class: "flex items-center space-x-4"
            }, [
              (file.type.startsWith('image/'))
                ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                    _createElementVNode("img", {
                      src: previews.value.find((p) => p.name === file.name)?.src,
                      alt: "File Preview",
                      class: "w-12 h-12 object-cover rounded-md"
                    }, null, 8, _hoisted_6),
                    _createTextVNode(" " + _toDisplayString(file.name), 1)
                  ]))
                : (_openBlock(), _createElementBlock("div", _hoisted_7, [
                    _createElementVNode("div", _hoisted_8, [
                      _createVNode(_component_font_awesome_icon, {
                        icon: "file",
                        class: "!text-5xl mr-6",
                        size: "5xl"
                      }),
                      _createTextVNode(" " + _toDisplayString(file.name), 1)
                    ])
                  ])),
              _createVNode(_component_font_awesome_icon, {
                onClick: ($event: any) => (removeFile(index)),
                class: "p-1 rounded-full cursor-pointer text-red-700 hover:bg-red-700 hover:bg-opacity-10",
                size: "2xs",
                icon: "x"
              }, null, 8, ["onClick"])
            ]))
          }), 128))
        ]))
      : _createCommentVNode("", true)
  ], 34))
}
}

})