<template>
  <div class="w-full relative">
    <label v-if="label" class="text-xs font-semibold">
      <font-awesome-icon
        v-if="icon"
        :icon="icon"
        class="absolute top-[38px] left-3 text-gray-400"
      />
      {{ label }}
    </label>
    <div class="flex">
      <input
        :type="inputType"
        v-model="inputValue"
        :placeholder="placeholder"
        :disabled="disabled"
        @focus="touched = true"
        @blur="touched = false"
        :class="[
          'w-full pr-3 py-2 rounded-lg border-2 border-gray-200 outline-none focus:border-secondary',
          icon ? 'pl-7' : 'pl-3',
        ]"
      />
      <span
        class="text-red-500 absolute -bottom-4 text-xs"
        v-if="inputValue?.length < props.minLength && touched"
        >{{ errorText }}</span
      >
    </div>
    <font-awesome-icon
      @click="isPasswordVisible = !isPasswordVisible"
      v-if="type === 'password'"
      :icon="isPasswordVisible ? 'eye' : 'eye-slash'"
      class="absolute top-[38px] right-3 text-gray-500 cursor-pointer"
    />
  </div>
</template>

<script lang="ts" setup>
import { defineProps, defineEmits, ref, watch, computed } from "vue";

const isPasswordVisible = ref(false);

const touched = ref(false);

const inputType = computed(() =>
  props.type === "password"
    ? isPasswordVisible.value
      ? "text"
      : "password"
    : props.type
);

const props = defineProps({
  modelValue: {
    type: String || Date,
    required: true,
  },
  type: {
    type: String,
    default: "text",
  },
  placeholder: {
    type: String,
    default: "",
  },
  label: {
    type: String,
    default: "",
  },
  icon: {
    type: String,
    default: "",
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  minLength: {
    type: Number,
    default: 0,
  },
  errorText: {
    type: String,
    default: "",
  },
});

const emit = defineEmits(["update:modelValue"]);

const inputValue = ref(props.modelValue);

watch(
  () => props.modelValue,
  (newValue) => {
    inputValue.value = newValue;
  }
);

watch(inputValue, (newValue) => {
  emit("update:modelValue", newValue);
});
</script>

<style scoped>
input {
  transition: border-color 0.2s;
}
</style>
