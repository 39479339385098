import type { infer as Infer } from "zod";
import { object, string } from "zod";
import { Profile } from "../profile";

export enum UserTier {
  ADMIN = "admin",
  MEMBER = "member",
}

export const LoginCredentialsSchema = object({
  phoneNumber: string().min(1),
  password: string().min(6),
});

export interface User {
  phoneNumber: string;
  isVerified: boolean;
  profile: Profile;
  role: UserTier;
  createdAt: Date;
  updatedAt: Date;
}

export interface UserModel extends User {
  _id: string;
  isInFreeTrial: boolean;
}

export interface VerifyCredentials {
  phoneNumber: string;
  code: string;
}

export type LoginCredentials = Infer<typeof LoginCredentialsSchema>;
