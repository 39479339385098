import router from "@/router";
import { ApiService, User } from "@/utils/api";
import { createStore } from "vuex";

export default createStore({
  state: {
    accessToken: localStorage.getItem("accessToken") || "",
    refreshToken: localStorage.getItem("refreshToken") || "",
    user: JSON.parse(localStorage.getItem("user") || "null"),
    services: JSON.parse(localStorage.getItem("services") || "null"),
    error: "err",
  },
  getters: {
    token: (state) => state.accessToken,
    user: (state) => state.user,
    services: (state) => state.services,
    error: (state) => state.error,
  },
  mutations: {
    LOGIN_SUCCESS: (
      state,
      payload: { user: User; accessToken: string; refreshToken: string }
    ) => {
      localStorage.setItem("accessToken", payload.accessToken);
      localStorage.setItem("refreshToken", payload.refreshToken);
      localStorage.setItem("user", JSON.stringify(payload.user));
      state.accessToken = payload.accessToken;
      state.refreshToken = payload.refreshToken;
      state.user = payload.user;
      state.error = "";

      router.push({ name: "adminHome" });
    },

    LOGIN_FAILED: (state, payload) => {
      state.accessToken = "";
      state.user = null;
      state.error = payload;
    },

    LOG_OUT: (state) => {
      state.accessToken = "";
      state.refreshToken = "";
      state.user = null;
      localStorage.removeItem("accessToken");
      localStorage.removeItem("refreshToken");
      localStorage.removeItem("user");
      router.push({ name: "login" });
    },
    SET_CONSULTATION_SERVICES: (state, payload) => {
      state.services = payload;
      localStorage.setItem("services", JSON.stringify(payload));
    },
    SET_USER_PROFILE: (state, payload) => {
      const user = {
        ...JSON.parse(localStorage.getItem("user") as string),
        profile: payload,
      };
      state.user = { ...user };
      localStorage.setItem("user", JSON.stringify(user));
    },
    DEACTIVATE_USER_FREE_TRIAL: (state) => {
      const user = {
        ...JSON.parse(localStorage.getItem("user") as string),
      };
      user.isInFreeTrial = false;

      state.user = { ...user };
      localStorage.setItem("user", JSON.stringify(user));
    },
    SET_TOKENS: (state, payload) => {
      localStorage.setItem("accessToken", payload.accessToken);
      localStorage.setItem("refreshToken", payload.refreshToken);
      state.accessToken = payload.accessToken;
      state.refreshToken = payload.refreshToken;
    },
  },
  actions: {
    GET_CONSULTATION_SERVICES: ({ commit }) => {
      ApiService.config
        .services()
        .then((res) => {
          commit("SET_CONSULTATION_SERVICES", res);
        })
        .catch(() => {
          commit("SET_CONSULTATION_SERVICES", null);
        });
    },
    REFRESH_ACCESS_TOKEN: ({ commit }) => {
      ApiService.auth
        .refreshToken()
        .then((res) => {
          commit("SET_TOKENS", res);
        })
        .catch(() => {
          commit("LOG_OUT");
        });
    },
  },
  modules: {},
});
