<template>
  <div class="bg-white rounded-md p-3 text-center shadow">
    <div class="font-semibold text-4xl">{{ props.value }}</div>
    <div class="text-gray-500 -mt-1">
      <font-awesome-icon :icon="props.icon" class="mr-1" />{{ props.name }}
    </div>
  </div>
</template>

<script setup lang="ts">
import { defineProps } from "vue";
const props = defineProps<{
  name: string;
  value: number | string;
  icon?: string;
}>();
</script>
