<template>
  <div>
    <label v-if="props.label" class="text-xs font-semibold">{{ label }}</label>
    <div class="flex flex-col md:flex-row md:items-center gap-4 mt-2">
      <label
        v-for="(option, i) in options"
        :key="i"
        class="flex flex-col justify-end cursor-pointer select-none"
      >
        <img
          v-if="props.hasImage && option.image"
          :src="option.image"
          class="w-32 h-32 mb-2"
        />
        <div class="flex items-center cursor-pointer select-none">
          <input
            type="checkbox"
            v-if="multiple"
            :value="option.value"
            @change="onCheckboxChange($event, option.value)"
            :checked="isChecked(option.value)"
            class="custom-input-checkbox"
          />
          <input
            type="radio"
            v-else
            :value="option.value"
            @change="onRadioChange(option.value)"
            :checked="modelValue === option.value"
            class="custom-input-radio"
          />
          <span
            class="custom-box"
            :class="multiple ? 'checkbox' : 'radio'"
          ></span>
          <span class="custom-label-text">{{ option.label }}</span>
        </div>
      </label>
    </div>
  </div>
</template>

<script setup lang="ts">
import { PropType, defineProps, defineEmits } from "vue";

const props = defineProps({
  options: {
    type: Array as PropType<
      Array<{ label: string; value: string | number | boolean; image?: string }>
    >,
    required: true,
  },
  modelValue: {
    type: [Array, String, Number, Boolean] as PropType<
      string | number | boolean | Array<string | number | boolean>
    >,
    required: true,
  },
  multiple: {
    type: Boolean,
    default: false,
  },
  label: {
    type: String,
    required: false,
  },
  hasImage: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(["update:modelValue"]);

const isChecked = (value: string | number | boolean) => {
  if (props.multiple && Array.isArray(props.modelValue)) {
    return props.modelValue.includes(value);
  }
  return props.modelValue === value;
};

const onCheckboxChange = (event: Event, value: string | number | boolean) => {
  const checked = (event.target as HTMLInputElement).checked;
  if (checked) {
    emit("update:modelValue", [
      ...(props.modelValue as Array<string | number | boolean>),
      value,
    ]);
  } else {
    emit(
      "update:modelValue",
      (props.modelValue as Array<string | number | boolean>).filter(
        (v) => v !== value
      )
    );
  }
};

const onRadioChange = (value: string | number | boolean) => {
  emit("update:modelValue", value);
};
</script>

<style scoped>
.custom-input-checkbox,
.custom-input-radio {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.custom-box {
  width: 20px;
  height: 20px;
  border-radius: 4px;
  margin-right: 4px;
  display: inline-block;
  position: relative;
  @apply border-2 border-primaryTint;
}

.custom-box.radio {
  border-radius: 50%;
}

.custom-label-text {
  font-size: 14px;
  color: #333;
}

.custom-input-checkbox:checked + .checkbox::before,
.custom-input-radio:checked + .radio::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 12px;
  height: 12px;
  @apply bg-secondary;
}

.custom-input-checkbox:checked + .checkbox::before {
  border-radius: 3px;
}

.custom-input-radio:checked + .radio::before {
  border-radius: 50%;
}
</style>
