import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex flex-col md:flex-row w-full" }
const _hoisted_2 = { class: "w-full md:w-1/2" }
const _hoisted_3 = { class: "w-full md:w-1/2" }

import { ref, onMounted } from "vue";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  Title,
  DoughnutController,
  PieController,
} from "chart.js";
import { DoughnutChart, PieChart } from "vue-chart-3";
import { ApiService } from "@/utils/api";


export default /*@__PURE__*/_defineComponent({
  __name: 'AdminCharts',
  setup(__props) {

ChartJS.register(
  ArcElement,
  Tooltip,
  Legend,
  Title,
  DoughnutController,
  PieController
);

const genderChartData = ref({
  labels: [] as string[],
  datasets: [
    {
      data: [] as number[],
      backgroundColor: ["#003366", "#99CCFF", "#E6F2FF"],
    },
  ],
});

const ageChartData = ref({
  labels: [] as string[],
  datasets: [
    {
      data: [] as number[],
      backgroundColor: [
        "#003366",
        "#1a4d80",
        "#002a59",
        "#99CCFF",
        "#9ecfff",
        "#91c2f2",
        "#E6F2FF",
        "#E9F3FF",
        "#CFDCEA",
        "#5A6E8A",
      ],
    },
  ],
});

const genderChartOptions = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      position: "bottom",
      labels: {
        color: "#003366",
      },
    },
    title: {
      display: true,
      text: "Cinsiyet Dağılımı",
      font: {
        size: 16,
      },
      color: "#003366",
    },
  },
};

const ageChartOptions = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      position: "bottom",
      labels: {
        color: "#003366",
      },
    },
    title: {
      display: true,
      text: "Yaş Dağılımı",
      font: {
        size: 16,
      },
      color: "#003366",
    },
  },
};

const fetchData = async () => {
  const genders: Record<string, string> = {
    male: "Erkek",
    female: "Kadın",
    other: "Diğer",
  };
  try {
    const response = await ApiService.admin.getDistributions();

    genderChartData.value.labels = response.genderDistribution.map(
      (item) => genders[item._id]
    );
    genderChartData.value.datasets[0].data = response.genderDistribution.map(
      (item) => item.count
    );

    ageChartData.value.labels = response.ageDistribution.map(
      (item) => item.label
    );
    ageChartData.value.datasets[0].data = response.ageDistribution.map(
      (item) => item.count
    );
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};

onMounted(fetchData);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_unref(DoughnutChart), {
        "chart-data": genderChartData.value,
        options: genderChartOptions
      }, null, 8, ["chart-data"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_unref(PieChart), {
        "chart-data": ageChartData.value,
        options: ageChartOptions
      }, null, 8, ["chart-data"])
    ])
  ]))
}
}

})