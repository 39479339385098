import type { AxiosInstance } from "axios";
import { Payment } from "./types";
import { paytrInstance } from "./instance";

export * from "./types";

export class PaymentService {
  private readonly instance: AxiosInstance;
  private readonly paytrInstance: AxiosInstance;

  constructor(instance: AxiosInstance) {
    this.instance = instance;

    this.paytrInstance = paytrInstance;
  }
  async create(params: Payment): Promise<any> {
    try {
      const response = await this.instance.post("/payment", params, {
        withCredentials: true,
      });
      return response.data.data;
    } catch (error) {
      throw error;
    }
  }

  async createToken(params: any): Promise<any> {
    try {
      const response = await this.instance.post("/payment/token", params, {
        withCredentials: true,
      });
      return response.data.data;
    } catch (error) {
      throw error;
    }
  }

  async getIFrameToken(params: any): Promise<any> {
    const formData = new FormData();

    for (const key in params) {
      if (Object.prototype.hasOwnProperty.call(params, key)) {
        formData.append(key, params[key]);
      }
    }

    try {
      const response = await this.paytrInstance.post(
        "/odeme/api/get-token",
        formData
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
}
