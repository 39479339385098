import type { AxiosInstance } from "axios";
import {
  Address,
  AddressModel,
  MedicalHistory,
  MedicalHistoryModel,
  Profile,
} from "./types";

export * from "./types";

class MedicalHistoryService {
  private readonly instance: AxiosInstance;

  constructor(instance: AxiosInstance) {
    this.instance = instance;
  }

  async get(): Promise<MedicalHistoryModel> {
    try {
      const response = await this.instance.get("/profile/medical_history");
      return response.data.data;
    } catch (error) {
      throw error;
    }
  }

  async create(params: MedicalHistory): Promise<MedicalHistoryModel> {
    try {
      const response = await this.instance.post(
        "/profile/medical_history",
        params
      );
      return response.data.data;
    } catch (error) {
      throw error;
    }
  }

  async update(params: MedicalHistory): Promise<MedicalHistoryModel> {
    try {
      const response = await this.instance.patch(
        "/profile/medical_history",
        params
      );
      return response.data.data;
    } catch (error) {
      throw error;
    }
  }
}

class AddressService {
  private readonly instance: AxiosInstance;

  constructor(instance: AxiosInstance) {
    this.instance = instance;
  }

  async create(params: Address): Promise<AddressModel> {
    try {
      const response = await this.instance.post("/profile/address", params);
      return response.data.data;
    } catch (error) {
      throw error;
    }
  }

  async list(): Promise<AddressModel[]> {
    try {
      const response = await this.instance.get("/profile/address");
      return response.data.data;
    } catch (error) {
      throw error;
    }
  }
}
export class ProfileService {
  private readonly instance: AxiosInstance;

  readonly medicalHistory: MedicalHistoryService;

  readonly address: AddressService;

  constructor(instance: AxiosInstance) {
    this.instance = instance;

    this.medicalHistory = new MedicalHistoryService(instance);
    this.address = new AddressService(instance);
  }

  async get(): Promise<Profile> {
    try {
      const response = await this.instance.get("/profile/me");
      return response.data.data;
    } catch (error) {
      throw error;
    }
  }

  async create(params: Profile): Promise<Profile> {
    try {
      const response = await this.instance.post("/profile/me", params);
      return response.data.data;
    } catch (error) {
      throw error;
    }
  }

  async update(params: Profile): Promise<Profile> {
    try {
      const response = await this.instance.patch("/profile/me", params);
      return response.data.data;
    } catch (error) {
      throw error;
    }
  }
}
