import type { AxiosInstance } from "axios";
import { Services } from "./types";

export * from "./types";

export class ConfigService {
  private readonly instance: AxiosInstance;

  constructor(instance: AxiosInstance) {
    this.instance = instance;
  }

  async services(): Promise<Services> {
    try {
      const response = await this.instance.get("/config/services", {
        withCredentials: true,
      });
      return response.data.data;
    } catch (error) {
      throw error;
    }
  }
}
