<template>
  <button
    :class="[
      'block w-full mx-auto transition-[background-color] duration-300 ease-in-out transform rounded-lg px-3 py-2 font-semibold  disabled:cursor-not-allowed hover:bg-opacity-80',
      buttonClasses,
    ]"
    :disabled="loading"
    @click="handleClick"
  >
    <div class="flex justify-center items-center space-x-2">
      <font-awesome-icon v-if="loading" icon="stethoscope" class="fast-spin" />
      <slot v-else />
    </div>
  </button>
</template>

<script setup lang="ts">
import { defineEmits, defineProps, computed } from "vue";

const props = defineProps({
  nativeType: {
    type: String,
    default: "button",
  },
  loading: {
    type: Boolean,
    default: false,
  },
  type: {
    type: String as () => "primary" | "secondary" | "alternative" | "danger",
    default: "primary",
  },
});

const emit = defineEmits<{
  (e: "click"): void;
}>();

const handleClick = () => {
  if (!props.loading) {
    emit("click");
  }
};

const buttonClasses = computed(() => {
  if (props.loading) {
    return "cursor-not-allowed bg-light text-primary";
  }

  switch (props.type) {
    case "secondary":
      return "bg-light text-primary border border-lightShade hover:bg-lightTint hover:border-secondaryTint hover:text-secondaryShade disabled:bg-light disabled:text-lightShade disabled:border-light";
    case "alternative":
      return "bg-secondary text-primary border border-secondary disabled:bg-light disabled:text-secondaryTint disabled:border-light";
    case "danger":
      return "bg-red-700 text-white border border-red-600 disabled:bg-red-200 disabled:text-red-500 disabled:border-red-200";
    default:
      return "bg-primary text-white disabled:bg-light disabled:text-primary disabled:border-secondary";
  }
});
</script>

<style scoped>
.fast-spin {
  animation: fast-spin 1s linear infinite;
  font-size: 20px;
}

@keyframes fast-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
