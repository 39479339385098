<template>
  <div class="w-full relative">
    <label v-if="label" class="text-xs font-semibold">
      {{ label }}
    </label>
    <div class="flex">
      <vue-tel-input
        v-model="inputValue"
        class="'w-full pr-3 py-1 !rounded-lg !border-2 w-full !border-gray-200 !outline-none focus-within:!border-secondary focus-within:!shadow-none"
        :valid-characters-only="true"
        @focus="touched = true"
        :disabled="disabled"
      ></vue-tel-input>
      <span
        class="text-red-500 absolute -bottom-4 text-xs"
        v-if="!isValid && touched"
        >{{ errorText }}</span
      >
    </div>
  </div>
</template>

<script lang="ts" setup>
import { defineProps, defineEmits, ref, watch, computed } from "vue";

const touched = ref(false);

const props = defineProps({
  modelValue: {
    type: String || Date,
    required: true,
  },
  placeholder: {
    type: String,
    default: "",
  },
  label: {
    type: String,
    default: "",
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  errorText: {
    type: String,
    default: "Lütfen geçerli bir telefon numarası giriniz.",
  },
});

function isRegexCompatible(input: string, pattern: RegExp): boolean {
  try {
    const regex = new RegExp(pattern);
    return regex.test(input);
  } catch (e) {
    console.error("Invalid regex pattern:", (e as Error).message);
    return false;
  }
}

const pattern = new RegExp(/^\+905\d{9}$/);

const isValid = computed(() => {
  return isRegexCompatible(props.modelValue, pattern);
});

const emit = defineEmits(["update:modelValue"]);

const inputValue = ref(props.modelValue);

watch(
  () => props.modelValue,
  (newValue) => {
    inputValue.value = newValue;
  }
);

watch(inputValue, (newValue) => {
  emit("update:modelValue", newValue.replace(/\s+/g, ""));
});
</script>

<style scoped>
input {
  transition: border-color 0.2s;
}
</style>
