<template>
  <form class="mt-8" @submit="submit" @focus="error = false">
    <div class="flex w-full">
      <div class="px-3 mb-5 text-left w-1/2">
        <BaseInput v-model="formData.firstName" label="İsminiz" />
      </div>
      <div class="px-3 mb-5 text-left w-1/2">
        <BaseInput v-model="formData.lastName" label="Soyisminiz" />
      </div>
    </div>
    <div class="flex w-full">
      <div class="px-3 mb-5 text-left w-1/2">
        <BaseSelect
          v-model="formData.gender"
          label="Cinsiyetiniz"
          :options="genders"
        />
      </div>
      <div class="px-3 mb-5 text-left w-1/2">
        <BaseInput
          v-model="formData.dateOfBirth"
          label="Doğum Tarihiniz"
          type="date"
        />
      </div>
    </div>
    <div class="flex w-full">
      <div class="px-3 mb-5 text-left w-1/2">
        <BaseRangeInput
          v-model="formData.height"
          label="Boyunuz"
          :min="50"
          :max="250"
          :step="1"
          unit="cm"
        />
      </div>
      <div class="px-3 mb-5 text-left w-1/2">
        <BaseRangeInput
          v-model="formData.weight"
          label="Kilonuz"
          :min="20"
          :max="200"
          :step="1"
          unit="kg"
        />
      </div>
    </div>
    <div v-if="error" class="text-red-500 text-sm mb-2">
      * Lütfen formu eksiksiz doldurunuz.
    </div>
    <BaseButton native-type="submit" :loading="loading">{{
      buttonText
    }}</BaseButton>
  </form>
</template>

<script setup lang="ts">
import { ApiService, ProfilePayload, Profile } from "@/utils/api";
import type { Option } from "@/components/BaseSelect.vue";
import {
  ref,
  computed,
  defineProps,
  onMounted,
  onUnmounted,
  defineEmits,
} from "vue";
import { watch } from "vue";
import { useStore } from "vuex";

const store = useStore();

const props = defineProps<{
  profile: Profile;
  type: "create" | "update";
}>();

const emit = defineEmits(["update:profile"]);

const buttonText = computed(() =>
  props.type === "create" ? "PROFİLİNİ TAMAMLA" : "KAYDET"
);

const loading = ref(false);
const error = ref(false);

const genders: Option[] = [
  { key: "male", label: "Erkek" },
  { key: "female", label: "Kadın" },
  { key: "other", label: "Diğer" },
];

const formData = ref<ProfilePayload>({
  firstName: "",
  lastName: "",
  gender: null,
  height: 50,
  weight: 20,
  dateOfBirth: null,
});

async function submit(e: Event) {
  e.preventDefault();
  error.value = false;

  if (!isFormValidated.value) {
    error.value = true;
    return;
  }
  loading.value = true;

  ApiService.profile[props.type](formData.value)
    .then((res) => {
      emit("update:profile", res);

      if (props.type === "create") {
        store.commit("SET_USER_PROFILE", res);
      }
    })
    .catch((err) => {
      console.log(err.response);
    })
    .finally(() => {
      loading.value = false;
    });
}

const isFormValidated = computed(() => {
  const data: Profile = formData.value;
  return (
    data.firstName.length &&
    data.lastName.length &&
    data.gender?.length &&
    data.height > 50 &&
    data.height < 250 &&
    data.weight > 20 &&
    data.weight < 300 &&
    data.dateOfBirth
  );
});

const handleFocus = () => {
  error.value = false;
};

onMounted(async () => {
  const inputs = document.querySelectorAll(
    "form input, form textarea, form select"
  );
  inputs.forEach((input) => {
    input.addEventListener("focus", handleFocus);
  });
  if (props.profile) {
    const { _id, createdAt, updatedAt, user, __v, ...others } = props.profile;
    formData.value = {
      ...others,
    };
  }
});

onUnmounted(() => {
  const inputs = document.querySelectorAll(
    "form input, form textarea, form select"
  );
  inputs.forEach((input) => {
    input.removeEventListener("focus", handleFocus);
  });
});

watch(
  () => props.profile,
  (newValue) => {
    const { _id, createdAt, updatedAt, user, __v, ...others } = newValue;
    formData.value = {
      ...others,
    };
  },
  { deep: true }
);
</script>
