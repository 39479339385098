import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "mx-auto flex w-16 h-16 items-center justify-center p-4 rounded-full bg-primary shadow-[0_15px_30px_rgba(71,189,255,0.4)]" }
const _hoisted_2 = ["src"]
const _hoisted_3 = { class: "font-semibold text-primary text-xl my-4" }
const _hoisted_4 = { class: "flex flex-col items-center" }
const _hoisted_5 = { class: "relative" }
const _hoisted_6 = { class: "bg-secondary p-1 text-2xl rounded-md text-white shadow-[0_15px_30px_rgba(71,189,255,0.4)] px-4" }
const _hoisted_7 = {
  key: 0,
  class: "text-sm font-semibold mb-0 ml-2"
}
const _hoisted_8 = {
  key: 0,
  class: "text-grat text-xs mt-2 font-medium"
}


import { Service } from "@/utils/api";
import { useStore } from "vuex";
import { computed } from "vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'ServiceCard',
  props: {
    service: {},
    selectedService: {}
  },
  setup(__props: any) {

const props = __props;

const store = useStore();
const user = computed(() => store.getters["user"]);

return (_ctx: any,_cache: any) => {
  const _component_BaseCard = _resolveComponent("BaseCard")!

  return (_openBlock(), _createBlock(_component_BaseCard, {
    class: _normalizeClass([
      'relative rounded-3xl border border-transparent cursor-pointer transition duration-500 hover:border-secondary hover:bg-secondary/20',
      props.selectedService?.ID === props.service.ID
        ? '!border-secondary !bg-secondary/20'
        : 'bg-white',
    ])
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("img", {
          src: _ctx.service.IMAGE
        }, null, 8, _hoisted_2)
      ]),
      _createElementVNode("div", _hoisted_3, _toDisplayString(props.service.NAME), 1),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("span", _hoisted_6, [
            _createElementVNode("span", {
              class: _normalizeClass([
              'text-xl font-semibold mb-0',
              user.value.isInFreeTrial ? ' line-through opacity-50' : '',
            ])
            }, _toDisplayString(props.service.PRICE) + "₺ ", 3),
            (user.value.isInFreeTrial)
              ? (_openBlock(), _createElementBlock("span", _hoisted_7, " İlk danışmanlığınızda ücretsiz! "))
              : _createCommentVNode("", true)
          ])
        ]),
        (user.value.isInFreeTrial)
          ? (_openBlock(), _createElementBlock("p", _hoisted_8, " Ücretsiz danışmanlık hakkınızdan 1 adet kalmıştır. "))
          : _createCommentVNode("", true)
      ])
    ]),
    _: 1
  }, 8, ["class"]))
}
}

})