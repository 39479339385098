import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["src", "alt"]
const _hoisted_2 = { class: "p-5 text-sm font-bold" }
const _hoisted_3 = { class: "mb-1 text-black" }


import { Service } from "@/utils/api";


export default /*@__PURE__*/_defineComponent({
  __name: 'SubserviceCard',
  props: {
    service: {},
    selectedService: {}
  },
  setup(__props: any) {

const props = __props;

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([
      'relative cursor-pointer rounded-3xl border border-transparent transition duration-500 hover:border-secondary hover:bg-secondary/20',
      props.selectedService?.ID === props.service.ID
        ? '!border-secondary !bg-secondary/20'
        : 'bg-white',
    ])
  }, [
    _createElementVNode("img", {
      src: props.service.IMAGE,
      alt: props.service.NAME,
      class: "h-52 w-full rounded-t-3xl object-cover",
      loading: "lazy"
    }, null, 8, _hoisted_1),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h6", _hoisted_3, _toDisplayString(props.service.NAME), 1)
    ])
  ], 2))
}
}

})