<template>
  <div class="w-full relative">
    <label v-if="label" class="text-xs font-semibold px-1">
      {{ label }}
    </label>
    <div class="flex">
      <select
        v-model="inputValue"
        :disabled="disabled"
        :class="[
          'w-full px-3 py-2 rounded-lg border-2 border-gray-200 outline-none focus:border-secondary bg-white',
        ]"
      >
        <option
          v-for="option in options"
          :key="typeof option == 'string' ? option : option.key"
          :value="typeof option == 'string' ? option : option.key"
        >
          {{ typeof option == "string" ? option : option.label }}
        </option>
      </select>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { defineProps, defineEmits, ref, watch } from "vue";

export type Option = string | { key: string; label: string };

const props = defineProps<{
  modelValue: string | Option;
  placeholder?: string;
  label?: string;
  disabled?: boolean;
  options: Option[];
}>();

const emit = defineEmits(["update:modelValue"]);

const inputValue = ref(props.modelValue);

watch(
  () => props.modelValue,
  (newValue) => {
    inputValue.value = newValue;
  }
);

watch(inputValue, (newValue) => {
  emit("update:modelValue", newValue);
});
</script>
